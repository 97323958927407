import ticTock from "../img/TicTocClock.png";
import moment from "moment";

export default function TimeToPaydayFlag({
  totalEarnings,
  endOfWeek,
  currentDate,
  guideSend
}) {
  let dollars = Math.floor(totalEarnings / 100);
  let cents = totalEarnings % 100;

  const today = moment(currentDate);
  const nextEndOfWeek = moment().day(endOfWeek); // Calculates the next endOfWeek

  let dueInDays = nextEndOfWeek.diff(today, "days");
  let buttonText = "Get Paid";
  let buttonDisabled = false;

  if (dueInDays > 0) {
    buttonText = `Get paid in ${dueInDays} days`;
    buttonDisabled = true;
  } else if (dueInDays < -1) {
    // To account for +1 day after endOfWeek
    buttonText = "Get Paid";
    buttonDisabled = false;
  }

  return (
    <div className="earningsContainer">
      <div className="earningsParent">
        <div className="timeToPaydayCell">
          <div className="earnedSoFar">
            <h5>You've Earned:</h5>
            <p className="youHave">
              <span className="dollarSign">$</span>
              <span className="dollarAmount">{dollars}.</span>
              <span className="centAmount">
                {cents.toString().padStart(2, "0")}
              </span>
            </p>
          </div>
          <div className="timeToPaydayImage">
            <img className="timeIcon" src={ticTock} alt={"Clock"} />
          </div>
          <div className="earnedSoFar">
            <h5>Get Paid In:</h5>
            <p className="youHave">
              <span className="dollarAmount">{dueInDays}</span>
              <span className="centAmount">days</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
