// Guide Machine.JS
import { Machine, assign } from "xstate";

export const startupMachine = Machine(
  {
    // Machine identifier
    id: "startup",
    // Initial state
    initial: "signIn",

    on: {
      SIGNOUT: "signIn",
      USERDELETED: "userSelector"
    },
    // States
    states: {
      signIn: {
        on: {
          NEWUSER: "signupFTUE",
          NUMOFUSERS1: "pullDownUser",
          NUMOFUSERS2: "userSelector"
        }
      },
      signupFTUE: {
        on: {
          COMPLETE: "pullDownUser"
        }
      },
      userSelector: {
        on: { COMPLETE: "pullDownUser" }
      },
      pullDownUser: {
        invoke: {
          src: (context, event) =>
            Promise.all([
              context.getAccountList(),
              context.getExpenseList(),
              context.refreshJobs(),
              context.getLoans()
            ]),
          onDone: { target: "defaultApplication" },
          onError: { target: "errorOnStartup" }
        }
      },
      errorOnStartup: {
        on: { RETRY: "pullDownUser" }
      },
      //
      //
      defaultApplication: {}
      //
      //
      // Jobs FTUE
      // TERMINATOR FOR STATES HERE
      // TERMINATOR FOR STATES HERE
      // TERMINATOR FOR STATES HERE
      // TERMINATOR FOR STATES HERE
      // TERMINATOR FOR STATES HERE
    }
  },
  {
    // Options: actions, guards, services, etc.
    actions: {
      setNumOfFamilyUsers: assign({
        numOfFamilyUsers: (context, event) => event.numOfFamilyUsers
      })
    },
    guards: {
      // your guard implementations
    }
    // ... other options
  }
);
