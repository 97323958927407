import { useState } from "react";
import { markJobsPaid } from "../jobComponents/JobsFunctions.js";
import { markExpensesAsPaid } from "../expenseComponents/ExpenseFunctions.js";
import moment from "moment";
import { useMachine } from "@xstate/react";
import { reconciliationMachine } from "../getPaidComponents/ReconciliationMachine.js";
import {
  GetAParent,
  YouEarned,
  YouOwe,
  PositiveBal,
  EqualBal,
  NegativeBal,
  PositiveWhyMatters,
  EqualWhyMatters,
  NegativeWhyMatters,
  PayChild,
  StreakLvl1Available,
  StreakLvl1InProgress,
  StreakLvl1Complete,
  NoPayBalance,
  PayBalance,
  BalancePaid,
  CantPayOptions2,
  TakeBreak,
  NoCarry,
  Carry1Start,
  Carry1Confirm,
  NoBorrow,
  Borrow1Start,
  Borrow1FromWho,
  Borrow1PayBack,
  Borrow1Confirm,
  Borrow1Completed
} from "../getPaidComponents/ReconciliationContents.js";

export default function PayDayWorkflow({
  totalEarnings,
  jobList,
  refreshIdentity,
  refreshJobs,
  expenseList,
  getExpenseList,
  getLoans,
  totalExpenses,
  userPayCycles,
  canBorrow,
  setCanBorrow,
  canCarry,
  setCanCarry,
  streakLvl,
  streakLvl1Status,
  payBalanceLvl,
  testBalance,
  needsBreak,
  setNeedsBreak,
  activeUser,
  guideSend
}) {
  let earnDollars = Math.floor(totalEarnings / 100);
  let earnCents = totalEarnings % 100;
  let expenseDollars = Math.floor(totalExpenses / 100);
  let expenseCents = totalExpenses % 100;

  // Temporary attributes for building a loan, sent to Finite State Machine
  const [newLoanInterest, setNewLoanInterest] = useState(0);
  const [newLoanLender, setNewLoanLender] = useState("Enter Name");
  const [newLoanAmount, setNewLoanAmount] = useState(0);
  const [newLoanDueDate, setNewLoanDueDate] = useState(null);
  const [newLoanDueDays, setNewLoanDueDays] = useState(7);

  // Get the start and end of the current week
  const startOfWeek = moment().startOf("isoWeek");
  // console.log("current week start: ", startOfWeek);
  const endOfWeek = moment().endOf("isoWeek");
  //console.log("current week end: ", endOfWeek);
  // Review unpaid jobs for this week
  const filteredJobs = jobList.filter((job) =>
    job.actionTimeStamps.some((ts) => ts.paid === false)
  );

  // Review unpaid expenses for this week
  const filteredExpenses = expenseList.filter((expense) => {
    const paidThisWeek = expense.actionTimeStamps.some((ts) => {
      const timestampMoment = moment(ts.timestamp);
      return (
        timestampMoment.isSameOrAfter(startOfWeek) &&
        timestampMoment.isSameOrBefore(endOfWeek)
      );
    });
    // Include the expense if it has not been paid this week and is due weekly (frequency = 1)
    return !paidThisWeek && expense.frequency === 1;
  });

  // Determine balance for the week and if it is positive or negative
  let summaryBalance = totalEarnings - totalExpenses;
  let summaryDollars = Math.floor(summaryBalance / 100);
  let summaryCents = summaryBalance % 100;
  console.log(
    "summary earnings are: " +
      summaryBalance +
      " : " +
      summaryDollars +
      " : " +
      summaryCents
  );
  console.log("pay cycles are: ", userPayCycles);
  const weeklyBalance =
    summaryBalance > 0
      ? "positive"
      : summaryBalance === 0
      ? "equal"
      : "negative";
  console.log("weekly balance is: ", testBalance);
  //
  //
  //
  //
  const handleDismiss = () => {
    send("RESET_RECONCILIATION_MACHINE");
    guideSend("DISMISS");
  };
  //
  //
  //
  const handleGetPaidExit = async () => {
    try {
      await Promise.all([refreshJobs(), getExpenseList(), getLoans()]);
      console.log("I've refreshed everything");
      refreshIdentity();
      guideSend("COMPLETE");
      send("RESET_RECONCILIATION_MACHINE");
    } catch (error) {
      console.error("One of the promises failed:", error);
      // Handle errors, perhaps setAuthenticated to false or show an error message
    }
  };

  // Initilizate Finite State Machine, and pass the context
  // as to the state of the weekly balance and what week it is
  const [current, send] = useMachine(reconciliationMachine, {
    context: {
      balance: testBalance,
      payBalanceLvl: payBalanceLvl,
      streakLvl: streakLvl,
      streakLvl1Status: streakLvl1Status,
      canBorrow: canBorrow,
      setCanBorrow: setCanBorrow,
      canCarry: canCarry,
      setCanCarry: setCanCarry,
      needsBreak: needsBreak,
      setNeedsBreak: setNeedsBreak,
      markExpensesAsPaid: markExpensesAsPaid,
      markJobsPaid: markJobsPaid,
      activeUser: activeUser,
      handleGetPaidExit: handleGetPaidExit
    }
  });
  console.log("Current State of reconciliation machine is: ", current);

  // Render content based on state of reconciliation State Machine
  let pageContent;
  if (current.matches("getAParent")) {
    pageContent = <GetAParent send={send} />;
  } else if (current.matches("youEarned")) {
    pageContent = (
      <YouEarned
        send={send}
        earnDollars={earnDollars}
        earnCents={earnCents}
        filteredJobs={filteredJobs}
      />
    );
  } else if (current.matches("youOwe")) {
    pageContent = (
      <YouOwe
        send={send}
        expenseDollars={expenseDollars}
        expenseCents={expenseCents}
        filteredExpenses={filteredExpenses}
      />
    );
  }
  //
  //
  // Balance Review
  else if (current.matches("balanceReview.positive")) {
    pageContent = (
      <PositiveBal
        send={send}
        summaryDollars={summaryDollars}
        summaryCents={summaryCents}
      />
    );
  } else if (current.matches("balanceReview.equal")) {
    pageContent = <EqualBal send={send} />;
  } else if (current.matches("balanceReview.negative")) {
    pageContent = (
      <NegativeBal
        send={send}
        summaryDollars={summaryDollars}
        summaryCents={summaryCents}
      />
    );
  } else if (current.matches("balanceReview.positiveWhyMatters")) {
    pageContent = <PositiveWhyMatters send={send} />;
  } else if (current.matches("balanceReview.equalWhyMatters")) {
    pageContent = <EqualWhyMatters send={send} />;
  } else if (current.matches("balanceReview.negativeWhyMatters")) {
    pageContent = <NegativeWhyMatters send={send} />;
  }
  //
  //
  // Payment
  else if (current.matches("payChild")) {
    pageContent = (
      <PayChild
        send={send}
        summaryDollars={summaryDollars}
        summaryCents={summaryCents}
      />
    );
  }
  //
  //
  // Streak Matching
  else if (current.matches("streakReview.streakLvl1Available")) {
    pageContent = <StreakLvl1Available send={send} />;
  } else if (current.matches("streakReview.streakLvl1InProg")) {
    pageContent = <StreakLvl1InProgress send={send} />;
  } else if (current.matches("streakReview.streakLvl1Complete")) {
    pageContent = <StreakLvl1Complete send={send} />;
  }
  //
  //
  // Pay Parents
  else if (current.matches("payParent.noPayBal")) {
    pageContent = <NoPayBalance send={send} />;
  } else if (current.matches("payParent.payBal2")) {
    pageContent = (
      <PayBalance
        send={send}
        summaryDollars={summaryDollars}
        summaryCents={summaryCents}
        earnDollars={earnDollars}
        earnCents={earnCents}
        expenseDollars={expenseDollars}
        expenseCents={expenseCents}
      />
    );
  } else if (current.matches("balancePaid")) {
    pageContent = <BalancePaid send={send} />;
  }
  //
  //
  // Can't Pay Parents
  else if (current.matches("cantPay.cantPayOptions2")) {
    pageContent = <CantPayOptions2 send={send} />;
  } else if (current.matches("cantPay.takeBreak")) {
    pageContent = <TakeBreak send={send} />;
  }
  //
  //
  // Carry Workflow
  else if (current.matches("carryFlow1.noCarry")) {
    pageContent = <NoCarry send={send} />;
  } else if (current.matches("carryFlow1.carry1Start")) {
    pageContent = (
      <Carry1Start
        send={send}
        summaryBalance={summaryBalance}
        summaryDollars={summaryDollars}
        summaryCents={summaryCents}
        activeUser={activeUser}
      />
    );
  } else if (current.matches("carryFlow1.carry1Confirm")) {
    pageContent = (
      <Carry1Confirm
        send={send}
        summaryBalance={summaryBalance}
        summaryDollars={summaryDollars}
        summaryCents={summaryCents}
      />
    );
  }
  //
  //
  //Borrow Workflow
  else if (current.matches("borrowFlow1.noBorrow")) {
    pageContent = <NoBorrow send={send} />;
  } else if (current.matches("borrowFlow1.borrow1Start")) {
    pageContent = (
      <Borrow1Start
        send={send}
        summaryDollars={summaryDollars}
        summaryCents={summaryCents}
      />
    );
  } else if (current.matches("borrowFlow1.borrow1FromWho")) {
    pageContent = (
      <Borrow1FromWho
        send={send}
        summaryDollars={summaryDollars}
        summaryCents={summaryCents}
        setNewLoanLender={setNewLoanLender}
      />
    );
  } else if (current.matches("borrowFlow1.borrow1PayBack")) {
    pageContent = (
      <Borrow1PayBack
        send={send}
        summaryDollars={summaryDollars}
        summaryCents={summaryCents}
        newLoanLender={newLoanLender}
        newLoanDueDays={newLoanDueDays}
        setNewLoanDueDays={setNewLoanDueDays}
      />
    );
  } else if (current.matches("borrowFlow1.borrow1Confirm")) {
    pageContent = (
      <Borrow1Confirm
        send={send}
        summaryBalance={summaryBalance}
        summaryDollars={summaryDollars}
        summaryCents={summaryCents}
        newLoanLender={newLoanLender}
        newLoanDueDate={newLoanDueDate}
        newLoanDueDays={newLoanDueDays}
        newLoanInterest={newLoanInterest}
        activeUser={activeUser}
      />
    );
  } else if (current.matches("borrowFlow1.borrow1Completed")) {
    pageContent = (
      <Borrow1Completed
        send={send}
        summaryBalance={summaryBalance}
        summaryDollars={summaryDollars}
        summaryCents={summaryCents}
      />
    );
  }
  // NEXT STATES HERE
  return (
    <div className="actModalOverlay">
      <div className="earningModalCont">
        <div className="earningModalHeader">
          <button className="buttonStyle3" onClick={handleDismiss}>
            X
          </button>
        </div>
        <div className="">{pageContent}</div>
      </div>
    </div>
  );
}
