export default function UserInfo({ setNewUsername }) {
  return (
    <div className="centeredContainer vertColumn">
      <div className="introTitle">
        <h1>Let's Get Started!</h1>
        <p>Enter your child's name below</p>
        <input
          className="inputCell"
          placeholder="Name..."
          onChange={(e) => setNewUsername(e.target.value)}
        />
      </div>
    </div>
  );
}
