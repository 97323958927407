import { useState } from "react";
import {
  addAccount,
  removeAccount,
  updateAccountInfo
} from "./AccountFunctions.js";

export default function CreateAcctModal({
  existingAccount,
  selectedAccount,
  images,
  onClose,
  onRemove,
  getAccountList,
  activeUser
}) {
  console.log("Active user during edit is: ", activeUser);

  const [selectedImg, setSelectedImg] = useState(1);
  // Create local variables for the name and amount so that i'm not overriding the existing
  const [accountName, setAccountName] = useState(selectedAccount.name);
  const [accountAmount, setAccountAmount] = useState(selectedAccount.amount);

  const handleClick = (imageId) => {
    setSelectedImg(imageId);
  };

  const handleAdd = async () => {
    if (!accountName || !accountName.trim()) {
      alert("Account name is required!"); // Display a simple alert, but you might want to show a more user-friendly error message in your UI
      return;
    }
    const amountInCents = Math.round(parseFloat(accountAmount) * 100);
    const success = await addAccount(
      accountName,
      amountInCents,
      selectedImg,
      activeUser
    );
    if (success) {
      getAccountList();
      onClose();
    } else {
      // handle error case here
    }
  };

  return (
    <div className="actModalOverlay">
      <div className="actModalContent">
        <div className="modalTitleControl">
          <h1>New Account</h1>
          <button className="buttonStyle3" onClick={onClose}>
            X
          </button>
        </div>
        <div className="actEditModalSubContents">
          <div className="createAccountRow">
            <div className="createAccountRowItem">
              <p>Enter an account name:</p>
              <input
                className="inputFull"
                placeholder="Enter Name"
                value={accountName} // Controlled component
                onChange={(e) => setAccountName(e.target.value)}
              />
            </div>
            <div className="createAccountRowItem">
              <p>Amount in this account:</p>
              <input
                className="inputFull"
                placeholder="Enter Amount"
                onChange={(e) => setAccountAmount(e.target.value)}
              />
            </div>
          </div>
          <div className="avatarModalSelection">
            <p>Choose an avatar:</p>
            {images.map((image) => (
              <img
                key={image.id}
                src={image.src}
                alt={image.alt}
                onClick={() => handleClick(image.id)}
                className={`acctAvatarEdit ${
                  selectedImg === image.id
                    ? "selectedAcctAvatar"
                    : "defaultAcctAvatar"
                }`}
              />
            ))}
          </div>
          <div className="modalFooterControlSingle">
            <button
              className="button"
              onClick={handleAdd}
              disabled={!accountName || !accountName.trim()} // Disable the button if accountName is empty or just spaces
            >
              save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
