export default function GetPaidListItem({ title, count, amount }) {
  let summaryAmt = amount;
  //console.log("count is: " + count + "amount is" + amount + "sumaryAmount is: " + summaryAmt)
  let dollars = Math.floor(summaryAmt / 100);
  let cents = summaryAmt % 100;

  return (
    <div className="getPaidLICont">
      <div className="">
        <p className="getPaidLIprefix">For</p>
        <p className="getPaidLItitle">{title}</p>
      </div>
      <div className="getPaidCount">
        {count !== null && (
          <div className="countCircle">
            <span className="count">{count}</span>
            <span className="centAmount">x</span>
          </div>
        )}
        <div>
          <p className="youHave">
            <span className="dollarSign3">$</span>
            <span className="dollarAmount3">{dollars}.</span>
            <span className="centAmount3">
              {cents.toString().padStart(2, "0")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
