// These are all of the jobs functions that might need to be called
import { db, auth } from "../Firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  getDocs,
  getDoc,
  query,
  updateDoc,
  where,
  arrayUnion,
  doc
} from "firebase/firestore";
const jobsCollectionRef = collection(db, "jobs");
//
//
//
//
// Get Jobs List
export const getJobsList = async (activeUser) => {
  // Make sure there's an authenticated user
  if (!auth.currentUser) {
    console.error("No user logged in!");
    return null; // Or return false, if you prefer
  }
  // Query documents where userId matches the authenticated user's UID
  const userJobsQuery = query(
    jobsCollectionRef,
    where("parentUID", "==", auth.currentUser.uid),
    where("userDocID", "==", activeUser)
  );
  try {
    const data = await getDocs(userJobsQuery);
    const filteredData = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id
    }));
    return filteredData;
  } catch (err) {
    console.error(err);
    return null; // Or return false, if you prefer
  }
};
//
//
//
//
// Create Job
export const createJob = async (
  newTitle,
  newFrequency,
  newTimeSpanHours,
  newAmount,
  activeUser
) => {
  console.log(
    "Setting: " +
      newTitle +
      "to new frequency of: " +
      newFrequency +
      newTimeSpanHours +
      "at a value of: " +
      newAmount
  );
  // Make sure there's an authenticated user
  if (!auth.currentUser) {
    console.error("No user logged in!");
    return false;
  }
  try {
    await addDoc(jobsCollectionRef, {
      title: newTitle,
      amount: newAmount,
      frequency: newFrequency,
      timeSpanHours: newTimeSpanHours,
      userDocID: activeUser,
      parentUID: auth?.currentUser?.uid,
      createdOn: new Date().toISOString(),
      actionTimeStamps: [] // empty array to be populated in instances where the user has taken action.
    });
    return true; // Return true if successful
  } catch (err) {
    console.error(err);
    return false; // Return false if there's an error
  }
};
//
//
//
//
// Remove Job
export const removeJob = async (id, activeUser) => {
  if (!auth.currentUser) {
    console.error("No user logged in!");
    return false;
  }
  const userJobsQuery = query(
    jobsCollectionRef,
    where("parentUID", "==", auth.currentUser.uid),
    where("userDocID", "==", activeUser)
  );
  try {
    const snapshot = await getDocs(userJobsQuery);
    let docRef;
    // find the document with the provided ID
    snapshot.forEach((doc) => {
      if (doc.id === id) {
        docRef = doc.ref;
      }
    });
    if (docRef) {
      await deleteDoc(docRef);
      console.log("delted: " + docRef);
      return true;
    } else {
      console.log("job with given ID not found");
      return false;
    }
  } catch (err) {
    console.error(err);
  }
};
//
//
//
//
// Update Job
export const updateJob = async (
  id,
  newTitle,
  newAmount,
  newFrequency,
  newTimeSpanHours,
  activeUser
) => {
  if (!auth.currentUser) {
    console.error("No user logged in!");
    return false;
  }
  const userJobsQuery = query(
    jobsCollectionRef,
    where("parentUID", "==", auth.currentUser.uid),
    where("userDocID", "==", activeUser)
  );
  try {
    const snapshot = await getDocs(userJobsQuery);
    let docRef;
    // find the document with the provided ID
    snapshot.forEach((doc) => {
      if (doc.id === id) {
        docRef = doc.ref;
      }
    });
    if (docRef) {
      await updateDoc(docRef, {
        title: newTitle,
        amount: newAmount,
        frequency: newFrequency,
        timeSpanHours: newTimeSpanHours
      });
      console.log("update completed on: " + docRef);
      return true;
    } else {
      console.log("job with given ID not found");
      return false;
    }
  } catch (err) {
    console.error(err);
  }
};

//
// Took action on job
export const tookAction = async (id, activeUser) => {
  if (!auth.currentUser) {
    console.error("No user logged in!");
    return false;
  }
  // creating item as object instead of array entry
  const actionEntry = {
    doneTimestamp: new Date().toISOString(),
    paid: false,
    paidTimestamp: null // Will be populated when this action is paid for
  };
  const userJobsQuery = query(
    jobsCollectionRef,
    where("parentUID", "==", auth.currentUser.uid),
    where("userDocID", "==", activeUser)
  );
  try {
    const snapshot = await getDocs(userJobsQuery);
    let docRef;
    // find the document with the provided ID
    snapshot.forEach((doc) => {
      if (doc.id === id) {
        docRef = doc.ref;
      }
    });
    if (docRef) {
      await updateDoc(docRef, {
        actionTimeStamps: arrayUnion(actionEntry)
      });
      console.log("action update completed on: " + docRef);
      return true;
    } else {
      console.log("job with given ID not found");
      return false;
    }
  } catch (err) {
    console.error(err);
  }
};

//
//
export const undoAction = async (id, index) => {
  if (!auth.currentUser) {
    console.error("No user logged in!");
    return false;
  }
  // Create a direct reference to the document if you know its ID
  const docRef = doc(jobsCollectionRef, id);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      if (
        data &&
        data.actionTimeStamps &&
        data.actionTimeStamps.length > index
      ) {
        // Remove the item at the specific index
        data.actionTimeStamps.splice(index, 1);

        // Update the document with the modified array
        await updateDoc(docRef, {
          actionTimeStamps: data.actionTimeStamps
        });

        console.log("action update completed on: " + docRef);
        return true;
      } else {
        console.log("ActionTimestamps array or its index is not valid");
        return false;
      }
    } else {
      console.log("Document with given ID not found");
      return false;
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const markJobsPaid = async (activeUser) => {
  try {
    // Get all the jobs from the collection for the currently active user
    const userJobsQuery = query(
      jobsCollectionRef,
      where("parentUID", "==", auth.currentUser.uid),
      where("userDocID", "==", activeUser)
    );

    // Execute the query to get documents
    const querySnapshot = await getDocs(userJobsQuery);

    // Use Promise.all to wait for all updates to complete
    await Promise.all(
      querySnapshot.docs.map(async (jobDoc) => {
        const job = jobDoc.data();

        // Check if the job has actionTimeStamps and if there are any unpaid actionTimeStamps for this job
        const unpaidTimeStamps =
          job.actionTimeStamps?.filter((ts) => !ts.paid) || [];

        if (unpaidTimeStamps.length) {
          // Update those actionTimeStamps to paid
          const updatedTimeStamps = job.actionTimeStamps.map((ts) => ({
            ...ts,
            paid: true,
            paidTimestamp: ts.paid ? ts.paidTimestamp : new Date().toISOString()
          }));

          // Update the document in the collection
          await updateDoc(doc(db, "jobs", jobDoc.id), {
            actionTimeStamps: updatedTimeStamps
          });
        }
      })
    );
    console.log("Successfully updated all unpaid jobs");
    return true; // Return true to signal success
  } catch (error) {
    console.error("Error marking all jobs as paid:", error);
    return false; // Return false to signal failure
  }
};
