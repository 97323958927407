import { avatars } from "./Avatars.js";
import { useState, useRef, useEffect } from "react";
import { getUserContext } from "./identity/IdentityFunctions.js";

export default function HeaderBar({
  userName,
  setMenuOpen,
  selectedAvatar,
  setIsExpenseModalOpen,
  setIsJobWorkflowOpen,
  activeUser,
  getUserContext,
  seenJobFTUE,
  setUserPickerOpen,
  savingsVisible,
  onViewClick
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const selectedImage =
    avatars.find((image) => image.id === selectedAvatar) || {};

  const openDropdown = (event) => {
    event.stopPropagation(); // Prevent this click from being propagated to close the dropdown
    setIsDropdownOpen(true);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (isDropdownOpen) {
      // Add a click event listener to the document to close the dropdown when you click outside of it
      document.addEventListener("click", closeDropdown);
    }

    return () => {
      // Cleanup the event listener
      document.removeEventListener("click", closeDropdown);
    };
  }, [isDropdownOpen]);

  return (
    <div className="headerContents">
      <button
        className="buttonStyle3"
        style={{ visibility: savingsVisible ? "hidden" : "visible" }}
        onClick={() => setMenuOpen(true)}
      >
        &#9776;
      </button>

      <div className="avatarBlock" onClick={() => setUserPickerOpen(true)}>
        <img
          className="statusAvatar"
          src={selectedImage.src}
          alt={selectedImage.alt}
        />
        <h5>{userName}</h5>
      </div>
      <div ref={dropdownRef}>
        {/* Conditionally render either '+' or 'X' based on savingsVisible */}
        <button
          className="buttonStyle3"
          onClick={savingsVisible ? onViewClick : openDropdown}
        >
          {savingsVisible ? "X" : "+"}
        </button>

        {/* Keep the dropdown menu functionality only if savingsVisible is false */}
        {!savingsVisible && isDropdownOpen && (
          <div className="dropdownMenu">
            <button onClick={() => setIsJobWorkflowOpen(true)}>
              New Job <span>(money in)</span>
            </button>
            <button onClick={() => setIsExpenseModalOpen(true)}>
              New Expense <span>(money out)</span>
            </button>
            {/* Other choices, each of which invokes its own function when clicked */}
          </div>
        )}
      </div>
    </div>
  );
}
