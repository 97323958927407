// acocunt functions
import { db, auth } from "../Firebase";
import {
  getDocs,
  collection,
  addDoc,
  deleteDoc,
  query,
  where,
  updateDoc
} from "firebase/firestore";

const accountsCollectionRef = collection(db, "accounts");
//
//
//
//
//
// Add Account
export const addAccount = async (name, amount, image, activeUser) => {
  console.log("name is " + name + "amount is " + amount + "image is " + image);
  // Create a new account object
  try {
    await addDoc(accountsCollectionRef, {
      name: name,
      amount: amount,
      image: image,
      userDocID: activeUser,
      parentUID: auth?.currentUser?.uid
    });
    return true;
    // Need to refresh at return    getAccountList();
  } catch (err) {
    console.error(err);
    return false;
  }
};
//
//
//
//
//
// UPdate Accounts
export const updateAccountInfo = async (
  id,
  accountName,
  accountAmount,
  activeUser
) => {
  // Check to see if a user is signed in
  if (!auth.currentUser) {
    console.error("No user logged in!");
    return;
  }
  // Query documents where userId matches the authenticated user's UID
  const userAccountsQuery = query(
    accountsCollectionRef,
    where("parentUID", "==", auth.currentUser.uid),
    where("userDocID", "==", activeUser)
  );
  try {
    const snapshot = await getDocs(userAccountsQuery);
    let docRef;
    // find the document with the provided ID
    snapshot.forEach((doc) => {
      if (doc.id === id) {
        docRef = doc.ref;
      }
    });
    //if found, update the document
    if (docRef) {
      await updateDoc(docRef, {
        name: accountName,
        amount: accountAmount
      });
      console.log("I updated the document = " + docRef);
      return true;
    } else {
      console.error("account with given ID not found");
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};
//

export const removeAccount = async (id, activeUser) => {
  if (!auth.currentUser) {
    console.error("No user logged in!");
    return;
  }

  console.log("Current User UID:", auth.currentUser?.uid);
  console.log("Active User:", activeUser);

  if (!auth.currentUser?.uid || !activeUser) {
    console.error("UID or Active User not defined");
    return;
  }
  // Query documents where userId matches the authenticated user's UID
  const userAccountsQuery = query(
    accountsCollectionRef,
    where("parentUID", "==", auth.currentUser.uid),
    where("userDocID", "==", activeUser)
  );
  try {
    const snapshot = await getDocs(userAccountsQuery);
    let docRef;
    // find the document with the provided ID
    snapshot.forEach((doc) => {
      if (doc.id === id) {
        docRef = doc.ref;
      }
    });
    //if found, update the document
    if (docRef) {
      await deleteDoc(docRef);
      console.log("I deleted the document = " + docRef);
      return true;
    } else {
      console.error("account with given ID not found");
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};
