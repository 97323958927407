import { useEffect, useState } from "react";
import EntryControl from "./EntryControl.js";
import pig1 from "./img/pig1.png";

export default function MoneyInPiggyBank({
  curBalance,
  enteredAmount,
  setEnteredAmount
}) {
  const buttons = ["1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "0", "←"];

  useEffect(() => {
    setEnteredAmount(curBalance.toString());
  }, [curBalance, setEnteredAmount]);

  const handleButtonClick = (value) => {
    if (enteredAmount === curBalance.toString() && value !== "←") {
      setEnteredAmount(value);
    } else {
      if (value === "←") {
        if (enteredAmount.length === 1) {
          setEnteredAmount(curBalance.toString());
        } else {
          setEnteredAmount(enteredAmount.slice(0, -1));
        }
      } else {
        setEnteredAmount(enteredAmount + value);
      }
    }
  };

  let dollars = parseInt(enteredAmount, 10);
  let cents = Math.round((enteredAmount - dollars) * 100);

  return (
    <div>
      <div className="centeredContainer">
        <div className="piggyBankContainer">
          <div className="column-1">
            <h1>Enter the amount here:</h1>
            <div className="introTitle centeredElement">
              <p className="youHave">
                <span className="dollarSign">$</span>
                <span className="dollarAmount">{dollars}.</span>
                <span className="centAmount">
                  {cents.toString().padStart(2, "0")}
                </span>
              </p>
            </div>
            <img className="countingAvatar" src={pig1} alt="Piggy Bank" />
          </div>
          <div className="column-2 paddingBankEntry">
            <div className="button-grid">
              {buttons.map((button) => (
                <EntryControl
                  key={button}
                  value={button}
                  onClick={() => handleButtonClick(button)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
