// ExpenseFunctions.js
import { db, auth } from "../Firebase";
import moment from "moment";
import {
  addDoc,
  collection,
  deleteDoc,
  getDocs,
  query,
  updateDoc,
  where,
  doc
} from "firebase/firestore";

// Collection reference for all functions
const expenseCollectionRef = collection(db, "expenses");

// Create Expense
export const createExpense = async (
  newTitle,
  newFrequency,
  newTimeSpanHours,
  newAmount,
  activeUser
) => {
  console.log(
    "Setting: " +
      newTitle +
      "to new frequency of: " +
      newFrequency +
      newTimeSpanHours +
      "at a value of: " +
      newAmount
  );
  // Make sure there's an authenticated user
  if (!auth.currentUser) {
    console.error("No user logged in!");
    return false;
  }
  try {
    await addDoc(expenseCollectionRef, {
      title: newTitle,
      amount: newAmount,
      frequency: newFrequency,
      timeSpanHours: newTimeSpanHours,
      userDocID: activeUser,
      parentUID: auth?.currentUser?.uid,
      createdOn: new Date().toISOString(),
      actionTimeStamps: [] // empty array to be populated in instances where the user has taken action.
    });
    return true; // Return true if successful
  } catch (err) {
    console.error(err);
    return false; // Return false if there's an error
  }
};
//
//
//
//
// Update Expense
export const updateExpense = async (
  id,
  newTitle,
  newAmount,
  newFrequency,
  newTimeSpanHours,
  activeUser
) => {
  if (!auth.currentUser) {
    console.error("No user logged in!");
    return false;
  }
  const userJobsQuery = query(
    expenseCollectionRef,
    where("parentUID", "==", auth.currentUser.uid),
    where("userDocID", "==", activeUser)
  );
  try {
    const snapshot = await getDocs(userJobsQuery);
    let docRef;
    // find the document with the provided ID
    snapshot.forEach((doc) => {
      if (doc.id === id) {
        docRef = doc.ref;
      }
    });
    if (docRef) {
      await updateDoc(docRef, {
        title: newTitle,
        amount: newAmount,
        frequency: newFrequency,
        timeSpanHours: newTimeSpanHours
      });
      console.log("update completed on: " + docRef);
      return true;
    } else {
      console.log("job with given ID not found");
      return false;
    }
  } catch (err) {
    console.error(err);
  }
};
//
//
//
// Remove Expense
export const removeExpense = async (id, activeUser) => {
  if (!auth.currentUser) {
    console.error("No user logged in!");
    return false;
  }
  const userJobsQuery = query(
    expenseCollectionRef,
    where("parentUID", "==", auth.currentUser.uid),
    where("userDocID", "==", activeUser)
  );
  try {
    const snapshot = await getDocs(userJobsQuery);
    let docRef;
    // find the document with the provided ID
    snapshot.forEach((doc) => {
      if (doc.id === id) {
        docRef = doc.ref;
      }
    });
    if (docRef) {
      await deleteDoc(docRef);
      console.log("delted: " + docRef);
      return true;
    } else {
      console.log("job with given ID not found");
      return false;
    }
  } catch (err) {
    console.error(err);
  }
};
//
//
//
//
// Mark as paid
export const markExpensesAsPaid = async (activeUser) => {
  try {
    // Query to get all expenses for the specified UID and activeUser
    const userExpenseQuery = query(
      expenseCollectionRef,
      where("parentUID", "==", auth.currentUser.uid),
      where("userDocID", "==", activeUser)
    );

    // Get all the expenses from the query
    const querySnapshot = await getDocs(userExpenseQuery);

    // Get the start and end of the current week
    const startOfWeek = moment().startOf("isoWeek");
    const endOfWeek = moment().endOf("isoWeek");

    // Use Promise.all to wait for all updates to complete
    await Promise.all(
      querySnapshot.docs.map(async (expenseDoc) => {
        const expense = expenseDoc.data();

        // Check if there is a timestamp for the current week
        const thisWeeksTimestamp = expense.actionTimeStamps.find(
          (ts) =>
            moment(ts.timestamp).isSameOrAfter(startOfWeek) &&
            moment(ts.timestamp).isSameOrBefore(endOfWeek)
        );

        if (!thisWeeksTimestamp) {
          // There is no timestamp for this week, so create one
          const updatedTimeStamps = [
            ...expense.actionTimeStamps,
            {
              timestamp: new Date().toISOString()
            }
          ];
          // Update the document in the collection
          await updateDoc(doc(db, "expenses", expenseDoc.id), {
            actionTimeStamps: updatedTimeStamps
          });
        }
      })
    );

    console.log("Successfully updated all unpaid expenses");
    return true; // Return true to signal success
  } catch (error) {
    console.error("Error marking all expenses as paid:", error);
    return false; // Return false to signal failure
  }
};
