import moneyInZero from "../img/moneyInZeroState.png";

export default function JobContainer0State() {
  return (
    <div className="flexContainer">
      <div className="jobsListWrapper">
        <div className="sideContainerTitleZeroLeft">
          <h1>No Money In..</h1>
        </div>
        <div>
          <img
            className="zeroStateImage"
            src={moneyInZero}
            alt="Money In Zero State"
          />
        </div>
      </div>
    </div>
  );
}
