export const frequencies = [
  { id: 0, title: "Once per day", frequency: 1, timeSpanHours: 24 },
  { id: 1, title: "Multiple times per day", frequency: 99, timeSpanHours: 24 },
  { id: 2, title: "Once per week", frequency: 1, timeSpanHours: 168 },
  {
    id: 3,
    title: "Multiple times per week",
    frequency: 99,
    timeSpanHours: 168
  },
  { id: 4, title: "Every other week", frequency: 1, timeSpanHours: 336 },
  { id: 5, title: "Once per month", frequency: 1, timeSpanHours: 720 },
  {
    id: 6,
    title: "Multiple times per month",
    frequency: 99,
    timeSpanHours: 720
  }
];
