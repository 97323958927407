import React, { useState } from "react";

export default function ChooseAvatar({ setSelectedAvatar, avatars }) {
  const [selectedImg, setSelectedImg] = useState(null);

  const handleClick = (imageId) => {
    console.log("selected avatar is: " + imageId);
    setSelectedImg(imageId);
    setSelectedAvatar(imageId);
  };

  return (
    <div>
      <div className="titleDefault">
        <div className="introTitle">
          <h1>Choose an avatar!</h1>
        </div>
      </div>
      <div className="avatarSelectGrid">
        {avatars.map((image) => (
          <img
            key={image.id}
            src={image.src}
            alt={image.alt}
            onClick={() => handleClick(image.id)}
            className={`avatar ${
              selectedImg === image.id ? "avatarSelected" : ""
            }`}
          />
        ))}
      </div>
    </div>
  );
}
