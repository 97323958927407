export default function AccountCard({
  name,
  balance,
  image,
  onTitleClick,
  onAmtClick
}) {
  return (
    <div className="accountCard">
      <div className="acPrimary" onClick={onTitleClick}>
        <div>
          <img className="accountIcon" src={image} alt={name} />
        </div>
        <div className="accountTitle">
          <h5>{name}</h5>
        </div>
      </div>
      <div className="acSecondary" onClick={onAmtClick}>
        <div className="accountAmount">
          <h5>${(balance / 100).toFixed(2)}</h5>
        </div>
      </div>
    </div>
  );
}
