//Expenses List
export const expenses = [
  {
    id: 0,
    title: "Cleaning and folding laundry",
    amount: 1,
    frequency: 1,
    timeSpanHours: 168
  },
  {
    id: 1,
    title: "Doing the dishes",
    amount: 1,
    frequency: 1,
    timeSpanHours: 168
  },
  {
    id: 2,
    title: "Cleaning the kitchen",
    amount: 1,
    frequency: 1,
    timeSpanHours: 168
  },
  {
    id: 3,
    title: "Making dinner",
    amount: 1,
    frequency: 1,
    timeSpanHours: 168
  },
  {
    id: 4,
    title: "Cleaning the bathroom",
    amount: 1,
    frequency: 1,
    timeSpanHours: 168
  },
  {
    id: 5,
    title: "Wifi / Internet",
    amount: 0,
    frequency: null,
    timeSpanHours: null
  },
  {
    id: 6,
    title: "Utilities (e.g. power, water...)",
    amount: 1,
    frequency: 1,
    timeSpanHours: 168
  },
  {
    id: 7,
    title: "Entertainment (e.g. netflix...)",
    amount: 1,
    frequency: 1,
    timeSpanHours: 168
  },
  { id: 8, title: "Room & Board", amount: 1, frequency: 1, timeSpanHours: 168 },
  {
    id: 9,
    title: "Cut Grass",
    amount: 1,
    frequency: 1,
    timeSpanHours: 168
  },
  //
  // PUT ALL OTHER EXPENSES ABOVE THIS LINE
  {
    id: 99,
    title: "Create My Own",
    amount: 0,
    frequency: null,
    timeSpanHours: null
  }
];
