//Loan repayment Workflow
import { useEffect, useState } from "react";
import pigFly from "../img/PigFly.png";
import pigAtm from "../img/PigAtm.png";
import confirmJumpHurdle from "../img/ConfirmJumpHurdle.png";
import { payLoan } from "./LoanFunctions.js";
import moment from "moment";

export default function LoanRepaymentFlow({
  onClose,
  loanID,
  type,
  title,
  lender,
  amount,
  interest,
  dueDate,
  dueDays,
  createdOn,
  activeUser,
  getLoans,
  setCanCarry
}) {
  const [workflowStatus, setWorkflowStatus] = useState(type);

  let dollars = Math.floor(amount / 100);
  let cents = amount % 100;
  let simpleDueDate = moment(createdOn);

  const calculateDueDate = (createdOn, dueDays) => {
    // Parse the createdOn date with moment
    const createdDate = moment(createdOn);
    // Calculate the due date
    const dueDate = createdDate.add(dueDays, "days");
    // Format the due date string
    const dueDateString = `${dueDate.format("dddd, MMMM Do YYYY")}.`;
    return dueDateString;
  };

  const dueDateString = calculateDueDate(createdOn, dueDays);

  const payDebt = async () => {
    try {
      await payLoan(loanID, activeUser);
      console.log("Debt marked as paid");
      setWorkflowStatus("paymentCompleted");
      setCanCarry(true);
    } catch (error) {
      console.error("Was unable to mark it paid", error);
    }
  };

  const closeAndRefresh = async () => {
    try {
      await getLoans();
      onClose();
    } catch (error) {
      console.error("Couldn't refresh loans");
    }
  };

  let pageContent;
  switch (workflowStatus) {
    case "carry":
      pageContent = (
        <div className="earningModalBodyDefault">
          <div className="borrowWorkflowTitle">
            <h2>
              You Owe Your Parents ${dollars}.
              {cents.toString().padStart(2, "0")}{" "}
            </h2>
          </div>
          <div className="borrowColumnContainer">
            <div className="borrowColumn-1">
              <img className="borrowImages" src={pigFly} alt="A pig flying" />
            </div>
            <div className="borrowColumn-2">
              <div className="loanReceipt">
                <p>
                  {" "}
                  I delayed paying{" "}
                  <span>
                    ${dollars}.{cents.toString().padStart(2, "0")}
                  </span>{" "}
                  on <span>{moment(createdOn).format("MMMM Do YYYY")}</span> and
                  I agreed to pay it back <span>ASAP.</span>
                </p>
              </div>
              <div className="borrowControlsStack">
                <button
                  className={"buttonStyle4"}
                  onClick={() => setWorkflowStatus("confirmPayment")}
                >
                  Pay It Now
                </button>
                <button className={"buttonStyle5"}>Cancel This Debt</button>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    case "balanceBorrow":
      pageContent = (
        <div className="earningModalBodyDefault">
          <div className="borrowWorkflowTitle">
            <h2>
              You Owe {lender} ${dollars}.{cents.toString().padStart(2, "0")}{" "}
            </h2>
          </div>
          <div className="borrowColumnContainer">
            <div className="borrowColumn-1">
              <img className="borrowImages" src={pigAtm} alt="Pig at the ATM" />
            </div>
            <div className="borrowColumn-2">
              <div className="loanReceipt">
                <p>
                  {" "}
                  I borrowed{" "}
                  <span>
                    ${dollars}.{cents.toString().padStart(2, "0")}
                  </span>{" "}
                  from <span>{lender},</span> and I agreed to pay it back by{" "}
                  <span>{dueDateString}</span>
                </p>
              </div>
              <div className="borrowControlsStack">
                <button
                  className={"buttonStyle4"}
                  onClick={() => setWorkflowStatus("confirmPayment")}
                >
                  Pay It Now
                </button>
                <button className={"buttonStyle5"}>Cancel This Debt</button>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    case "confirmPayment":
      pageContent = (
        <div className="earningModalBodyDefault">
          <div className="earningModalConfirmationContent">
            <img
              className="earningModalCinformationAvatar"
              src={confirmJumpHurdle}
              alt="Piggy bank Jumping a hurdle"
            />
            <h2>Pay Your Debt?</h2>
            <p>
              Once you've paid the ${dollars}.
              {cents.toString().padStart(2, "0")} you owe, <br /> tap the button
              below.
            </p>
          </div>
          <div className="earningModalcenterFooter">
            <button className={"buttonStyle4"} onClick={payDebt}>
              I Paid It
            </button>
            <button className={"buttonStyle5"} onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      );
      break;
    case "paymentCompleted":
      pageContent = (
        <div className="earningModalBodyDefault">
          <div className="earningModalConfirmationContent">
            <img
              className="earningModalCinformationAvatar"
              src={confirmJumpHurdle}
              alt="Piggy bank Jumping a hurdle"
            />
            <h2>Your Debt is Paid!</h2>
            <p>
              Great job paying back money you owe. The debt has been removed
              from your dashboard.
            </p>
          </div>
          <div className="earningModalcenterFooter">
            <button className={"buttonStyle4"} onClick={closeAndRefresh}>
              Done
            </button>
          </div>
        </div>
      );
      break;

    default:
      break;
  }

  return (
    <div className="actModalOverlay">
      <div className="earningModalCont">
        <div className="earningModalHeader">
          <button className="buttonStyle3" onClick={onClose}>
            X
          </button>
        </div>
        <div className="">{pageContent}</div>
      </div>
    </div>
  );
}
