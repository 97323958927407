import AccountCard from "./AccountCard.js";

export default function Accounts({
  accountList,
  images,
  onAccountClick,
  onAcctTitleClick,
  onAcctBalanceClick,
  onAddAcctClick
}) {
  console.log(accountList);

  return (
    <div className="accountsContainer">
      <div className="centeredAccountElements">
        <div className="dashedLine"></div>
        <p>Your Money Is:</p>
        <div className="dashedLine"></div>
      </div>
      <div className="acctColumnsContainer">
        <div className="accountsColumn-1">
          <h5>In Savings:</h5>
          <div className="jobsList">
            {accountList.map((account) => {
              const matchingImage = images.find(
                (image) => image.id === account.image
              );
              const imageSrc = matchingImage
                ? matchingImage.src
                : "path/to/default/image.jpg"; // Replace with the path to a default image or any other fallback logic you'd prefer.
              return (
                <AccountCard
                  key={account.id}
                  name={account.name}
                  balance={account.amount}
                  image={imageSrc}
                  onTitleClick={() => onAcctTitleClick(account)}
                  onAmtClick={() => onAcctBalanceClick(account)}
                />
              );
            })}
          </div>
          <div className="fullCenter">
            <button onClick={onAddAcctClick} className="buttonStyle3">
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
