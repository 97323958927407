import React, { useEffect, useRef, useState } from "react";
import Arrow from "../Arrow.js";
import ExpenseCell from "./ExpenseCell.js";
import moment from "moment";
import LoanCell from "../loanComponents/LoanCell.js";

export default function ExpenseContainer({
  expenseList,
  getExpenseList,
  activeUser,
  currentDate,
  endOfWeek,
  loanList,
  setSelectedCell,
  setIsExpenseEditModalOpen,
  setIsPayLoanWorkflowOpen,
  setSelectedLoan
}) {
  const flexContainerRef = useRef(null);
  const jobsListRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (
      jobsListRef.current.scrollHeight > flexContainerRef.current.clientHeight
    ) {
      setIsOverflowing(true);
    } else {
      setIsOverflowing(false);
    }
  }, [expenseList]); // This effect runs whenever jobList changes
  //

  function handleCellClick(id) {
    setSelectedCell(expenseList.find((cell) => cell.id === id));
    setIsExpenseEditModalOpen(true);
    console.log("opening expense: ", id);
  }

  function handleLoanClick(id) {
    setSelectedLoan(loanList.find((loan) => loan.id === id));
    setIsPayLoanWorkflowOpen(true);
    console.log("opening loan: ", id);
  }

  return (
    <div
      className={`flexContainer ${isOverflowing ? "overflowing" : ""}`}
      ref={flexContainerRef}
    >
      <Arrow />
      <div className="jobsListWrapper">
        <div className="sideContainerTitleRight">
          <h1>Money Out:</h1>
        </div>
        <div className="jobsList" ref={jobsListRef}>
          {expenseList.map((cell) => {
            const today = moment(currentDate);
            // Determine the next occurrence of the selected 'endOfWeek'
            let endOfWeekDate =
              today.day() <= moment().day(endOfWeek)
                ? moment(currentDate).day(endOfWeek)
                : moment(currentDate).add(1, "weeks").day(endOfWeek);
            const bufferDay = moment(endOfWeekDate).add(1, "days");
            const startOfWeek = moment(endOfWeekDate).subtract(6, "days");
            let dueInDays = endOfWeekDate.diff(today, "days");
            const paidThisWeek = cell.actionTimeStamps.some((ts) => {
              const timestampMoment = moment(ts.timestamp);
              return (
                timestampMoment.isSameOrAfter(startOfWeek) &&
                timestampMoment.isSameOrBefore(endOfWeekDate)
              );
            });
            let statusText = ""; // Default value
            if (today.isSame(bufferDay, "day")) {
              statusText = paidThisWeek ? "Paid" : "Overdue";
            } else if (paidThisWeek) {
              statusText = "Paid";
            } else {
              statusText =
                dueInDays === 0 ? "Due Today" : `Due in ${dueInDays} days`;
            }
            return (
              <ExpenseCell
                key={cell.id}
                title={cell.title}
                amount={cell.amount}
                paidStatusText={statusText}
                onClick={() => handleCellClick(cell.id)}
              />
            );
          })}
          {loanList.map((loan) => {
            return (
              <LoanCell
                key={loan.id}
                type={loan.type}
                title={loan.title}
                lender={loan.lender}
                amount={loan.amount}
                interest={loan.interest}
                dueDate={loan.dueDate}
                dueDays={loan.dueDays}
                onClick={() => handleLoanClick(loan.id)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
