//JobCompletedModal.js
export default function JobCompletedModal({ timeSpanHours, onClose }) {
  console.log("timeSpan is: ", timeSpanHours);

  let pageContent;
  switch (timeSpanHours) {
    case 24:
      pageContent = (
        <p>
          This job has already been marked done. It can only be completed once a
          day.
        </p>
      );
      break;
    case 168:
      pageContent = (
        <p>
          This job has already been marked done. It can only be completed once a
          week.
        </p>
      );
      break;
    case 336:
      break;
    case 720:
      break;
    default:
      break;
  }

  return (
    <div className="actModalOverlay">
      <div className="earningModalCont">
        <div className="earningModalHeader">
          <button className="buttonStyle3" onClick={onClose}>
            X
          </button>
        </div>
        <div className="earningModalBodyDefault">
          <div className="earningModalConfirmationContent">
            <div className="bigCircle">
              <span className="iconCheckmark">&#10003;</span>
            </div>
            <h2>Job Completed!</h2>
            {pageContent}
          </div>
          <div className="earningModalcenterFooter">
            <button className={"buttonStyle4"} onClick={onClose}>
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
