import { useEffect } from "react";

export default function StatusContainer({
  currentBal,
  images,
  onViewClick,
  getAccountList,
  refreshJobs,
  getExpenseList,
  savingsVisible
}) {
  useEffect(() => {
    getAccountList();
    refreshJobs();
    getExpenseList();
  }, []);

  let dollars = Math.floor(currentBal / 100);
  let cents = currentBal % 100;
  const selectedImage = images.find((image) => image.id === 1);

  return (
    <div className="expandFull">
      <div className={`contentWrapper ${savingsVisible ? "slideUp" : ""}`}>
        <div className="statusContainer">
          {selectedImage ? (
            <img
              className="youHaveAvatar"
              src={selectedImage.src}
              alt={selectedImage.alt}
            />
          ) : (
            <p>Loading...</p> // Or any placeholder you'd like
          )}
          <div>
            <h1>You Have</h1>
            <p className="youHave">
              <span className="dollarSign">$</span>
              <span className="dollarAmount">{dollars}.</span>
              <span className="centAmount">
                {cents.toString().padStart(2, "0")}
              </span>
            </p>

            <button
              className="framelessButton"
              style={{ visibility: savingsVisible ? "hidden" : "visible" }}
              onClick={onViewClick}
            >
              View Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
