import { useState } from "react";
import { db, auth } from "./Firebase";
import {
  getDocs,
  collection,
  query,
  where,
  updateDoc
} from "firebase/firestore";
import EntryControl from "./EntryControl.js";
import { removeAccount } from "./bankAccounts/AccountFunctions.js";

export default function BalanceModal({
  existingAccount,
  selectedAccount,
  selectedAccountId,
  images,
  onClose,
  getAccountList,
  activeUser
}) {
  const selectedImg = images.find(
    (image) => image.id === selectedAccount.image
  );
  const buttons = ["1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "0", "←"];
  const [currentBalance, setCurrentBalance] = useState(
    selectedAccount?.amount / 100
  );
  const [inputValue, setInputValue] = useState(currentBalance.toFixed(2));
  const [userHasStartedInput, setUserHasStartedInput] = useState(false);
  const accountsCollectionRef = collection(db, "accounts");

  const handleButtonPress = (value) => {
    if (!userHasStartedInput) {
      if (value === "←") return; // Do nothing if the user's first action is trying to delete
      if (value === ".") {
        setInputValue("0.");
      } else {
        setInputValue(value);
      }
      setUserHasStartedInput(true);
      return;
    }

    if (value === "←") {
      setInputValue((prev) => prev.slice(0, -1)); // remove last character
    } else if (value === "." && inputValue.includes(".")) {
      // Avoid adding multiple decimal points
      return;
    } else {
      // Check if two characters after the decimal point are already there
      const decimalIndex = inputValue.indexOf(".");
      if (
        decimalIndex !== -1 &&
        inputValue.slice(decimalIndex + 1).length >= 2
      ) {
        return;
      }
      setInputValue((prev) => prev + value);
    }
  };

  const numericalValue = parseFloat(inputValue);

  let dollars = parseInt(numericalValue, 10);
  let cents = Math.round((numericalValue - dollars) * 100);

  const handleSave = async () => {
    const newAmount = parseFloat(inputValue) * 100;
    console.log("attempting to commit changes ");
    // Check to see if a user is signed in
    if (!auth.currentUser) {
      console.error("No user logged in!");
      return;
    }
    // Query documents where userId matches the authenticated user's UID
    const userAccountsQuery = query(
      accountsCollectionRef,
      where("parentUID", "==", auth.currentUser.uid),
      where("userDocID", "==", activeUser)
    );
    try {
      const snapshot = await getDocs(userAccountsQuery);
      let docRef;
      // find the document with the provided ID
      snapshot.forEach((doc) => {
        if (doc.id === selectedAccountId) {
          docRef = doc.ref;
        }
      });
      //if found, update the document
      if (docRef) {
        await updateDoc(docRef, {
          amount: newAmount
        });
        console.log("I updated the document = " + docRef);
        getAccountList();
        onClose();
      } else {
        console.error("account with given ID not found");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleExit = () => {
    setInputValue(0);
    setUserHasStartedInput(false);
    onClose();
  };

  const handleRemove = async () => {
    const success = await removeAccount(selectedAccount.id, activeUser);
    console.log("trying to delete account: " + selectedAccount.id);
    if (success) {
      console.log("success");
      getAccountList();
      onClose();
    } else {
      // handle error case here
    }
  };

  return (
    <div className="actModalOverlay">
      <div className="actModalContent">
        <div className="modalTitleControl">
          <h1>{selectedAccount.name}</h1>
          <button className="buttonStyle3" onClick={handleExit}>
            X
          </button>
        </div>
        <div className="actModalColumns">
          <div className="actColumn-1">
            <h1>This account has:</h1>
            <div className="introTitle centeredElement">
              <p className="youHave">
                <span className="dollarSign">$</span>
                <span className="dollarAmount">{dollars}.</span>
                <span className="centAmount">
                  {cents.toString().padStart(2, "0")}
                </span>
              </p>
            </div>
            {selectedImg && (
              <img
                className="actAvatar"
                src={selectedImg.src}
                alt={selectedImg.alt}
              />
            )}
          </div>
          <div className="column-2">
            <div className="button-grid">
              {buttons.map((button) => (
                <EntryControl
                  key={button}
                  value={button}
                  onClick={() => handleButtonPress(button)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="modalFooterControls2">
          <button className="button" onClick={handleRemove}>
            delete
          </button>
          <button className="button" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
