// PaydayFTUEflag.js

import moneyBag from "../img/pigMoneyToss.png";

export default function PaydayFTUEflag({ guideSend }) {
  return (
    <div className="earningsContainer">
      <div className="earningsParent">
        <div className="earningsAvatar">
          <img className="earningsIcon" src={moneyBag} alt={"bagOfMoney"} />
        </div>
        <div className="earningsCell">
          <h5>You've Earned:</h5>
          <div className="earningsControl">
            <div className="earningSummaryControl">
              <button className="buttonStyle2" onClick={() => guideSend("GO")}>
                Get Paid
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
