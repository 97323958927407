//ExpenseModal
import { useState } from "react";
import { createExpense } from "./ExpenseFunctions.js";
import { expenses } from "./Expenses.js";
import expFTUE1 from "../img/ExpenseFTUE1.png";

export default function ExpenseCreateFlow({
  onClose,
  getExpenseList,
  title = "",
  amount = 100,
  frequency = 99,
  timeSpanHours = 99,
  expenseEntryPosition,
  activeUser,
  guideSend
}) {
  const [workflowState, setNewWorkflowState] = useState(expenseEntryPosition);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [prevExpenseChoice, setPrevExpenseChoice] = useState(null);
  const [newTitle, setNewTitle] = useState(title);
  const [newAmount, setNewAmount] = useState(amount);
  const [newFrequency, setNewFrequency] = useState(frequency);
  const [newTimeSpanHours, setNewTimeSpanHours] = useState(timeSpanHours);
  const [showInput, setShowInput] = useState(false);

  let dollars = Math.floor(newAmount / 100);
  let cents = newAmount % 100;

  const handleExpenseSelection = (expenseId) => {
    // Check if jobId is 4 and hide entry field.
    if (expenseId === 10) {
      setShowInput(true);
      console.log("Show Input");
    }
    if (prevExpenseChoice === expenseId) {
      setSelectedExpense(null);
      setPrevExpenseChoice(null);
      setShowInput(false);
      console.log("No Show Input");
    } else {
      setSelectedExpense(expenseId);
      setPrevExpenseChoice(expenseId);
      // Look up the job in the array
      const selectedExpenseObj = expenses.find(
        (expense) => expense.id === expenseId
      );
      // if a job is located, pull default variables from it and
      //populate the attributes for the remainder of the workflow:
      if (selectedExpenseObj) {
        setNewTitle(selectedExpenseObj.title);
        setNewFrequency(selectedExpenseObj.frequency);
        setNewTimeSpanHours(selectedExpenseObj.timeSpanHours);
      }
    }
  };

  const increment = () => {
    setNewAmount(newAmount + 50);
  };
  const decrement = () => {
    if (newAmount > 0) {
      // optional check to prevent negative values
      setNewAmount(newAmount - 50);
    }
  };

  const handleFinalIncrement = async () => {
    const wasSuccessful = await createExpense(
      newTitle,
      newFrequency,
      newTimeSpanHours,
      newAmount,
      activeUser
    );
    if (wasSuccessful) {
      console.log("Expense was successfully created");
      getExpenseList();
      guideSend("DONE");
      onClose();
    } else {
      console.log("Expense creation failed.");
    }
  };

  let pageContent;
  switch (workflowState) {
    case 0:
      pageContent = (
        <>
          <div className="roundColumn1">
            <div>
              <div className="jobModalTitleContainer">
                <h1>Select a service your child can help pay for</h1>
              </div>
              <p>
                <strong>Parent Tip: </strong>Create a list of services that you
                pay for or perform that they might not be aware of. Create a
                list of the things you do around the house and the amount of
                time it takes you.
                <br />
                <br />
                <strong>Why? </strong>’Around the house’ jobs are often
                thankless. This helps children begin to recognize how much
                effort it takes to keep things running. Later on, you can use
                this list to create ‘around the house’ jobs for your child to do
                for payment
              </p>
            </div>
            <img
              className="jobWorkflowAvatar"
              src={expFTUE1}
              alt="Pig running on a treadmill"
            />
          </div>
          <div className="roundColumn2">
            <div className="jobModalExitContainer">
              <button
                className="buttonStyle3"
                onClick={() => guideSend("CLOSE")}
              >
                X
              </button>
            </div>
            <div className="modalActionContents">
              <div className="listWrapper">
                {expenses
                  .filter((expense) => expense.id >= 0 && expense.id <= 7)
                  .map((expense) => (
                    <button
                      key={expense.id}
                      className={
                        selectedExpense === expense.id
                          ? "jobChoiceSelected"
                          : "jobChoice"
                      }
                      onClick={() => handleExpenseSelection(expense.id)} // Passing jobId
                    >
                      {expense.title}
                    </button>
                  ))}
              </div>
              {showInput && (
                <input
                  type="text"
                  placeholder="Enter your expense title"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                />
              )}
            </div>
            <div className="modalFooterControlSingle ">
              <button
                className={
                  selectedExpense !== null ? "button" : "button-disabled"
                }
                onClick={
                  selectedExpense !== null ? () => setNewWorkflowState(1) : null
                }
              >
                Next
              </button>
            </div>
          </div>
        </>
      );
      break;
    case 1:
      //
      //workflow start
      pageContent = (
        <>
          <div className="roundColumn1">
            <div>
              <div className="jobModalTitleContainer">
                <h1>How much will your child pay each week for {newTitle}</h1>
              </div>
              <p>
                <strong>Parent Tip: </strong>Don’t set the price too high. The
                goal is to help them recognize that life has costs that they
                need to balance by earning money. Consider setting the price of
                expenses so that their total weekly cost is just under what they
                are earning.
              </p>
            </div>
            <img
              className="jobWorkflowAvatar"
              src={expFTUE1}
              alt="Pig running on a treadmill"
            />
          </div>
          <div className="roundColumn2">
            <div className="jobModalExitContainer">
              <button
                className="buttonStyle3"
                onClick={() => guideSend("CLOSE")}
              >
                X
              </button>
            </div>
            <div className="modalActionContents">
              <div className="modalCounter">
                <button className="button" onClick={decrement}>
                  -
                </button>
                <h2>
                  ${dollars}.{cents.toString().padStart(2, "0")}
                </h2>
                <button className="button" onClick={increment}>
                  +
                </button>
              </div>
            </div>
            <div className="modalFooterControls ">
              <button className="button" onClick={() => setNewWorkflowState(0)}>
                Back
              </button>
              <button className="button" onClick={handleFinalIncrement}>
                Next
              </button>
            </div>
          </div>
        </>
      );
      break;
    //
    //
    //
    //
    //
    //
    //
    case 2:
      pageContent = (
        <>
          <div className="roundColumn1">
            <div>
              <div className="jobModalTitleContainer">
                <h1>Select a service or expense your child can pay towards.</h1>
              </div>
              <p>
                <strong>Parent Tip: </strong>Create a list of services that you
                pay for or perform that they might not be aware of. Create a
                list of the things you do around the house and the amount of
                time it takes you.
                <br />
                <br />
                <strong>Why? </strong>’Around the house’ jobs are often
                thankless. This helps children begin to recognize how much
                effort it takes to keep things running. Later on, you can use
                this list to create ‘around the house’ jobs for your child to do
                for payment
              </p>
            </div>
            <img
              className="jobWorkflowAvatar"
              src={expFTUE1}
              alt="Pig running on a treadmill"
            />
          </div>
          <div className="roundColumn2">
            <div className="jobModalExitContainer">
              <button
                className="buttonStyle3"
                onClick={() => guideSend("CLOSE")}
              >
                X
              </button>
            </div>
            <div className="modalActionContents">
              <div className="listWrapper">
                {expenses
                  .filter((expense) => expense.id >= 0 && expense.id <= 10)
                  .map((expense) => (
                    <button
                      key={expense.id}
                      className={
                        selectedExpense === expense.id
                          ? "jobChoiceSelected"
                          : "jobChoice"
                      }
                      onClick={() => handleExpenseSelection(expense.id)} // Passing jobId
                    >
                      {expense.title}
                    </button>
                  ))}
                {showInput && (
                  <input
                    type="text"
                    placeholder="Enter your expense title"
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                  />
                )}
              </div>
            </div>
            <div className="modalFooterControlSingle ">
              <button
                className={
                  selectedExpense !== null ? "button" : "button-disabled"
                }
                onClick={
                  selectedExpense !== null ? () => setNewWorkflowState(3) : null
                }
              >
                Next
              </button>
            </div>
          </div>
        </>
      );
      break;
    case 3:
      //
      //workflow start
      pageContent = (
        <>
          <div className="roundColumn1">
            <div>
              <div className="jobModalTitleContainer">
                <h1>
                  Select how much your <br /> child will pay each week
                  <br /> for this service.
                </h1>
              </div>
              <p>
                <strong>Parent Tip: </strong>Don’t set the price too high. The
                goal is to help them recognize that life has costs that they
                need to balance by earning money. Consider setting the price of
                expenses so that their total weekly cost is just under what they
                are earning.
              </p>
            </div>
            <img
              className="jobWorkflowAvatar"
              src={expFTUE1}
              alt="Pig running on a treadmill"
            />
          </div>
          <div className="roundColumn2">
            <div className="jobModalExitContainer">
              <button
                className="buttonStyle3"
                onClick={() => guideSend("CLOSE")}
              >
                X
              </button>
            </div>
            <div className="modalActionContents">
              <div className="modalCounter">
                <button className="button" onClick={decrement}>
                  -
                </button>
                <h2>
                  ${dollars}.{cents.toString().padStart(2, "0")}
                </h2>
                <button className="button" onClick={increment}>
                  +
                </button>
              </div>
            </div>
            <div className="modalFooterControls ">
              <button className="button" onClick={() => setNewWorkflowState(2)}>
                Back
              </button>
              <button className="button" onClick={handleFinalIncrement}>
                Next
              </button>
            </div>
          </div>
        </>
      );
      break;

    default:
  }

  return (
    <div className="actModalOverlay">
      <div className="jobModal">{pageContent}</div>
    </div>
  );
}
