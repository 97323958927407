import { useState, useEffect, useCallback } from "react";
import { CSSTransition } from "react-transition-group";

//Component Imports
import JobContainer from "./jobComponents/JobContainer.js";
import ExpenseContainer from "./expenseComponents/ExpenseContainer.js";
import StatusContainer from "./StatusContainer.js";
import HeaderBar from "./HeaderBar.js";
import SignIn from "./SignIn.js";
import Accounts from "./bankAccounts/Accounts.js";
import pig1 from "./img/pig1.png";
import pig2 from "./img/pig2.png";
import pig3 from "./img/pig3.png";
import pig4 from "./img/pig4.png";
import pig5 from "./img/pig5.png";
import CreateAcctModal from "./bankAccounts/CreateAcctModal.js";
import { db, auth } from "./Firebase";
import Menu from "./Menu.js";
import { signOut } from "firebase/auth";
import AccountEditModal from "./bankAccounts/AccountEditModal.js";
import BalanceModal from "./BalanceModal.js";
import JobCreateFlowDefault from "./jobComponents/JobCreateFlowDefault.js";
import JobCreateFirstJobFLow from "./jobComponents/JobCreateFirstJobFlow.js";
import CreateDailyJobFlag from "./jobComponents/CreateDailyJobFlag.js";
import { getJobsList } from "./jobComponents/JobsFunctions.js";
import TotalEarningsFlag from "./getPaidComponents/TotalEarningsFlag.js";
import ExpenseCreateFlow from "./expenseComponents//ExpenseCreateFlow.js";
import JobContainer0State from "./jobComponents/JobContainer0State.js";
import Expenses0State from "./expenseComponents/Expenses0State.js";
import CreateWeeklyExpenseFlag from "./expenseComponents/CreateFirstExpenseFlag.js";
import moment from "moment";
import UserPicker from "./UserPicker.js";
import getUserContext, {
  getUserIdentityDoc,
  deleteUser
} from "./identity/IdentityFunctions.js";
import { getDocs, collection, query, where } from "firebase/firestore";
import {
  Level1MovementAddJobFlag,
  Level1MovementAddExpFlag,
  Level1MovementJobFTUE,
  Level1MovementExpenseFTUE
} from "./Levels/JobsAndExpenseLevel1.js";
import { startupMachine } from "./StartupMachine.js";
import { useMachine } from "@xstate/react";
import { guidanceMachine } from "./identity/GuidanceMachine.js";
import { PayDayFTUE } from "./getPaidComponents/PayDayFTUE.js";
import RemoveUserModal from "./identity/RemoveUserModal.js";
import PaydayFTUEflag from "./getPaidComponents/PaydayFTUEflag.js";
import {
  JobsAndExpenseLevel2Flag,
  JobsAndExpenseLevel2Guide
} from "./Levels/JobsAndExpenseLevel2.js";
import JobEditModal from "./jobComponents/JobEditModal.js";
import JobCompletedModal from "./jobComponents/JobCompletedModal.js";
import ExpenseEditModal from "./expenseComponents/ExpenseEditModal.js";
import LoanRepaymentWorkflow from "./loanComponents/LoanRepaymentWorkflow.js";
import IntroFTUE from "./IntroFTUE.js";
import PayDayWorkflow from "./getPaidComponents/PayDayWorkflow.js";
import TimeToPaydayFlag from "./getPaidComponents/TimeToPaydayFlag.js";

const images = [
  { id: 1, src: pig1, alt: "pig1" },
  { id: 2, src: pig2, alt: "pig2" },
  { id: 3, src: pig3, alt: "pig3" },
  { id: 4, src: pig4, alt: "pig1" },
  { id: 5, src: pig5, alt: "pig2" },
  { id: 6, src: pig1, alt: "pig3" },
  { id: 7, src: pig1, alt: "pig1" },
  { id: 8, src: pig2, alt: "pig2" },
  { id: 9, src: pig3, alt: "pig3" },
  { id: 10, src: pig4, alt: "pig1" },
  { id: 11, src: pig5, alt: "pig2" },
  { id: 12, src: pig1, alt: "pig3" }
];

export default function AppContainer() {
  let [curBalance, setCurrBal] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState(1);
  const [isMainViewVisible, setMainViewVisible] = useState(true);
  const [isAcctModalOpen, setIsAcctModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [existingAccount, setExistingAccount] = useState(null);
  const [accountList, setAccountList] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const [userName, setUsername] = useState(null);
  const accountsRef = collection(db, "accounts");
  const expenseCollectionRef = collection(db, "expenses");
  const loanCollectionRef = collection(db, "loans");
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [getPaidModal, setGetPaidModal] = useState(false);
  const [totalEarningsShow, setTotalEarningsShow] = useState(true);
  const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false);
  const [expenseList, setExpenseList] = useState([]);
  const [isFirstJobWorkflowOpen, setIsFirstJobWorkflowOpen] = useState(false);
  const [isJobWorkflowOpen, setIsJobWorkflowOpen] = useState(false);
  const [expenseEntryPosition, setExpenseEntryPosition] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [endOfWeek, setEndOfWeek] = useState("Friday");
  const [jobsCreated, setJobsCreated] = useState(0);
  const [expCreated, setExpCreated] = useState(0);
  const [userPayCycles, setUserPayCycles] = useState(0);
  const [loanList, setLoanList] = useState([]);
  const [familyDocId, setFamilyDocId] = useState(null);
  const [numberOfUnpaidJobs, setNumberOfUnpaidJobs] = useState(0);
  const [userPickerOpen, setUserPickerOpen] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [isJobEditModalOpen, setIsJobEditModalOpen] = useState(false);
  const [isAlreadyCompletedOpen, setIsAlreadyCompletedOpen] = useState(false);
  const [isExpenseEditModalOpen, setIsExpenseEditModalOpen] = useState(false);
  const [isPayLoanWorkflowOpen, setIsPayLoanWorkflowOpen] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [savingsVisible, setSavingsVisible] = useState(false);
  const [isPayday, setIsPayday] = useState(false);
  //
  // User Leveling
  const [numOfFamilyUsers, setNumOfFamilyUsers] = useState(0);
  const [seenSignupFTUE, setSeenSignupFTUE] = useState(true);
  const [seenJobFTUE, setSeenJobFTUE] = useState(false);
  const [seenExpFTUE, setSeenExpFTUE] = useState(false);
  const [iSawPaydayFTUE, setISawPaydayFTUE] = useState(false);
  const [canBorrow, setCanBorrow] = useState(true);
  const [canCarry, setCanCarry] = useState(true);
  const [streakLvl, setStreakLvl] = useState(1);
  const [streakLvl1Status, setStreakLvl1Status] = useState("available");
  const [payBalanceLvl, setPayBalanceLvl] = useState(1);
  const [testBalance, setTestBalance] = useState("positive");
  const [needsBreak, setNeedsBreak] = useState(false);
  const [seenJobsAndExpLevel2, setSeenJobsAndExpLevel2] = useState(false);
  //
  //

  const logOut = async () => {
    try {
      await signOut(auth, email, password);
      setMenuOpen(false);
      console.log("I signed out");
      startupSend("SIGNOUT");
      setActiveUser(null);
      setAuthenticated(false);
      setUsername(null);
    } catch (err) {
      console.error(err);
    }
  };

  const removeUser = async () => {
    try {
      const success = await deleteUser(activeUser);
      if (success) {
        setMenuOpen(false);
        setRemoveUserModal(false);
        setActiveUser(null);
        setUserPickerOpen(true);
      } else {
        console.error("Failed to delete the user.");
      }
    } catch (err) {
      console.error(err);
    }
  };
  //startupSend
  //
  //
  //// Get User Identity Attributes
  async function refreshIdentity() {
    console.log("REFRESH IDENTITY CALLED");

    const identityData = await getUserIdentityDoc(activeUser, familyDocId);
    if (identityData.success) {
      console.log("i got identity data: ", identityData.data);
      //
      //
      // Check for seenExpFTUE and update the local context/state if they exist
      if ("seenExpFTUE" in identityData.data) {
        console.log(
          "seenExpFTUE exists in the user data: ",
          identityData.data.seenExpFTUE
        );
        setSeenExpFTUE(identityData.data.seenExpFTUE);
        guideSend({
          type: "UPDATE_SEEN_EXP_FTUE",
          value: identityData.data.seenExpFTUE // Correcting property access
        });
      } else {
        setSeenExpFTUE(false);
        console.log("seenExpFTUE does not exist in the user data.");
      }
      //
      //
      // Check for seenExpFTUE and update the local context/state if they exist
      if ("seenJobFTUE" in identityData.data) {
        console.log(
          "seenJobFTUE exists in the user data: ",
          identityData.data.seenJobFTUE
        );
        setSeenJobFTUE(identityData.data.seenJobFTUE);
        guideSend({
          type: "UPDATE_SEEN_JOB_FTUE",
          value: identityData.data.seenJobFTUE
        });
      } else {
        setSeenJobFTUE(false);
        console.log("seenJobFTUE does not exist in the user data.");
      }
      //
      //
      // Check for seenExpFTUE and update the local context/state if they exist
      if ("payCycles" in identityData.data) {
        console.log(
          "payCYCLES exists in the user data: ",
          identityData.data.payCycles
        );
        setUserPayCycles(identityData.data.payCycles);
        guideSend({
          type: "UPDATE_PAYCYCLES",
          value: identityData.data.payCycles
        });
      } else {
        setUserPayCycles(0);
        console.log("seenJobFTUE does not exist in the user data.");
      }
      //
      //
      if ("iSawPaydayFTUE" in identityData.data) {
        console.log(
          "iSawPaydayFTUE exists in the user data: ",
          identityData.data.iSawPaydayFTUE
        );
        setISawPaydayFTUE(identityData.data.iSawPaydayFTUE);
        guideSend({
          type: "UPDATE_SAWPAYDAYFTUE",
          value: identityData.data.iSawPaydayFTUE
        });
      } else {
        setISawPaydayFTUE(false);
        console.log("iSawPaydayFTUE does not exist in the user data.");
      }
      //
      //
      if ("seenJobsAndExpLevel2" in identityData.data) {
        console.log(
          "seenJobsAndExpLevel2 exists in the user data: ",
          identityData.data.seenJobsAndExpLevel2
        );
        setSeenJobsAndExpLevel2(identityData.data.seenJobsAndExpLevel2);
        guideSend({
          type: "UPDATE_JOBSANDEXPENSESLEVEL2",
          value: identityData.data.seenJobsAndExpLevel2
        });
      } else {
        setSeenJobsAndExpLevel2(false);
        console.log("seenJobsAndExpLevel2 does not exist in the user data.");
      }
      //
      // TERMINATION OF IDENTITY ATTRIBUTE LOAD
      //
      setMainViewVisible(true);
      setTotalEarningsShow(false);
      setSavingsVisible(false);
      guideSend("RESET_GUIDE_MACHINE");
    } else {
      console.error("Failed to get identity data: ", identityData.error);
    }
  }
  //
  //
  // Accounts, Jobs & Expenses ------------------------------ --------- ------- ------- --------- section
  // Get the accounts list and build the accounts array
  const getAccountList = async () => {
    // Make sure there's an authenticated user
    if (!auth.currentUser) {
      console.error("No user logged in!");
      return;
    }
    //
    // Get Family Documents
    try {
      const querySnapshot = await getDocs(
        query(
          accountsRef,
          where("parentUID", "==", auth.currentUser.uid),
          where("userDocID", "==", activeUser)
        )
      );
      const filteredData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      }));
      setAccountList(filteredData);
      console.log("account List Updated for user: ", activeUser);
    } catch (err) {
      console.error(err);
    }
  };
  //
  //
  //
  //
  // Get the expense list and build the expense array
  const getExpenseList = async () => {
    // check to ensure someone is signed in
    if (!auth.currentUser) {
      console.error("No user logged in!");
      return;
    }
    // Query documents where user ID matches auth user ID
    const userExpenseQuery = query(
      expenseCollectionRef,
      where("parentUID", "==", auth.currentUser.uid),
      where("userDocID", "==", activeUser)
    );
    try {
      const data = await getDocs(userExpenseQuery);
      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      }));
      const numExpenses = Array.isArray(filteredData) ? filteredData.length : 0;
      console.log("I see this many expenses: ", numExpenses);
      setExpCreated(numExpenses);
      guideSend({
        type: "UPDATE_EXPCREATED",
        value: numExpenses // Correcting property access
      });
      guideSend("RESET_GUIDE_MACHINE");
      setExpenseList(filteredData);
      // Calculate total expenses for those without a timestamp from the current week
      const startOfWeek = moment().startOf("isoWeek");
      const endOfWeek = moment().endOf("isoWeek");
      const totalExpenses = filteredData.reduce((sum, expense) => {
        const thisWeeksTimestamp = expense.actionTimeStamps.find(
          (ts) =>
            moment(ts.timestamp).isSameOrAfter(startOfWeek) &&
            moment(ts.timestamp).isSameOrBefore(endOfWeek)
        );
        // If there's no timestamp for this week, add the expense amount
        if (!thisWeeksTimestamp) {
          return sum + expense.amount;
        }
        return sum;
      }, 0);
      setTotalExpenses(totalExpenses);
      //console.log("Total expenses for this week: ", totalExpenses);
    } catch (err) {
      console.error(err);
    }
  };
  //
  //
  // Loans Management
  // Pull the user's loan list and build the jobs array
  async function getLoans() {
    // check to ensure a user is signed in
    if (!auth.currentUser) {
      console.error("No user logged in!");
      return;
    }
    // Query documents where user ID matches auth user ID
    const userLoanQuery = query(
      loanCollectionRef,
      where("parentUID", "==", auth.currentUser.uid),
      where("userDocID", "==", activeUser),
      where("status", "==", "unpaid")
    );
    try {
      const data = await getDocs(userLoanQuery);
      console.log("Total number of found documents:", data.size);
      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      }));
      setLoanList(filteredData);
      console.log("LoansPulled", filteredData);
    } catch (error) {
      console.error("Error retrieving loans:", error);
    }
  }
  //
  //
  // Jobs Management
  // Pull the user's job list and build the jobs array
  async function refreshJobs() {
    const jobListData = await getJobsList(activeUser);
    const numJobs = Array.isArray(jobListData) ? jobListData.length : 0;
    setJobsCreated(numJobs);
    guideSend({
      type: "UPDATE_JOBSCREATED",
      value: numJobs // Correcting property access
    });
    guideSend("RESET_GUIDE_MACHINE");
    console.log("I see this many jobs: ", numJobs);
    setJobList(jobListData); // update jobs data
    console.log("Job List is: " + jobListData);

    if (jobListData) {
      console.log("Job list successfully retrieved.");

      let totalUnpaidStampsCount = 0; // Initialize a counter for all unpaid jobs across all documents

      // Calculate the total earnings based on updated jobListData
      const total = jobListData.reduce((sum, job) => {
        const unpaidStampsCount = job.actionTimeStamps.filter(
          (stamp) => !stamp.paid
        ).length;

        totalUnpaidStampsCount += unpaidStampsCount; // Accumulate the unpaid jobs count

        return sum + job.amount * unpaidStampsCount;
      }, 0);
      setNumberOfUnpaidJobs(totalUnpaidStampsCount); // Set the state only once, with the accumulated count
      setTotalEarnings(total); // update total earnings data
    } else {
      console.log("Job list retrieval failed.");
    }
  }

  //
  //
  // Total balance Management
  const calculateBalance = useCallback(() => {
    // Assuming you're summing up the 'amount' from each account in the accountList.
    return accountList.reduce((acc, curr) => acc + curr.amount, 0);
  }, [accountList]);
  useEffect(() => {
    setCurrBal(calculateBalance() * 100);
  }, [accountList, calculateBalance]);
  //
  //
  // Page UI Management ------------------------------ --------- ------- ------- --------- section
  const handleViewClick = () => {
    setMainViewVisible((prevState) => !prevState);
    setTotalEarningsShow((prevState) => !prevState);
    setSavingsVisible((prevState) => !prevState);
    setTimeout(500);
  };

  //Modal Management
  const onAddAcctClick = () => {
    console.log("I was clicked");
    setSelectedAccount({ name: "", amount: 0, image: null });
    setExistingAccount(false);
    setIsAcctModalOpen(true);
  };
  //
  const onAcctTitleClick = (account) => {
    setSelectedAccount(account);
    setSelectedAccountId(account.id);
    setExistingAccount(true);
    setIsEditModalOpen(true);
  };
  const onAcctBalanceClick = (account) => {
    setSelectedAccount(account);
    setSelectedAccountId(account.id);
    setExistingAccount(true);
    setIsBalanceModalOpen(true);
  };
  useEffect(() => {
    const today = moment(currentDate);
    const nextEndOfWeek = moment().day(endOfWeek); // Calculates the next endOfWeek
    let dueInDays = nextEndOfWeek.diff(today, "days");
    console.log("I detected change in current date and it is now: ", dueInDays);
    if (dueInDays > 0) {
      setIsPayday(false);
      guideSend({
        type: "UPDATE_PAYDAY",
        value: false
      });
      console.log("Setting Payday to False");
    } else if (dueInDays === 0) {
      // To account for +1 day after endOfWeek
      setIsPayday(true);
      guideSend({
        type: "UPDATE_PAYDAY",
        value: true
      });
      console.log("Setting Payday to True");
    }
    guideSend("RESET_GUIDE_MACHINE");
  }, [currentDate]);

  // Initiate Finite State Machine for application core,
  // and pass the context for startup
  const [startupCurrent, startupSend] = useMachine(startupMachine, {
    context: {
      refreshIdentity: refreshIdentity,
      getAccountList: getAccountList,
      getExpenseList: getExpenseList,
      refreshJobs: refreshJobs,
      getLoans: getLoans,
      seenSignupFTUE: seenSignupFTUE,
      numOfFamilyUsers: numOfFamilyUsers
    }
  });

  // Initiate Finite State Machine for app guidance,
  // and pass context to identify user level and next action
  const [guideCurrent, guideSend] = useMachine(guidanceMachine, {
    context: {
      activeUser: activeUser,
      seenJobFTUE: seenJobFTUE,
      seenExpFTUE: seenExpFTUE,
      userPayCycles: userPayCycles,
      refreshIdentity: refreshIdentity,
      iSawPaydayFTUE: iSawPaydayFTUE,
      jobsCreated: jobsCreated,
      numberOfUnpaidJobs: numberOfUnpaidJobs,
      setIsFirstJobWorkflowOpen: setIsFirstJobWorkflowOpen,
      setIsExpenseModalOpen: setIsExpenseModalOpen,
      setSeenExpFTUE: setSeenExpFTUE,
      setSeenJobFTUE: setSeenJobFTUE,
      expCreated: expCreated,
      seenJobsAndExpLevel2: seenJobsAndExpLevel2,
      setSeenJobsAndExpLevel2: setSeenJobsAndExpLevel2,
      isPayday: isPayday
    }
  });
  console.log("Startup value is: ", startupCurrent);
  console.log("Guide value is: ", guideCurrent);
  //
  //
  // Listen for context updates and send updates to the machine
  useEffect(() => {
    console.log("Use Effect calling for active user change");
    guideSend({ type: "UPDATE_USER", user: activeUser });
    refreshIdentity();

    if (startupCurrent.matches("defaultApplication")) {
      refreshJobs();
      getLoans();
      getExpenseList();
      getAccountList();
    }
  }, [activeUser, startupCurrent]);
  //
  // Check for changes on initial FTUE moments
  useEffect(() => {
    console.log("seenJobFTUE changed. Now equals: ", seenJobFTUE);
    guideSend({ type: "UPDATE_SEEN_JOB_FTUE", value: seenJobFTUE });
    guideSend("RESET_GUIDE_MACHINE");
  }, [seenJobFTUE]);
  //
  useEffect(() => {
    console.log("seenExpFTUE changed. Now equals: ", seenExpFTUE);
    guideSend({ type: "UPDATE_SEEN_EXP_FTUE", value: seenExpFTUE });
    guideSend("RESET_GUIDE_MACHINE");
  }, [seenExpFTUE]);
  //
  useEffect(() => {
    console.log("seenPaydayFTUE changed. Now equals: ", iSawPaydayFTUE);
    guideSend({ type: "UPDATE_SAWPAYDAYFTUE", value: iSawPaydayFTUE });
    guideSend("RESET_GUIDE_MACHINE");
  }, [iSawPaydayFTUE]);
  //
  // Check for payments on leveling attributes
  // Pay CYCLES
  useEffect(() => {
    console.log("paycycles changed. Now equals: ", userPayCycles);
    guideSend({ type: "UPDATE_PAYCYCLES", value: userPayCycles });
    guideSend("RESET_GUIDE_MACHINE");
  }, [userPayCycles]);
  //
  // Seen JobsAndExpensesLevel2 Guide
  useEffect(() => {
    console.log(
      "Jobs And Expenses Level 2 Guide. Now equals: ",
      seenJobsAndExpLevel2
    );
    guideSend({
      type: "UPDATE_JOBSANDEXPENSESLEVEL2",
      value: seenJobsAndExpLevel2
    });
    guideSend("RESET_GUIDE_MACHINE");
  }, [seenJobsAndExpLevel2]);
  //
  //
  // Unpaid Jobs
  useEffect(() => {
    console.log("unpaidJobs changed. Now equals: ", numberOfUnpaidJobs);
    guideSend({ type: "UPDATE_UNPAIDJOBS", value: numberOfUnpaidJobs });
    //guideSend("RESET_GUIDE_MACHINE");
  }, [numberOfUnpaidJobs]);
  //

  //
  //
  //
  //
  // Render the machine contents accordingly.
  let guideContent;
  // Introduction FLAGS and FTUEs
  if (guideCurrent.matches("showJobFTUEFlag")) {
    guideContent = <Level1MovementAddJobFlag guideSend={guideSend} />;
  } else if (guideCurrent.matches("showJobFTUE")) {
    guideContent = <Level1MovementJobFTUE guideSend={guideSend} />;
  } else if (guideCurrent.matches("showFirstJobFlag")) {
    guideContent = <CreateDailyJobFlag guideSend={guideSend} />;
  } else if (guideCurrent.matches("showExpFTUEFlag")) {
    guideContent = <Level1MovementAddExpFlag guideSend={guideSend} />;
  } else if (guideCurrent.matches("showExpFTUE")) {
    guideContent = <Level1MovementExpenseFTUE guideSend={guideSend} />;
  } else if (guideCurrent.matches("showFirstExpFlag")) {
    guideContent = <CreateWeeklyExpenseFlag guideSend={guideSend} />;
  } else if (guideCurrent.matches("showPaydayFTUEflag")) {
    guideContent = <PaydayFTUEflag guideSend={guideSend} />;
  } else if (guideCurrent.matches("showGetPaidFTUE")) {
    guideContent = <PayDayFTUE guideSend={guideSend} />;
  }
  // Level Up behavior based FLAGS AND FTUEs
  else if (guideCurrent.matches("showJobAndExpLevel2")) {
    guideContent = <JobsAndExpenseLevel2Flag guideSend={guideSend} />;
  } else if (guideCurrent.matches("showJobAndExpLevel2Guide")) {
    guideContent = (
      <JobsAndExpenseLevel2Guide
        guideSend={guideSend}
        activeUser={activeUser}
        refreshJobs={refreshJobs}
        getExpenseList={getExpenseList}
      />
    );
  }
  // Final state of guide machine
  else if (guideCurrent.matches("showTimeToPayDayFlag")) {
    guideContent = (
      <TimeToPaydayFlag
        totalEarnings={totalEarnings}
        currentDate={currentDate}
        endOfWeek={endOfWeek}
        guideSend={guideSend}
      />
    );
  } else if (guideCurrent.matches("showGetPaidFlag")) {
    guideContent = (
      <TotalEarningsFlag
        totalEarnings={totalEarnings}
        currentDate={currentDate}
        endOfWeek={endOfWeek}
        guideSend={guideSend}
      />
    );
  } else if (guideCurrent.matches("showPayDayWorkflow")) {
    guideContent = (
      <PayDayWorkflow
        totalEarnings={totalEarnings}
        jobList={jobList}
        refreshIdentity={refreshIdentity}
        refreshJobs={refreshJobs}
        expenseList={expenseList}
        getExpenseList={getExpenseList}
        getLoans={getLoans}
        totalExpenses={totalExpenses}
        userPayCycles={userPayCycles}
        canBorrow={canBorrow}
        setCanBorrow={setCanBorrow}
        canCarry={canCarry}
        setCanCarry={setCanCarry}
        streakLvl={streakLvl}
        streakLvl1Status={streakLvl1Status}
        setStreakLvl1Status={setStreakLvl1Status}
        payBalanceLvl={payBalanceLvl}
        testBalance={testBalance}
        needsBreak={needsBreak}
        setNeedsBreak={setNeedsBreak}
        activeUser={activeUser}
        guideSend={guideSend}
      />
    );
  }

  let appContent;
  if (startupCurrent.matches("signIn")) {
    appContent = (
      <SignIn
        startupSend={startupSend}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        setAuthenticated={setAuthenticated}
        setActiveUser={setActiveUser}
        setUserPayCycles={setUserPayCycles}
        setFamilyDocId={setFamilyDocId}
        setUsername={setUsername}
        setSelectedAvatar={setSelectedAvatar}
      />
    );
  } else if (startupCurrent.matches("signupFTUE")) {
    appContent = (
      <IntroFTUE
        selectedAvatar={selectedAvatar}
        setSelectedAvatar={setSelectedAvatar}
        curBalance={curBalance}
        activeUser={activeUser}
        startupSend={startupSend}
        setUsername={setUsername}
      />
    );
  } else if (startupCurrent.matches("userSelector")) {
    appContent = (
      <UserPicker
        startupSend={startupSend}
        setUsername={setUsername}
        setSelectedAvatar={setSelectedAvatar}
        activeUser={activeUser}
        setActiveUser={setActiveUser}
        setAuthenticated={setAuthenticated}
        setUserPickerOpen={setUserPickerOpen}
      />
    );
  } else if (startupCurrent.matches("defaultApplication")) {
    console.log("active user is: ", activeUser);

    appContent = (
      <div>
        <div className="header">
          <HeaderBar
            userName={userName}
            setMenuOpen={setMenuOpen}
            selectedAvatar={selectedAvatar}
            setIsExpenseModalOpen={setIsExpenseModalOpen}
            setIsJobWorkflowOpen={setIsJobWorkflowOpen}
            activeUser={activeUser}
            getUserContext={refreshIdentity}
            seenJobFTUE={seenJobFTUE}
            setUserPickerOpen={setUserPickerOpen}
            savingsVisible={savingsVisible}
            setSavingsVisible={setSavingsVisible}
            onViewClick={handleViewClick}
          />
        </div>
        <div className="horizontalContainerFull">
          <div
            className={`leftMainView ${isMainViewVisible ? "show" : "hide"} `}
          >
            {jobsCreated > 0 ? (
              <JobContainer
                jobList={jobList}
                setJobList={setJobList}
                refreshJobs={refreshJobs}
                activeUser={activeUser}
                currentDate={currentDate}
                setIsJobEditModalOpen={setIsJobEditModalOpen}
                setSelectedCell={setSelectedCell}
                setIsAlreadyCompletedOpen={setIsAlreadyCompletedOpen}
              />
            ) : (
              <JobContainer0State />
            )}
          </div>
          <StatusContainer
            currentBal={curBalance / 100}
            images={images}
            onViewClick={handleViewClick}
            getAccountList={getAccountList}
            refreshJobs={refreshJobs}
            getExpenseList={getExpenseList}
            savingsVisible={savingsVisible}
            setSavingsVisible={setSavingsVisible}
          />
          <div
            className={`${isMainViewVisible ? "show" : "hide"} rightMainView`}
          >
            {expCreated > 0 ? (
              <ExpenseContainer
                expenseList={expenseList}
                getExpenseList={getExpenseList}
                activeUser={activeUser}
                currentDate={currentDate}
                endOfWeek={endOfWeek}
                loanList={loanList}
                setSelectedCell={setSelectedCell}
                setIsExpenseEditModalOpen={setIsExpenseEditModalOpen}
                setIsPayLoanWorkflowOpen={setIsPayLoanWorkflowOpen}
                setSelectedLoan={setSelectedLoan}
              />
            ) : (
              <Expenses0State />
            )}
          </div>
          <CSSTransition
            in={!isMainViewVisible}
            timeout={500}
            classNames="accounts"
            unmountOnExit
          >
            <div className="accountsPageElement">
              <Accounts
                accountList={accountList}
                images={images}
                onAcctTitleClick={onAcctTitleClick}
                onAcctBalanceClick={onAcctBalanceClick}
                onAddAcctClick={onAddAcctClick}
              />
            </div>
          </CSSTransition>
        </div>
        {menuOpen && (
          <Menu
            setMenuOpen={setMenuOpen}
            isActive={menuOpen}
            userName={userName}
            logOut={logOut}
            images={images}
            selectedAvatar={selectedAvatar}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            endOfWeek={endOfWeek}
            setEndOfWeek={setEndOfWeek}
            userPayCycles={userPayCycles}
            setUserPayCycles={setUserPayCycles}
            canBorrow={canBorrow}
            setCanBorrow={setCanBorrow}
            canCarry={canCarry}
            setCanCarry={setCanCarry}
            streakLvl={streakLvl}
            setStreakLvl={setStreakLvl}
            streakLvl1Status={streakLvl1Status}
            setStreakLvl1Status={setStreakLvl1Status}
            payBalanceLvl={payBalanceLvl}
            setPayBalanceLvl={setPayBalanceLvl}
            testBalance={testBalance}
            setTestBalance={setTestBalance}
            needsBreak={needsBreak}
            setNeedsBreak={setNeedsBreak}
            getExpenseList={getExpenseList}
            guideSend={guideSend}
            setSeenExpFTUE={setSeenExpFTUE}
            setSeenJobFTUE={setSeenJobFTUE}
            setISawPaydayFTUE={setISawPaydayFTUE}
            seenExpFTUE={seenExpFTUE}
            seenJobFTUE={seenJobFTUE}
            iSawPaydayFTUE={iSawPaydayFTUE}
            setRemoveUserModal={setRemoveUserModal}
            seenJobsAndExpLevel2={seenJobsAndExpLevel2}
            setSeenJobsAndExpLevel2={setSeenJobsAndExpLevel2}
            isPayday={isPayday}
            setIsPayday={setIsPayday}
          />
        )}
        {userPickerOpen && (
          <UserPicker
            startupSend={startupSend}
            setUsername={setUsername}
            setSelectedAvatar={setSelectedAvatar}
            activeUser={activeUser}
            setActiveUser={setActiveUser}
            userPickerOpen={userPickerOpen}
            setUserPickerOpen={setUserPickerOpen}
            guideSend={guideSend}
          />
        )}
        {removeUserModal && (
          <RemoveUserModal
            setRemoveUserModal={setRemoveUserModal}
            removeUser={removeUser}
          />
        )}
        {isFirstJobWorkflowOpen && (
          <JobCreateFirstJobFLow
            onClose={() => setIsFirstJobWorkflowOpen(false)}
            refreshJobs={refreshJobs}
            activeUser={activeUser}
            guideSend={guideSend}
          />
        )}
        {isJobWorkflowOpen && (
          <JobCreateFlowDefault
            onClose={() => setIsJobWorkflowOpen(false)}
            refreshJobs={refreshJobs}
            activeUser={activeUser}
          />
        )}
        {isExpenseModalOpen && (
          <ExpenseCreateFlow
            onClose={() => setIsExpenseModalOpen(false)}
            getExpenseList={getExpenseList}
            expenseEntryPosition={expenseEntryPosition}
            activeUser={activeUser}
            guideSend={guideSend}
          />
        )}
        {isAcctModalOpen && (
          <CreateAcctModal
            existingAccount={existingAccount}
            selectedAccount={selectedAccount}
            images={images}
            onClose={() => setIsAcctModalOpen(false)}
            getAccountList={getAccountList}
            activeUser={activeUser}
          />
        )}
        {isEditModalOpen && (
          <AccountEditModal
            existingAccount={existingAccount}
            selectedAccount={selectedAccount}
            selectedAccountId={selectedAccountId}
            onClose={() => setIsEditModalOpen(false)}
            images={images}
            getAccountList={getAccountList}
            activeUser={activeUser}
          />
        )}
        {isBalanceModalOpen && (
          <BalanceModal
            existingAccount={existingAccount}
            selectedAccount={selectedAccount}
            selectedAccountId={selectedAccountId}
            onClose={() => setIsBalanceModalOpen(false)}
            images={images}
            getAccountList={getAccountList}
            activeUser={activeUser}
          />
        )}
        {isJobEditModalOpen && (
          <JobEditModal
            cellId={selectedCell?.id}
            onClose={() => setIsJobEditModalOpen(false)}
            title={selectedCell?.title}
            amount={selectedCell?.amount}
            frequency={selectedCell?.frequency}
            timeSpanHours={selectedCell?.timeSpanHours}
            actionTimeStamps={selectedCell?.actionTimeStamps}
            refreshJobs={refreshJobs}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            setIsJobEditModalOpen={setIsJobEditModalOpen}
            activeUser={activeUser}
          />
        )}
        {isAlreadyCompletedOpen && (
          <JobCompletedModal
            timeSpanHours={selectedCell?.timeSpanHours}
            onClose={() => setIsAlreadyCompletedOpen(false)}
          />
        )}
        {isExpenseEditModalOpen && (
          <ExpenseEditModal
            onClose={() => setIsExpenseEditModalOpen(false)}
            cellId={selectedCell?.id}
            title={selectedCell?.title}
            amount={selectedCell?.amount}
            frequency={selectedCell?.frequency}
            timeSpanHours={selectedCell?.timeSpanHours}
            actionTimeStamps={selectedCell?.actionTimeStamps}
            getExpenseList={getExpenseList}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            setIsExpenseEditModalOpen={setIsExpenseEditModalOpen}
            activeUser={activeUser}
          />
        )}
        {isPayLoanWorkflowOpen && (
          <LoanRepaymentWorkflow
            onClose={() => setIsPayLoanWorkflowOpen(false)}
            loanID={selectedLoan?.id}
            type={selectedLoan?.type}
            title={selectedLoan?.title}
            lender={selectedLoan?.lender}
            amount={selectedLoan?.amount}
            interest={selectedLoan?.interest}
            dueDate={selectedLoan?.dueDate}
            dueDays={selectedLoan?.dueDays}
            createdOn={selectedLoan?.createdOn}
            activeUser={activeUser}
            getLoans={getLoans}
            setCanCarry={setCanCarry}
          />
        )}
        {isMainViewVisible && (
          <div className="guideContentElement">{guideContent}</div>
        )}
      </div>
    );
  }
  //
  //
  // Core Application ------------------------------ --------- ------- ------- --------- section
  return <>{appContent}</>;
}
