import React, { useState, useEffect } from "react";
import { removeExpense, updateExpense } from "./ExpenseFunctions.js";

export default function EditCellModal({
  onClose,
  cellId,
  title,
  amount,
  frequency,
  timeSpanHours,
  actionTimeStamps = [],
  getExpenseList,
  selectedCell,
  setSelectedCell,
  setIsExpenseEditModalOpen,
  activeUser
}) {
  const [newTitle, setNewTitle] = useState(title);
  const [newAmount, setNewAmount] = useState(amount);
  const [newFrequency, setNewFrequency] = useState(frequency);
  const [newTimeSpanHours, setNewTimeSpanHours] = useState(timeSpanHours);

  const [rawAmount, setRawAmount] = useState(newAmount / 100);
  const [formattedAmount, setFormattedAmount] = useState(
    formatAsCurrency(newAmount / 100)
  );
  const [isFocused, setIsFocused] = useState(false);

  const handleModalClose = React.useCallback(
    (event) => {
      if (
        event.target.classList.contains("actModalOverlay") ||
        event.target.classList.contains("modal-content")
      ) {
        onClose();
      }
    },
    [onClose]
  );

  //
  // Looking for changes based on user activity
  useEffect(() => {
    setNewTitle(title);
    setNewAmount(amount);
  }, [title, amount]);

  useEffect(() => {
    window.addEventListener("click", handleModalClose);
    return () => {
      window.removeEventListener("click", handleModalClose);
    };
  }, [handleModalClose]);

  function formatAsCurrency(value) {
    return parseFloat(value).toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
  }

  const handleTitleChange = (event) => {
    setNewTitle(event.target.value);
  };

  const handleAmountChange = (event) => {
    let value = event.target.value;
    // Remove any non-numeric characters except for a decimal point
    value = value.replace(/[^0-9.]/g, "");
    if (value === "" || /^\d*\.?\d{0,2}$/.test(value)) {
      // Allow empty string or decimal number with up to 2 decimal places
      setRawAmount(value);
    }
  };
  const handleAmountBlur = () => {
    const updatedAmount = Math.round(parseFloat(rawAmount) * 100);
    setNewAmount(updatedAmount);
    setFormattedAmount(formatAsCurrency(updatedAmount / 100));
  };

  async function handleEditSubmit() {
    const wasSuccessful = await updateExpense(
      selectedCell.id,
      newTitle,
      newAmount,
      newFrequency,
      newTimeSpanHours,
      activeUser
    );
    if (wasSuccessful) {
      console.log("Job was successfully removed.");
      // Optionally, you can update the jobList state here to remove the job from the UI
      getExpenseList();
      setSelectedCell(null);
      setIsExpenseEditModalOpen(false);
    } else {
      console.log("Job removal failed.");
    }
  }

  async function handleRemoveExpense() {
    const wasSuccessful = await removeExpense(selectedCell.id, activeUser);
    if (wasSuccessful) {
      console.log("Action Timestamp was logged.");
      getExpenseList();
      setSelectedCell(null);
      setIsExpenseEditModalOpen(false);
    } else {
      console.log("Action timestamp failed to write");
    }
  }

  return (
    <div className="actModalOverlay">
      <div className="actModalContent">
        <div className="modalTitleControl">
          <h1>Edit Expense</h1>
          <button className="buttonStyle3" onClick={onClose}>
            X
          </button>
        </div>
        <div className="actModalColumns">
          <div className="jobColumn-1">
            <label>Expense Name:</label>
            <input
              type="text"
              className="inputFull"
              value={newTitle}
              onChange={handleTitleChange}
            />
            <label>Amount to be paid:</label>
            <input
              type="text"
              className="inputFull"
              value={isFocused ? rawAmount : formattedAmount}
              onFocus={() => {
                setIsFocused(true);
                setRawAmount(newAmount / 100);
              }}
              onChange={handleAmountChange}
              onBlur={() => {
                handleAmountBlur();
                setIsFocused(false);
              }}
            />
            <label>How often this expense should be paid:</label>
            <select className="inputFull" name="frequency" id="frequency">
              <option value="weekly">Weekly</option>
              <option value="bi-weekly">Bi-Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>
          <div className="jobColumn-2">
            <label>Paid On:</label>
            <div>
              <div>
                {" "}
                {actionTimeStamps.map((stamp, index) => {
                  return (
                    <div key={index}>
                      {new Date(stamp.timestamp).toLocaleString()} -{" "}
                      {stamp.amount}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="modalFooterControls2">
          <button className="button" onClick={handleRemoveExpense}>
            Remove
          </button>
          <button className="button" onClick={handleEditSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
