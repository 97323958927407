import React, { useEffect, useRef, useState } from "react";
import JobCell from "./JobCell.js";
import { tookAction } from "./JobsFunctions.js";
import Arrow from "../Arrow.js";

export default function JobContainer({
  jobList,
  refreshJobs,
  activeUser,
  currentDate,
  setIsJobEditModalOpen,
  setSelectedCell,
  setIsAlreadyCompletedOpen
}) {
  const flexContainerRef = useRef(null);
  const jobsListRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (
      jobsListRef.current.scrollHeight > flexContainerRef.current.clientHeight
    ) {
      setIsOverflowing(true);
    } else {
      setIsOverflowing(false);
    }
  }, [jobList]); // This effect runs whenever jobList changes
  //

  //
  //
  //Individual Cell Functions
  async function handleTookAction(id) {
    const wasSuccessful = await tookAction(id, activeUser);
    if (wasSuccessful) {
      console.log("Action Timestamp was logged.");
      refreshJobs();
    } else {
      console.log("Action timestamp failed to write");
    }
  }

  function handleCellClick(id) {
    console.log("A job was clicked. about to open");
    setSelectedCell(jobList.find((cell) => cell.id === id));
    setIsJobEditModalOpen(true);
  }

  function handleCompleteReClick(id) {
    setSelectedCell(jobList.find((cell) => cell.id === id));
    setIsAlreadyCompletedOpen(true);
  }

  //
  // calculate if the job button should be active or not based on time stamps:
  const isActionOnDifferentDay = (timestamp) => {
    const today = new Date(currentDate);
    today.setHours(0, 0, 0, 0);
    const actionDate = new Date(timestamp); // Convert timestamp to a date object
    actionDate.setHours(0, 0, 0, 0); // Reset its time portion to the start of the day
    return today.getTime() !== actionDate.getTime();
  };

  const getStartOfWeek = (date) => {
    let d = new Date(date);
    let day = d.getDay();
    let diff = d.getDate() - day; // adjust to Sunday
    d = new Date(d.setDate(diff));
    d.setHours(0, 0, 0, 0); // Reset time to the start of the day
    return d;
  };

  const getStartOfBiWeek = (date) => {
    const fixedStartDate = new Date("2020-01-01"); // Define a fixed start date.
    const msInADay = 24 * 60 * 60 * 1000; // Milliseconds in a day.

    //const daysSinceStart = (testDate - fixedStartDate) / msInADay;
    const daysSinceStart = (date - fixedStartDate) / msInADay;
    const biWeeksSinceStart = Math.floor(daysSinceStart / 14);

    // Calculate the start of the current bi-weekly cycle.
    const startOfCurrentBiWeek = new Date(
      fixedStartDate.getTime() + biWeeksSinceStart * 14 * msInADay
    );
    return startOfCurrentBiWeek;
  };

  const getStartOfMonth = (date) => {
    //return new Date(testDate.getFullYear(), testDate.getMonth(), 1);
    return new Date(date.getFullYear(), date.getMonth(), 1);
  };
  //
  return (
    <div
      className={`flexContainer ${isOverflowing ? "overflowing" : ""}`}
      ref={flexContainerRef}
    >
      <div className="jobsListWrapper">
        <div className="sideContainerTitle">
          <h1>Money In:</h1>
        </div>
        <div className="jobsList" ref={jobsListRef}>
          {jobList.map((cell) => {
            const today = new Date(currentDate);

            let actionDisabled = false;
            // For daily activities
            if (cell.timeSpanHours === 24) {
              // count the number of time stamps that appear today
              const todayActions = cell.actionTimeStamps.filter(
                (action) => !isActionOnDifferentDay(action.doneTimestamp)
              ).length;
              if (todayActions < cell.frequency) {
                actionDisabled = false;
              } else {
                actionDisabled = true;
              }
              // for weekly activities
            } else if (cell.timeSpanHours === 168) {
              // Check for weekly reset and frequency
              const startOfWeek = getStartOfWeek(today);
              const actionsThisWeek = cell.actionTimeStamps.filter((action) => {
                let tsDate = new Date(action.doneTimestamp);
                let localOffset = tsDate.getTimezoneOffset() * 60000; // in milliseconds
                tsDate = new Date(tsDate.getTime() - localOffset); // Adjusting to local time
                tsDate.setHours(0, 0, 0, 0); // Resetting time part for comparison
                return tsDate >= startOfWeek;
              }).length;
              actionDisabled = actionsThisWeek >= cell.frequency;

              // for bi-weekly activities
            } else if (cell.timeSpanHours === 336) {
              const startOfBiWeek = getStartOfBiWeek(today);
              const actionsThisBiWeek = cell.actionTimeStamps.filter(
                (action) => new Date(action.doneTimestamp) >= startOfBiWeek
              ).length;
              actionDisabled = actionsThisBiWeek >= cell.frequency;
              // TO DO for monthly activities
            } else if (cell.timeSpanHours === 720) {
              // Rough average of a month in hours
              const startOfMonth = getStartOfMonth(today);
              const actionsThisMonth = cell.actionTimeStamps.filter(
                (action) => new Date(action.doneTimestamp) >= startOfMonth
              ).length;
              actionDisabled = actionsThisMonth >= cell.frequency;
            }
            //console.log("Rendering cell:", cell.title, "action timestamps:", cell.actionTimeStamps, "Disabled:", actionDisabled);

            return (
              <JobCell
                key={cell.id}
                title={cell.title}
                amount={cell.amount}
                timeSpanHours={cell.timeSpanHours}
                onClick={() => handleCellClick(cell.id)}
                onAction={() => handleTookAction(cell.id)}
                disableAction={actionDisabled}
                handleCompleteReClick={() => handleCompleteReClick(cell.id)}
              />
            );
          })}
        </div>
      </div>
      <Arrow />
    </div>
  );
}
