// BorrowFunctions.js
import { db, auth } from "../Firebase";
import moment from "moment";
import {
  addDoc,
  collection,
  deleteDoc,
  getDocs,
  query,
  updateDoc,
  where,
  doc
} from "firebase/firestore";

// Collection reference for all functions
const loanCollectionRef = collection(db, "loans");

export const createCarryLoan = async (newAmount, newInterest, activeUser) => {
  try {
    console.log("Trying to create loan: ", newAmount, newInterest, activeUser);
    await addDoc(loanCollectionRef, {
      type: "carry",
      title: "Carried Balance",
      lender: "parent",
      amount: newAmount,
      interest: newInterest,
      dueDate: null,
      dueDays: 0,
      userDocID: activeUser,
      parentUID: auth?.currentUser?.uid,
      createdOn: new Date().toISOString(),
      status: "unpaid"
    });

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const createBorrowLoan = async (
  newLoanLender,
  newLoanAmount,
  newLoanInterest,
  newLoanDueDate,
  newLoanDueDays,
  activeUser
) => {
  try {
    await addDoc(loanCollectionRef, {
      type: "balanceBorrow",
      title: "Short Term Loan",
      lender: newLoanLender,
      amount: newLoanAmount,
      interest: newLoanInterest,
      dueDate: newLoanDueDate,
      dueDays: newLoanDueDays,
      userDocID: activeUser,
      parentUID: auth?.currentUser?.uid,
      createdOn: new Date().toISOString(),
      status: "unpaid"
    });
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const payLoan = async (loanID, activeUser) => {
  if (!auth.currentUser) {
    console.error("No User Logged in!");
    return false;
  }
  const userLoanQuery = query(
    loanCollectionRef,
    where("parentUID", "==", auth.currentUser.uid),
    where("userDocID", "==", activeUser)
  );
  try {
    const snapshot = await getDocs(userLoanQuery);
    let docRef;
    snapshot.forEach((doc) => {
      if (doc.id === loanID) {
        docRef = doc.ref;
      }
    });
    if (docRef) {
      await updateDoc(docRef, {
        status: "paid"
      });
      console.log("Update completed on: ", docRef);
      return true;
    } else {
      console.log("loan with given ID was not found");
      return false;
    }
  } catch (err) {
    console.error(err);
  }
};
