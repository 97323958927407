//Guidance Machine.js
import { Machine, assign, log } from "xstate";
import { updateUserActivity } from "./IdentityFunctions.js";

export const guidanceMachine = Machine(
  {
    // Machine identifier
    id: "guidance",
    // Initial state
    initial: "holdStart",
    context: {
      seenJobFTUE: false,
      seenExpFTUE: false,
      iSawPaydayFTUE: false,
      activeUser: null,
      lastUpdatedField: null,
      userPayCycles: 0,
      jobsCreated: 0,
      expCreated: 0,
      numberOfUnpaidJobs: 0,
      seenJobsAndExpLevel2: false
      // other context properties...
    },
    // Update user context if the activeUser changes at any point
    on: {
      UPDATE_USER: {
        actions: assign({
          activeUser: (context, event) => event.user
        })
      },
      UPDATE_SEEN_JOB_FTUE: {
        actions: assign({
          seenJobFTUE: (context, event) => event.value
        })
      },
      UPDATE_SEEN_EXP_FTUE: {
        actions: assign({
          seenExpFTUE: (context, event) => event.value
        })
      },
      UPDATE_SAWPAYDAYFTUE: {
        actions: assign({
          iSawPaydayFTUE: (context, event) => event.value
        })
      },
      UPDATE_PAYCYCLES: {
        actions: assign({
          userPayCycles: (context, event) => event.value
        })
      },
      UPDATE_UNPAIDJOBS: {
        actions: assign({
          numberOfUnpaidJobs: (context, event) => event.value
        })
      },
      UPDATE_JOBSANDEXPENSESLEVEL2: {
        actions: assign({
          seenJobsAndExpLevel2: (context, event) => event.value
        })
      },
      UPDATE_JOBSCREATED: {
        actions: assign({
          jobsCreated: (context, event) => event.value
        })
      },
      UPDATE_EXPCREATED: {
        actions: assign({
          expCreated: (context, event) => event.value
        })
      },
      UPDATE_PAYDAY: {
        actions: assign({
          isPayday: (context, event) => event.value
        })
      },

      RESET_GUIDE_MACHINE: "determineJobFTUE"
    },
    // States
    states: {
      // First state, determine if user has seen job creation FTUE,
      // display job FTUE flag accordingly. If SEEN, go to determine
      // Expense FTUE.
      holdStart: {
        on: { GO: "determineJobFTUE" }
      },
      determineJobFTUE: {
        always: [
          {
            target: "showJobFTUEFlag",
            cond: (context, event) => context.seenJobFTUE === false
          },
          {
            target: "determineFirstJobCreated",
            cond: (context, event) => context.seenJobFTUE === true
          }
        ]
      },
      // When JOB FTUE FLAG RENDERED.
      showJobFTUEFlag: {
        on: { GO: "showJobFTUE" }
      },
      // Render the JOB FTUE
      showJobFTUE: {
        on: {
          CLOSE: "determineJobFTUE",
          DONE: {
            target: "updateServer",
            actions: [
              "setSeenJob",
              "openFirstJobWorkflowAction",
              assign({
                lastUpdatedField: (context, event) => "seenJobFTUE",
                userFieldToUpdate: (context, event) => "seenJobFTUE",
                userDataToUpdate: (context, event) => true
              })
            ]
          }
        }
      },
      // Determine if there is at least one job. If not, prompt user to create one
      determineFirstJobCreated: {
        always: [
          {
            target: "showFirstJobFlag",
            cond: (context, event) => context.jobsCreated === 0
          },
          {
            target: "determineExpFTUE",
            cond: (context, event) => context.jobsCreated > 0
          }
        ]
      },
      showFirstJobFlag: {
        on: {
          GO: {
            target: "showFirstJobWorkflow",
            actions: "openFirstJobWorkflowAction"
          }
        }
      },
      showFirstJobWorkflow: {
        on: {
          CLOSE: {
            target: "determineFirstJobCreated",
            actions: "closeFirstJobWorkflowAction"
          },
          DONE: "determineExpFTUE"
        }
      },
      // Determine if the user has seen the expense creation FTUE,
      // display expense FTUE flag accordingly. If SEEN, go to
      // CheckLevelUp.
      determineExpFTUE: {
        always: [
          {
            target: "showExpFTUEFlag",
            cond: (context, event) => context.seenExpFTUE === false
          },
          {
            target: "determineFirstExpCreated",
            cond: (context, event) => context.seenExpFTUE === true
          }
        ]
      },
      showExpFTUEFlag: {
        on: { GO: "showExpFTUE" }
      },
      showExpFTUE: {
        on: {
          CLOSE: "determineExpFTUE",
          DONE: {
            target: "updateServer",
            actions: [
              "setSeenExp",
              "openExpenseModal",
              assign({
                lastUpdatedField: (context, event) => "seenExpFTUE",
                userFieldToUpdate: (context, event) => "seenExpFTUE",
                userDataToUpdate: (context, event) => true
              })
            ]
          }
        }
      },
      determineFirstExpCreated: {
        always: [
          {
            target: "showFirstExpFlag",
            cond: (context, event) => context.expCreated === 0
          },
          {
            target: "checkLevelUp",
            cond: (context, event) => context.expCreated > 0
          }
        ]
      },
      showFirstExpFlag: {
        on: {
          GO: {
            target: "showFirstExpWorkflow",
            actions: "openFirstExpWorkflowAction"
          }
        }
      },
      showFirstExpWorkflow: {
        on: {
          CLOSE: {
            target: "determineFirstExpCreated",
            actions: "closeFirstExpWorkflowAction"
          },
          DONE: "checkLevelUp"
        }
      },
      //
      //
      //
      //
      //
      // Check for level up
      checkLevelUp: {
        always: [
          {
            target: "awaitingJobComplete",
            cond: (context, event) => context.userPayCycles === 0
          },
          {
            target: "showJobAndExpLevel2",
            cond: (context, event) =>
              context.userPayCycles === 1 &&
              context.seenJobsAndExpLevel2 === false
          },
          {
            target: "awaitingJobComplete",
            cond: (context, event) =>
              context.userPayCycles === 1 &&
              context.seenJobsAndExpLevel2 === true
          }
        ]
      },
      //
      //
      //
      // Jobs and Expenses Level 2
      showJobAndExpLevel2: {
        on: { GO: "showJobAndExpLevel2Guide" }
      },
      showJobAndExpLevel2Guide: {
        on: {
          CLOSE: "checkLevelUp",
          DONE: {
            target: "updateServer",
            actions: [
              "finishedLevel2",
              assign({
                lastUpdatedField: (context, event) => "seenJobsAndExpLevel2",
                userFieldToUpdate: (context, event) => "seenJobsAndExpLevel2",
                userDataToUpdate: (context, event) => true
              })
            ]
          }
        }
      },

      //
      //
      //
      //
      // DEFAULT GUIDE STATE HERE
      // Once first payday completed, check condiations for potential level up
      // and terminate at awating job complete.
      awaitingJobComplete: {
        always: [
          {
            target: "checkGetPaidFTUE",
            cond: (context, event) => context.numberOfUnpaidJobs > 0
          }
        ]
      },
      // Check for unpaid jobs. If true, go to CheckGetPaid FTUE
      checkGetPaidFTUE: {
        always: [
          {
            target: "DetectPayDayFlagToShow",
            cond: (context, event) => context.iSawPaydayFTUE === true
          },
          {
            target: "showPaydayFTUEflag",
            cond: (context, event) => context.iSawPaydayFTUE === false
          }
        ]
      },

      showPaydayFTUEflag: {
        on: {
          GO: "showGetPaidFTUE"
        }
      },

      // Check for getPaidFTUE SEEN, if False, show get paid FTUE. if true, show payday Flag
      showGetPaidFTUE: {
        on: {
          CLOSE: "checkGetPaidFTUE",
          DONE: {
            target: "updateServer",
            actions: assign({
              lastUpdatedField: (context, event) => "iSawPaydayFTUE",
              userFieldToUpdate: (context, event) => "iSawPaydayFTUE",
              userDataToUpdate: (context, event) => true
            })
          }
        }
      },
      //
      //
      DetectPayDayFlagToShow: {
        always: [
          {
            target: "showTimeToPayDayFlag",
            cond: (context, event) => context.isPayday === false
          },
          {
            target: "showGetPaidFlag",
            cond: (context, event) => context.isPayday === true
          }
        ]
      },
      showTimeToPayDayFlag: {
        on: {
          CHECKPAYDAY: "checkPayDayModal"
        }
      },
      checkPayDayModal: {
        on: {
          CLOSE: "determineJobFTUE"
        }
      },
      showGetPaidFlag: {
        on: {
          GO: "showPayDayWorkflow"
        }
      },
      showPayDayWorkflow: {
        on: {
          DISMISS: "showGetPaidFlag",
          COMPLETE: "determineJobFTUE"
        }
      },

      // Generic function to update the server with attribute on what has been,
      // seen and hat hasn't been seen. This will be called by all functions.
      updateServer: {
        invoke: {
          id: "updateServer",
          src: (context, event) =>
            updateUserActivity(
              context.activeUser,
              context.userFieldToUpdate,
              context.userDataToUpdate
            ),
          onDone: {
            target: "determineJobFTUE",
            actions: [
              assign({
                seenJobFTUE: (context, event) =>
                  context.lastUpdatedField === "seenJobFTUE"
                    ? context.userDataToUpdate
                    : context.seenJobFTUE,
                seenExpFTUE: (context, event) =>
                  context.lastUpdatedField === "seenExpFTUE"
                    ? context.userDataToUpdate
                    : context.seenExpFTUE,
                iSawPaydayFTUE: (context, event) =>
                  context.lastUpdatedField === "iSawPaydayFTUE"
                    ? context.userDataToUpdate
                    : context.iSawPaydayFTUE,
                seenJobsAndExpLevel2: (context, event) =>
                  context.lastUpdatedField === "seenJobsAndExpLevel2"
                    ? context.userDataToUpdate
                    : context.seenJobsAndExpLevel2,
                lastUpdatedField: (context, event) => null,
                userFieldToUpdate: (context, event) => null,
                userDataToUpdate: (context, event) => null
              })
            ]
          },
          onError: {
            target: "holdStart",
            actions: (_, event) =>
              console.error("Failed to update server", event.data)
          }
        }
      }
      //
      //
      //
      // TERMINATOR FOR STATES HERE
      // TERMINATOR FOR STATES HERE
      // TERMINATOR FOR STATES HERE
      // TERMINATOR FOR STATES HERE
    }
  },
  {
    // Options: actions, guards, services, etc.
    actions: {
      // MIGHT NEED TO DUPLICATE SEEN JOB FTUE HERE
      logUserDoc: log((context, event) => {
        return `User Doc: ${JSON.stringify(
          context.activeUser
        )}, Event: ${JSON.stringify(event)}`;
      }),
      openFirstJobWorkflowAction: (context, event) => {
        context.setIsFirstJobWorkflowOpen(true);
      },
      closeFirstJobWorkflowAction: (context, event) => {
        context.setIsFirstJobWorkflowOpen(false);
      },
      openFirstExpWorkflowAction: (context, event) => {
        context.setIsExpenseModalOpen(true);
      },
      closeFirstExpWorkflowAction: (context, event) => {
        context.setIsExpenseModalOpen(false);
      },
      openExpenseModal: (context, event) => {
        context.setIsExpenseModalOpen(true);
      },
      finishedLevel2: (context, event) => {
        context.setSeenJobsAndExpLevel2(true);
      }
      // ... (other actions)
    },
    guards: {
      // your guard implementations
    }
    // ... other options
  }
);
