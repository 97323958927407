import React, { useEffect, useState } from "react";
import { avatars } from "./Avatars.js";
import { importAllIdentities } from "./identity/IdentityFunctions.js";
import StickyFooter from "./StickyFooter.js";
import ChooseAvatar from "./ChooseAvatar.js";
import CountMoney from "./CountMoney.js";
import MoneyInPiggyBank from "./MoneyInPiggyBank.js";
import UserInfo from "./UserInfo.js";
import { createNewUser } from "./identity/IdentityFunctions.js";
import { addAccount } from "./bankAccounts/AccountFunctions.js";

export default function UserPicker({
  startupSend,
  setUsername,
  setSelectedAvatar,
  activeUser,
  setActiveUser,
  setUserPickerOpen,
  guideSend
}) {
  const [allUsers, setAllUsers] = useState([]);
  const [isIntroStatus, setIntroStatus] = useState(1);
  const [newUserName, setNewUsername] = useState(null);
  const [newSelectedAvatar, setNewSelectedAvatar] = useState(null);
  const [curBalance] = useState("0");
  const [enteredAmount, setEnteredAmount] = useState("0");
  console.log(
    "current blaance, ",
    curBalance,
    " Entered amount: ",
    enteredAmount
  );

  useEffect(() => {
    const fetchData = async () => {
      const users = await importAllIdentities();
      setAllUsers(users);
    };
    fetchData();
  }, []);

  const getUserAvatar = (avatarId) => {
    const avatar = avatars.find((image) => image.id === avatarId);
    return avatar ? avatar.src : null;
  };
  //
  //
  ///Incrementer for setup states
  const incrementStatus = () => {
    if (isIntroStatus < 10) {
      setIntroStatus((prevStatus) => prevStatus + 1);
    } else {
      //do nothing
    }
  };
  const decrementStatus = () => {
    if (isIntroStatus > 0) {
      setIntroStatus((prevStatus) => prevStatus - 1);
    } else {
      //do nothing
    }
  };

  const handleComplete = async () => {
    // Step 1: Get the newUserID
    const newUserID = await createNewUser(newUserName, newSelectedAvatar);
    setActiveUser(newUserID);
    console.log("The new user is going to be known as: ", newUserID);
    setSelectedAvatar(newSelectedAvatar);
    setUsername(newUserName);
    // If we have a valid newUserID, then add an account.
    if (newUserID) {
      await addAccount("Piggy Bank", enteredAmount * 100, 1, newUserID);
    }
    guideSend({ type: "UPDATE_USER", user: newUserID });
    guideSend({ type: "UPDATE_SEEN_EXP_FTUE", value: false });
    guideSend({ type: "UPDATE_SEEN_JOB_FTUE", value: false });
    guideSend({ type: "UPDATE_PAYDAYFTUE", value: false });
    guideSend("RESET_GUIDE_MACHINE");
    // Close the UserPicker after creating user and account.
    setUserPickerOpen(false);
  };
  //
  //
  // When activeUser changes, check if it is truthy (i.e., set)
  // and if so, send the "COMPLETE" signal to the state machine.
  useEffect(() => {
    if (activeUser) {
      startupSend("COMPLETE");
      console.log("sent complete to startup function");
    }
  }, [activeUser, startupSend]);

  const handleSelection = (user) => {
    setActiveUser(user.id);
    console.log("Setting Active user to: ", activeUser);
    setUsername(user.name);
    setSelectedAvatar(user.selectedAvatar);
    setUserPickerOpen(false);
  };

  let pageContent;
  switch (isIntroStatus) {
    case 1:
      pageContent = (
        <div className="userPickerContainer">
          <div className="userPickerDetails">
            {!activeUser && (
              <p>
                <h1>Who is using the application?</h1>
              </p>
            )}
            {activeUser && (
              <p>
                <h1>Select a user</h1>
              </p>
            )}

            <div className="userTiles">
              {allUsers.map((user) => (
                <div
                  key={user.id}
                  className={
                    user.id === activeUser
                      ? "pickerActiveUser"
                      : "pickerInactiveUser"
                  }
                  onClick={() => handleSelection(user)}
                >
                  <img
                    src={getUserAvatar(user.selectedAvatar)}
                    className="menuAvatar"
                    alt="user avatar"
                  />
                  {user.name}
                </div>
              ))}
              <div>
                {activeUser && (
                  <button className="buttonStyle3" onClick={incrementStatus}>
                    +
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      );
      break;
    case 2:
      pageContent = (
        <div className="horizontalContainer">
          <UserInfo setNewUsername={setNewUsername} />
          <StickyFooter
            onClickIncrement={incrementStatus}
            incrementText="Next"
            onClickDecrement={decrementStatus}
            decrementText="Cancel"
            newUserName={newUserName}
          />
        </div>
      );
      break;
    case 3:
      pageContent = (
        <div>
          <ChooseAvatar
            incrementStatus={incrementStatus}
            decrementStatus={decrementStatus}
            setSelectedAvatar={setNewSelectedAvatar}
            avatars={avatars}
          />
          <StickyFooter
            onClickIncrement={incrementStatus}
            incrementText="Next"
            onClickDecrement={decrementStatus}
            decrementText="Back"
            selectedAvatar={newSelectedAvatar}
          />
        </div>
      );
      break;
    case 4:
      pageContent = (
        <div className="horizontalContainer">
          <CountMoney />
          <StickyFooter
            onClickIncrement={incrementStatus}
            incrementText="Next"
            onClickDecrement={decrementStatus}
            decrementText="Back"
            selectedAvatar={newSelectedAvatar}
          />
        </div>
      );
      break;
    case 5:
      pageContent = (
        <div className="horizontalContainer">
          <MoneyInPiggyBank
            curBalance={curBalance}
            enteredAmount={enteredAmount}
            setEnteredAmount={setEnteredAmount}
          />
          <StickyFooter
            onClickIncrement={handleComplete}
            incrementText="Done"
            onClickDecrement={decrementStatus}
            decrementText="Back"
            selectedAvatar={newSelectedAvatar}
          />
        </div>
      );
      break;
    default:
  }

  return <div className="userPickerOverlay">{pageContent}</div>;
}
