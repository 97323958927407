import emptyBank from "./img/emptyBank.png";

export default function CountMoney({}) {
  return (
    <div>
      <div className="titleDefault">
        <div className="introTitle">
          <h1>With your child, count the money in their piggy bank</h1>
        </div>
      </div>
      <div className="centeredElement">
        <img className="countImage" src={emptyBank} alt="Empty Piggy Bank" />
      </div>
    </div>
  );
}
