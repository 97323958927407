import moneyOutZero from "../img/moneyOutZeroState.png";

export default function SpendingContainer0State() {
  return (
    <div className="flexContainer">
      <div className="jobsListWrapper">
        <div className="sideContainerTitleZeroRight">
          <h1>No Money Out..</h1>
        </div>
        <div>
          <img
            className="zeroStateImage"
            src={moneyOutZero}
            alt="Money In Zero State"
          />
        </div>
      </div>
    </div>
  );
}
