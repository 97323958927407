// Level 1: Money Movement
import { useState } from "react";
import pigRun from "../img/PigRun.png";
import jobFTUE1 from "../img/JobFTUE1.png";
import jobFTUE2 from "../img/JobFTUE2.png";
import jobFTUE3 from "../img/JobFTUE3.png";
import jobFTUE4 from "../img/JobFTUE4.png";
import jobFTUE5 from "../img/JobFTUE5.png";
import jobFTUE6 from "../img/JobFTUE6.png";
import jobFTUE7 from "../img/JobFTUE7.png";
import expFTUE1 from "../img/ExpenseFTUE1.png";
import expFTUE2 from "../img/ExpenseFTUE2.png";
import expFTUE3 from "../img/ExpenseFTUE3.png";
import expFTUE4 from "../img/ExpenseFTUE4.png";
import expFTUE5 from "../img/ExpenseFTUE5.png";

export function Level1MovementAddJobFlag({ guideSend }) {
  return (
    <div className=" earningsContainer">
      <div className="levelFlagParent">
        <img
          className="levelFlagAvatar"
          src={pigRun}
          alt="Pig running on a treadmill"
        />
        <div className="flagCell">
          <div className="levelSummary">
            <h5>Getting Started:</h5>
            <h1>Earn Money By Completing Jobs</h1>
          </div>
          <div className="earningSummaryControl">
            <button className="buttonStyle2" onClick={() => guideSend("GO")}>
              Go
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Level1MovementAddExpFlag({ guideSend }) {
  return (
    <div className=" earningsContainer">
      <div className="levelFlagParent">
        <img
          className="levelFlagAvatar"
          src={pigRun}
          alt="Pig running on a treadmill"
        />
        <div className="flagCell">
          <div className="levelSummary">
            <h6>Getting Started:</h6>
            <h1>Add An Expense</h1>
          </div>
          <div className="earningSummaryControl">
            <button className="buttonStyle2" onClick={() => guideSend("GO")}>
              Go
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Level1MovementJobFTUE({ guideSend }) {
  const [flowPosition, setFlowPosition] = useState(1);
  //
  // Increment position
  const incrementStatus = () => {
    if (flowPosition < 7) {
      setFlowPosition((prevStatus) => prevStatus + 1);
    } else {
      //do nothing
    }
  };
  const decrementStatus = () => {
    if (flowPosition > 0) {
      setFlowPosition((prevStatus) => prevStatus - 1);
    } else {
      //do nothing
    }
  };

  const handleExit = () => {
    guideSend("CLOSE");
  };
  //
  //
  // Cycle FTUE Contents
  let pageContent;
  switch (flowPosition) {
    case 1:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1>Money In...</h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="ftueColumn-1">
              <img
                className="FTUEimages"
                src={jobFTUE1}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <h1>Your child will practice:</h1>
                <p>
                  <li>How time and effort creates value</li>
                  <li>Earning money by completing tasks</li>
                  <li>Delayed gratification</li>
                  <li>Negotiating the value of their work</li>
                  <li>Personal accountability</li>
                  <li>Basic addition & multiplication</li>
                  <li>Awarness of the effort required to maintain a home</li>
                </p>
                <h1>Time needed by parents:</h1>
                <li>3 - 4 min per week</li>
              </div>
              <div className="modalFooterControlSingle">
                <button className="button" onClick={incrementStatus}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case 2:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1>Money In...</h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="ftueColumn-1">
              <img
                className="FTUEimages"
                src={jobFTUE2}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <p>
                  <h1>How it works:</h1>
                </p>
                <h2>
                  To get started, we’ll focus on the jobs that your child might
                  do every day.
                </h2>
              </div>
              <div className="modalFooterControls2">
                <button className="button" onClick={decrementStatus}>
                  Back
                </button>
                <button className="button" onClick={incrementStatus}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case 3:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1>Money In...</h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="ftueColumn-1">
              <img
                className="FTUEimages"
                src={jobFTUE3}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <p>
                  <h1>How it works:</h1>
                </p>
                <h2>
                  Together, you’ll choose a job to be done and agree on the
                  value of completing that job.
                </h2>
              </div>
              <div className="modalFooterControls2">
                <button className="button" onClick={decrementStatus}>
                  Back
                </button>
                <button className="button" onClick={incrementStatus}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case 4:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1>Money In...</h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="ftueColumn-1">
              <img
                className="FTUEimages"
                src={jobFTUE4}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <p>
                  <h1>How it works:</h1>
                </p>
                <h2>
                  When the job is done, your child presses the ‘circle’ button
                  to mark it complete
                </h2>
              </div>
              <div className="modalFooterControls2">
                <button className="button" onClick={decrementStatus}>
                  Back
                </button>
                <button className="button" onClick={incrementStatus}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case 5:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1>Money In...</h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="ftueColumn-1">
              <img
                className="FTUEimages"
                src={jobFTUE5}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <p>
                  <h1>How it works:</h1>
                </p>
                <h2>
                  The amount earned for completing the job is added together
                  over the course of the week - letting your child track what
                  they’ve earned to date.
                </h2>
              </div>
              <div className="modalFooterControls2">
                <button className="button" onClick={decrementStatus}>
                  Back
                </button>
                <button className="button" onClick={incrementStatus}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case 6:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1>Money In...</h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="ftueColumn-1">
              <img
                className="FTUEimages"
                src={jobFTUE6}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <p>
                  <h1>How it works:</h1>
                </p>
                <h2>
                  Each Friday, your child can activate pay-day, where you’ll
                  review the work you have done and get paid.
                </h2>
              </div>
              <div className="modalFooterControls2">
                <button className="button" onClick={decrementStatus}>
                  Back
                </button>
                <button className="button" onClick={incrementStatus}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case 7:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1>Money In...</h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="ftueColumn-1">
              <img
                className="FTUEimages"
                src={jobFTUE7}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <p>
                  <h1>How it works:</h1>
                </p>
                <h2>
                  {" "}
                  Over time, the app will introduce new ways to practice earning
                  money, including:{" "}
                </h2>
                <p>
                  <li>selling items you make / own</li>
                  <li>renting items you make / own</li>
                  <li>investing</li>
                  <li>collection & redemption</li>
                </p>
              </div>
              <div className="modalFooterControls2">
                <button className="button" onClick={decrementStatus}>
                  Back
                </button>
                <button className="button" onClick={() => guideSend("DONE")}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    default:
      pageContent = <div></div>;
  }

  return (
    <div className="actModalOverlay">
      <div className="actModalContent">{pageContent}</div>
    </div>
  );
}
//
//
//
//
//
//
//
//
// EXPENSE FTUE
//
//
export function Level1MovementExpenseFTUE({ guideSend }) {
  const [flowPosition, setFlowPosition] = useState(1);
  //
  // Increment position
  const incrementStatus = () => {
    if (flowPosition < 7) {
      setFlowPosition((prevStatus) => prevStatus + 1);
    } else {
      //do nothing
    }
  };
  const decrementStatus = () => {
    if (flowPosition > 0) {
      setFlowPosition((prevStatus) => prevStatus - 1);
    } else {
      //do nothing
    }
  };

  const handleExit = () => {
    guideSend("CLOSE");
  };
  //
  //
  // Cycle FTUE Contents
  let pageContent;
  switch (flowPosition) {
    case 1:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1>Money Out...</h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="column-1">
              <img
                className="FTUEimages"
                src={jobFTUE1}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <h1>Your child will practice:</h1>
                <p>
                  <li>Balancing what they earn with what they spend</li>
                  <li>Forecasting future expenses</li>
                  <li>
                    Recognizing that everything around them costs money or
                    effort to have & maintain
                  </li>
                </p>
                <h1>Time needed by parents:</h1>
                <li>3 - 4 min per week</li>
              </div>
              <div className="modalFooterControlSingle">
                <button className="button" onClick={incrementStatus}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case 2:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1>Money Out...</h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="ftueColumn-1">
              <img
                className="FTUEimages"
                src={expFTUE1}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <p>
                  <h1>How it works:</h1>
                </p>
                <h2>
                  To get started, we’ll focus on the services your child uses
                  everyday, but might not notice that someone is paying for or
                  doing for them.
                </h2>
              </div>
              <div className="modalFooterControls2">
                <button className="button" onClick={decrementStatus}>
                  Back
                </button>
                <button className="button" onClick={incrementStatus}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case 3:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1>Money Out...</h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="ftueColumn-1">
              <img
                className="FTUEimages"
                src={expFTUE2}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <p>
                  <h1>How it works:</h1>
                </p>
                <h2>
                  Together, you’ll select a service that the child can
                  contribute to on a weekly basis.
                </h2>
              </div>
              <div className="modalFooterControls2">
                <button className="button" onClick={decrementStatus}>
                  Back
                </button>
                <button className="button" onClick={incrementStatus}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case 4:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1>Money In...</h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="ftueColumn-1">
              <img
                className="FTUEimages"
                src={expFTUE3}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <p>
                  <h1>How it works:</h1>
                </p>
                <h2>
                  Each week, the app will deduct these charges from the money
                  they’ve earned by doing jobs.
                </h2>
              </div>
              <div className="modalFooterControls2">
                <button className="button" onClick={decrementStatus}>
                  Back
                </button>
                <button className="button" onClick={incrementStatus}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case 5:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1>Money In...</h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="ftueColumn-1">
              <img
                className="FTUEimages"
                src={expFTUE4}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <p>
                  <h1>How it works:</h1>
                </p>
                <h2>
                  As a parent, you can choose to put money they’ve contributed
                  to pay the actual bills, or hold that money in an account and
                  give it to them at a later date.
                </h2>
              </div>
              <div className="modalFooterControls2">
                <button className="button" onClick={decrementStatus}>
                  Back
                </button>
                <button className="button" onClick={incrementStatus}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case 6:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1>Money In...</h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="ftueColumn-1">
              <img
                className="FTUEimages"
                src={expFTUE5}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <p>
                  <h1>How it works:</h1>
                </p>
                <h2>
                  Over time, the app will introduce additional and sometimes
                  unexpected expenses, introducing your child to the idea of
                  preparing for an unexpected future.
                </h2>
              </div>
              <div className="modalFooterControls2">
                <button className="button" onClick={decrementStatus}>
                  Back
                </button>
                <button className="button" onClick={() => guideSend("DONE")}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    default:
      pageContent = <div></div>;
  }

  return (
    <div className="actModalOverlay">
      <div className="actModalContent">{pageContent}</div>
    </div>
  );
}
