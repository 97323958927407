import React, { useState, useEffect } from "react";

export default function CellContainer({
  title,
  amount,
  timeSpanHours,
  onClick,
  onAction,
  disableAction,
  handleCompleteReClick
}) {
  const [showTempCheckmark, setShowTempCheckmark] = useState(false);

  let dollars = Math.floor(amount / 100);
  let cents = amount % 100;
  let timeSpanText =
    timeSpanHours === 24
      ? "day"
      : timeSpanHours === 168
      ? "week"
      : timeSpanHours === 336
      ? "bi-week"
      : timeSpanHours === 720
      ? "month"
      : "";

  // Reset the temporary checkmark after 2 seconds
  useEffect(() => {
    let timer;
    if (showTempCheckmark) {
      timer = setTimeout(() => {
        setShowTempCheckmark(false);
      }, 2000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [showTempCheckmark]);

  const handleAction = () => {
    if (disableAction) {
      handleCompleteReClick(); // set to true when disabled and clicked
      return; // prevent the rest of the function from executing
    }
    setShowTempCheckmark(true);
    onAction();
  };

  return (
    <div className="jobCard">
      <div className="acPrimary" onClick={onClick}>
        <div className="accountTitle">
          <h5>{title}</h5>
          <p>
            Earn{" "}
            <strong>
              ${dollars}.{cents.toString().padStart(2, "0")}
            </strong>{" "}
            {timeSpanText}
          </p>
        </div>
      </div>
      <div className="">
        <button
          className={disableAction ? "didItInactive" : "didItButton"}
          onClick={(e) => {
            e.stopPropagation(); // prevent cell's onClick from firing
            handleAction();
          }}
        >
          {disableAction ? (
            <span className="iconCheckmark">&#10003;</span>
          ) : showTempCheckmark ? (
            <span className="iconCheckmark">&#10003;</span>
          ) : (
            <span className="iconCircle">&#9711;</span>
          )}
        </button>
      </div>
    </div>
  );
}
