import { frequencies } from "../Frequencies.js";
import { jobs } from "./Jobs.js";
import { useState } from "react";
import { createJob } from "./JobsFunctions.js";
import jobFTUE2 from "../img/JobFTUE2.png";

export default function JobCreateFirstJobFLow({
  onClose,
  title = "",
  amount = 1,
  frequency = 99,
  timeSpanHours = 99,
  refreshJobs,
  activeUser,
  guideSend
}) {
  const [selectedJob, setSelectedJob] = useState(null);
  const [prevJobChoice, setPrevJobChoice] = useState(null);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [prevFrequency, setPrevFrequency] = useState(null);
  const [newTitle, setNewTitle] = useState(title);
  const [newAmount, setNewAmount] = useState(amount);
  const [newFrequency, setNewFrequency] = useState(frequency);
  const [newTimeSpanHours, setNewTimeSpanHours] = useState(timeSpanHours);
  const [workflowState, setNewWorkflowState] = useState(0);
  const [showInput, setShowInput] = useState(false);

  let dollars = Math.floor(newAmount / 100);
  let cents = newAmount % 100;

  const handleJobSelection = (jobId) => {
    // Check if jobId is 4 and hide entry field.
    if (prevJobChoice === jobId) {
      setSelectedJob(null);
      setPrevJobChoice(null);
      setShowInput(false);
      console.log("No Show Input");
    } else {
      setSelectedJob(jobId);
      setPrevJobChoice(jobId);
      // Look up the job in the array
      const selectedJobObj = jobs.find((job) => job.id === jobId);
      // if a job is located, pull default variables from it and
      //populate the attributes for the remainder of the workflow:
      if (selectedJobObj) {
        setNewTitle(selectedJobObj.title);
        setNewAmount(selectedJobObj.amount);
        setNewFrequency(selectedJobObj.frequency);
        setNewTimeSpanHours(selectedJobObj.timeSpanHours);
      }
    }
  };

  const handleFrequencySelection = (frequencyId) => {
    if (prevFrequency === frequencyId) {
      setSelectedFrequency(null);
      setPrevFrequency(null);
    } else {
      setSelectedFrequency(frequencyId);
      setPrevFrequency(frequencyId);
      const selectedFrequencyObj = frequencies.find(
        (frequency) => frequency.id === frequencyId
      );
      if (selectedFrequencyObj) {
        setNewFrequency(selectedFrequencyObj.frequency);
        setNewTimeSpanHours(selectedFrequencyObj.timeSpanHours);
      }
    }
  };

  const increment = () => {
    setNewAmount(newAmount + 50);
  };

  const decrement = () => {
    if (newAmount > 0) {
      // optional check to prevent negative values
      setNewAmount(newAmount - 50);
    }
  };

  async function jobSubmission() {
    const wasSuccessful = await createJob(
      newTitle,
      newFrequency,
      newTimeSpanHours,
      newAmount,
      activeUser
    );
    if (wasSuccessful) {
      console.log("Job was successfully created.", newAmount);
      refreshJobs();
      guideSend("DONE");
      onClose();
    } else {
      console.log("Job creation failed.");
    }
  }

  let pageContent;
  switch (workflowState) {
    case 0:
      //
      // Workflow Start
      pageContent = (
        <>
          <div className="roundColumn1">
            <div>
              <div className="jobModalTitleContainer">
                <h1>Choose A Daily Job</h1>
              </div>
              <p>
                <strong>Parent Tip: </strong>With your child, select a job that
                they can or already do every day. <br />
                <br />
                Consider activities like 'going to school' a job your child
                already does and should get paid for. This helps reinforce the
                idea that their time and attention has immediate value.
              </p>
            </div>
            <img
              className="jobWorkflowAvatar"
              src={jobFTUE2}
              alt="Pig running on a treadmill"
            />
          </div>
          <div className="roundColumn2">
            <div className="jobModalExitContainer">
              <button
                className="buttonStyle3"
                onClick={() => guideSend("CLOSE")}
              >
                X
              </button>
            </div>
            <div className="modalActionContents">
              <div className="listWrapper">
                {jobs
                  .filter((job) => job.id >= 0 && job.id <= 19)
                  .map((job) => (
                    <button
                      key={job.id}
                      className={
                        selectedJob === job.id
                          ? "jobChoiceSelected"
                          : "jobChoice"
                      }
                      onClick={() => handleJobSelection(job.id)} // Passing jobId
                    >
                      {job.title}
                    </button>
                  ))}
                {showInput && (
                  <input
                    type="text"
                    placeholder="Enter your job title"
                    onChange={(e) => setNewTitle(e.target.value)}
                  />
                )}
              </div>
            </div>
            <div className="modalFooterControlSingle ">
              <button
                className={selectedJob !== null ? "button" : "button-disabled"}
                onClick={
                  selectedJob !== null ? () => setNewWorkflowState(1) : null
                }
              >
                Next
              </button>
            </div>
          </div>
        </>
      );
      break;
    case 1:
      //
      //
      //workflow start
      pageContent = (
        <>
          <div className="roundColumn1">
            <div>
              <div className="jobModalTitleContainer">
                <h1>Select how many times a day this job can be marked done</h1>
              </div>
              <p>
                <strong>Parent Tip: </strong>Jobs that pay once per day will
                only allow the child to mark it as complete once every 24 hours.
                Jobs that can be completed multiple times per day have no limit.
              </p>
            </div>
            <img
              className="jobWorkflowAvatar"
              src={jobFTUE2}
              alt="Pig running on a treadmill"
            />
          </div>
          <div className="roundColumn2">
            <div className="jobModalExitContainer">
              <button
                className="buttonStyle3"
                onClick={() => guideSend("CLOSE")}
              >
                X
              </button>
            </div>
            <div className="modalActionContents">
              <div className="listWrapper">
                {frequencies
                  .filter((frequency) => frequency.id >= 0 && frequency.id <= 1)
                  .map((frequency) => (
                    <button
                      key={frequency.id}
                      className={
                        selectedFrequency === frequency.id
                          ? "jobChoiceSelected"
                          : "jobChoice"
                      }
                      onClick={() => handleFrequencySelection(frequency.id)} // Passing jobId
                    >
                      {frequency.title}
                    </button>
                  ))}
              </div>
            </div>
            <div className="modalFooterControls ">
              <button className="button" onClick={() => setNewWorkflowState(0)}>
                Back
              </button>
              <button
                className="button"
                onClick={
                  selectedFrequency !== null
                    ? () => setNewWorkflowState(2)
                    : null
                }
              >
                Next
              </button>
            </div>
          </div>
        </>
      );
      break;
    case 2:
      //
      //
      //workflow start
      pageContent = (
        <>
          <div className="roundColumn1">
            <div>
              <div className="jobModalTitleContainer">
                <h1>Select how much this job is worth when completed</h1>
              </div>
              <p>
                <strong>Parent Tip: </strong>Discuss with your child what a
                meaningful amount is for completing this job.
                <br />
                <br />
                Let them negotiate.
                <br />
                <br />
                Settle on an amount that you can reasonably pay while still
                providing a meaningful incentive. You can always change this
                later.
              </p>
            </div>
            <img
              className="jobWorkflowAvatar"
              src={jobFTUE2}
              alt="Pig running on a treadmill"
            />
          </div>
          <div className="roundColumn2">
            <div className="jobModalExitContainer">
              <button
                className="buttonStyle3"
                onClick={() => guideSend("CLOSE")}
              >
                X
              </button>
            </div>
            <div className="modalActionContents">
              <div className="modalCounter">
                <button className="button" onClick={decrement}>
                  -
                </button>
                <h2>
                  ${dollars}.{cents.toString().padStart(2, "0")}
                </h2>
                <button className="button" onClick={increment}>
                  +
                </button>
              </div>
            </div>
            <div className="modalFooterControls ">
              <button className="button" onClick={() => setNewWorkflowState(1)}>
                Back
              </button>
              <button className="button" onClick={jobSubmission}>
                Next
              </button>
            </div>
          </div>
        </>
      );
      break;
    //
    //
    //
    //
    // DEFAULT ADD FLOW HERE
    //
    //
    //

    case 3:
      //
      // Workflow Start
      pageContent = (
        <>
          <div className="roundColumn1">
            <div>
              <div className="jobModalTitleContainer">
                <h1>Select a job for your child</h1>
              </div>
              <p>
                <strong>Parent Tip: </strong>With your child, discuss all of the
                activities that you do around the house that they might not
                notice, on a daily, weekly, and monthly basis. <br />
                <br />
                Consider writing these activities down and asking them to review
                which jobs they would be willing to do for payment. <br />
                <br />
                Keep this list for future reference, as the app will prompt them
                to find additional ways to earn money.
              </p>
            </div>
            <img
              className="jobWorkflowAvatar"
              src={jobFTUE2}
              alt="Pig running on a treadmill"
            />
          </div>
          <div className="roundColumn2">
            <div className="jobModalExitContainer">
              <button
                className="buttonStyle3"
                onClick={() => guideSend("CLOSE")}
              >
                X
              </button>
            </div>
            <div className="modalActionContents">
              <div className="listWrapper">
                {jobs
                  .filter((job) => job.id >= 5 && job.id <= 11)
                  .map((job) => (
                    <button
                      key={job.id}
                      className={
                        selectedJob === job.id
                          ? "jobChoiceSelected"
                          : "jobChoice"
                      }
                      onClick={() => handleJobSelection(job.id)} // Passing jobId
                    >
                      {job.title}
                    </button>
                  ))}
                {showInput && (
                  <input
                    type="text"
                    placeholder="Enter your job title"
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                  />
                )}
              </div>
            </div>
            <div className="modalFooterControlSingle ">
              <button
                className={selectedJob !== null ? "button" : "button-disabled"}
                onClick={
                  selectedJob !== null ? () => setNewWorkflowState(4) : null
                }
              >
                Next
              </button>
            </div>
          </div>
        </>
      );
      break;
    case 4:
      //
      //
      //workflow start
      pageContent = (
        <>
          <div className="roundColumn1">
            <div>
              <div className="jobModalTitleContainer">
                <h1>
                  Select how frequently <br /> this job can be completed
                </h1>
              </div>
              <p>
                <strong>Parent Tip: </strong>Jobs that pay once per day will
                only allow the child to mark it as complete once every 24 hours.
                Jobs that can be completed multiple times per day have no limit.
              </p>
            </div>
            <img
              className="jobWorkflowAvatar"
              src={jobFTUE2}
              alt="Pig running on a treadmill"
            />
          </div>
          <div className="roundColumn2">
            <div className="jobModalExitContainer">
              <button
                className="buttonStyle3"
                onClick={() => guideSend("CLOSE")}
              >
                X
              </button>
            </div>
            <div className="modalActionContents">
              {frequencies
                .filter((frequency) => frequency.id >= 0 && frequency.id <= 6)
                .map((frequency) => (
                  <button
                    key={frequency.id}
                    className={
                      selectedFrequency === frequency.id
                        ? "jobChoiceSelected"
                        : "jobChoice"
                    }
                    onClick={() => handleFrequencySelection(frequency.id)} // Passing jobId
                  >
                    {frequency.title}
                  </button>
                ))}
            </div>
            <div className="modalFooterControls ">
              <button className="button" onClick={() => setNewWorkflowState(3)}>
                Back
              </button>
              <button
                className="button"
                onClick={
                  selectedFrequency !== null
                    ? () => setNewWorkflowState(2)
                    : null
                }
              >
                Next
              </button>
            </div>
          </div>
        </>
      );
      break;
    case 5:
      //
      //
      //workflow start
      pageContent = (
        <>
          <div className="roundColumn1">
            <div>
              <div className="jobModalTitleContainer">
                <h1>Select how much this job is worth when completed</h1>
              </div>
              <p>
                <strong>Parent Tip: </strong>Discuss with your child what a
                meaningful amount is for completing this job.
                <br />
                <br />
                Let them negotiate.
                <br />
                <br />
                Settle on an amount that you can reasonably pay while still
                providing a meaningful incentive. You can always change this
                later.
              </p>
            </div>
            <img
              className="jobWorkflowAvatar"
              src={jobFTUE2}
              alt="Pig running on a treadmill"
            />
          </div>
          <div className="roundColumn2">
            <div className="jobModalExitContainer">
              <button
                className="buttonStyle3"
                onClick={() => guideSend("CLOSE")}
              >
                X
              </button>
            </div>
            <div className="modalActionContents">
              <div className="modalCounter">
                <button className="button" onClick={decrement}>
                  -
                </button>
                <h2>
                  ${dollars}.{cents.toString().padStart(2, "0")}
                </h2>
                <button className="button" onClick={increment}>
                  +
                </button>
              </div>
            </div>
            <div className="modalFooterControls ">
              <button className="button" onClick={() => setNewWorkflowState(4)}>
                Back
              </button>
              <button className="button" onClick={jobSubmission}>
                Next
              </button>
            </div>
          </div>
        </>
      );
      break;
    default:
    //
    //Show Nothing
  }

  //
  // Render everything

  return (
    <div className="actModalOverlay">
      <div className="jobModal">{pageContent}</div>
    </div>
  );
}
