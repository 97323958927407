import { useState } from "react";
import { db, auth } from "../Firebase";
import {
  getDocs,
  collection,
  query,
  where,
  updateDoc
} from "firebase/firestore";
import { removeAccount } from "./AccountFunctions.js";

export default function AccountEditModal({
  existingAccount,
  selectedAccount,
  selectedAccountId,
  images,
  onClose,
  getAccountList,
  activeUser
}) {
  const [selectedImg, setSelectedImg] = useState(selectedAccount?.image);
  const [accountName, setAccountName] = useState(selectedAccount?.name);
  const [error, setError] = useState("");

  const handleClick = (imageId) => {
    setSelectedImg(imageId);
  };

  const accountsCollectionRef = collection(db, "accounts");

  const handleSubmit = async () => {
    // Check for empty or whitespace-only accountName
    if (!accountName || accountName.trim() === "") {
      setError("Account name is required!");
      return;
    }
    // Query documents where userId matches the authenticated user's UID
    const userAccountsQuery = query(
      accountsCollectionRef,
      where("parentUID", "==", auth.currentUser.uid),
      where("userDocID", "==", activeUser)
    );
    try {
      const snapshot = await getDocs(userAccountsQuery);
      let docRef;
      // find the document with the provided ID
      snapshot.forEach((doc) => {
        if (doc.id === selectedAccountId) {
          docRef = doc.ref;
        }
      });
      //if found, update the document
      if (docRef) {
        await updateDoc(docRef, {
          name: accountName,
          image: selectedImg
        });
        getAccountList();
        onClose();
      } else {
        console.error("account with given ID not found");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleRemove = async () => {
    const success = await removeAccount(selectedAccount.id, activeUser);
    if (success) {
      getAccountList();
      onClose();
    } else {
      // handle error case here
    }
  };

  return (
    <div className="actModalOverlay">
      <div className="actModalContent">
        <div className="modalTitleControl">
          <h1>Edit Account</h1>
          <button className="buttonStyle3" onClick={onClose}>
            X
          </button>
        </div>
        <div className="actEditModalSubContents">
          <div className="">
            <p>Account Name</p>
            <input
              className="inputFull"
              placeholder={selectedAccount?.name}
              onChange={(e) => {
                setAccountName(e.target.value);
                setError(""); // Clear the error once the user starts typing
              }}
            />
            {error && <p style={{ color: "red" }}>{error}</p>}
          </div>
          <div className="avatarModalSelection">
            <p>Account Avatar:</p>
            {images.map((image) => (
              <img
                key={image.id}
                src={image.src}
                alt={image.alt}
                onClick={() => handleClick(image.id)}
                className={`acctAvatarEdit ${
                  selectedImg === image.id
                    ? "selectedAcctAvatar"
                    : "defaultAcctAvatar"
                }`}
              />
            ))}
          </div>
          <div className="modalFooterControls2">
            <button className="button" onClick={handleRemove}>
              Delete
            </button>
            <button className="button" onClick={handleSubmit}>
              save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
