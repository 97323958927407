export default function StickyFooter({
  onClickIncrement,
  incrementText,
  onClickDecrement,
  decrementText
}) {
  return (
    <div className="stickyFooter">
      <button className={"button"} onClick={onClickDecrement}>
        {decrementText}
      </button>
      <button className={"button"} onClick={onClickIncrement}>
        {incrementText}
      </button>
    </div>
  );
}
