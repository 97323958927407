// Menu
import { avatars } from "./Avatars.js";
import React, { useState } from "react";
import moment from "moment";

export default function Menu({
  setMenuOpen,
  isActive,
  userName,
  logOut,
  selectedAvatar,
  currentDate,
  setCurrentDate,
  endOfWeek,
  setEndOfWeek,
  userPayCycles,
  setUserPayCycles,
  canBorrow,
  setCanBorrow,
  canCarry,
  setCanCarry,
  streakLvl,
  setStreakLvl,
  streakLvl1Status,
  setStreakLvl1Status,
  payBalanceLvl,
  setPayBalanceLvl,
  testBalance,
  setTestBalance,
  needsBreak,
  setNeedsBreak,
  getExpenseList,
  guideSend,
  setSeenExpFTUE,
  setSeenJobFTUE,
  setISawPaydayFTUE,
  seenExpFTUE,
  seenJobFTUE,
  iSawPaydayFTUE,
  setRemoveUserModal,
  seenJobsAndExpLevel2,
  setSeenJobsAndExpLevel2,
  isPayday,
  setIsPayday
}) {
  console.log("menu Rendered", isActive);
  const selectedImage =
    avatars.find((image) => image.id === selectedAvatar) || {};
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const handleClose = () => {
    //guideSend("RESET_GUIDE_MACHINE");
    setMenuOpen(false);
  };
  return (
    <div className="menuOverlay">
      <div className={"menuContainer"}>
        <div className="">
          <button onClick={handleClose}>X</button>
        </div>
        <div className="menuUserDetails">
          <div className="userNameAndPic">
            <img
              className="menuAvatar"
              src={selectedImage.src}
              alt={selectedImage.alt}
            />
            <h2>{userName}</h2>
          </div>
        </div>
        <div className="scrollableContent">
          <div className="menuBody">
            <h1>Totals</h1>
            <div>
              <p>
                <strong>Total Amount Earned: </strong>
              </p>
            </div>
            <div>
              <p>
                <strong>Total Amount Spent: </strong>
              </p>
            </div>
            <p>
              Parents: This is the amount your child has spent on expenses. We
              recommend setting this amount aside and giving it to them at a
              later date.
            </p>
            {/* Add Settings accordion toggle button */}
            <button onClick={toggleSettings}>Settings</button>
            {isSettingsOpen && (
              <div className="menuSettings">
                <div>
                  <label>Current Date</label>
                  <input
                    type="date"
                    value={moment(currentDate).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setCurrentDate(moment(e.target.value).toDate());
                      getExpenseList();
                    }}
                  />
                </div>
                <div>
                  <label>End Of Week</label>
                  <select
                    value={endOfWeek}
                    onChange={(e) => {
                      setEndOfWeek(e.target.value);
                      getExpenseList();
                    }}
                  >
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                  </select>
                </div>
                <div>
                  <label>Is Pay Day?</label>
                  <select
                    value={isPayday}
                    onChange={(e) => {
                      setIsPayday(e.target.value);
                    }}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
                <div>
                  <label>Pay Cycles Completed</label>
                  <select
                    value={userPayCycles}
                    onChange={(e) => {
                      setUserPayCycles(parseInt(e.target.value, 10));
                    }}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>
                <div>
                  <label>TestBalance</label>
                  <select
                    value={testBalance}
                    onChange={(e) => {
                      setTestBalance(e.target.value);
                    }}
                  >
                    <option value="positive">Positive</option>
                    <option value="equal">Equal</option>
                    <option value="negative">Negative</option>
                  </select>
                </div>

                <div>
                  <label>Streak Level</label>
                  <select
                    value={streakLvl}
                    onChange={(e) => {
                      setStreakLvl(parseInt(e.target.value, 10));
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </select>
                </div>
                <div>
                  <label>Streak Status</label>
                  <select
                    value={streakLvl1Status}
                    onChange={(e) => {
                      setStreakLvl1Status(e.target.value);
                    }}
                  >
                    <option value="available">Available</option>
                    <option value="inProgress">In Progress</option>
                    <option value="complete">complete</option>
                  </select>
                </div>
                <div>
                  <label>Pay Balance Level</label>
                  <select
                    value={payBalanceLvl}
                    onChange={(e) => {
                      setPayBalanceLvl(parseInt(e.target.value, 10));
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </select>
                </div>
                <div>
                  <label>Can Carry Balance</label>
                  <select
                    value={canCarry}
                    onChange={(e) => {
                      setCanCarry(e.target.value === "true");
                    }}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
                <div>
                  <label>Can Borrow</label>
                  <select
                    value={canBorrow}
                    onChange={(e) => {
                      setCanBorrow(e.target.value === "true");
                    }}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
                <div>
                  <label>NeedsBreak</label>
                  <select
                    value={needsBreak}
                    onChange={(e) => {
                      setNeedsBreak(e.target.value === "true");
                    }}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
                <div>
                  <label>Seen Job FTUE</label>
                  <select
                    value={seenJobFTUE}
                    onChange={(e) => {
                      setSeenJobFTUE(e.target.value === "true");
                    }}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
                <div>
                  <label>Seen Exp FTUE</label>
                  <select
                    value={seenExpFTUE}
                    onChange={(e) => {
                      setSeenExpFTUE(e.target.value === "true");
                    }}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
                <div>
                  <label>Seen Payday FTUE</label>
                  <select
                    value={iSawPaydayFTUE}
                    onChange={(e) => {
                      setISawPaydayFTUE(e.target.value === "true");
                    }}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
                <div>
                  <label>Seen JobsAndExpenses Lvl 2 Guide</label>
                  <select
                    value={seenJobsAndExpLevel2}
                    onChange={(e) => {
                      setSeenJobsAndExpLevel2(e.target.value === "true");
                    }}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="modalFooterControlSingle ">
          <div className="doubleButtonFooter ">
            <button
              className="borderless"
              onClick={() => setRemoveUserModal(true)}
            >
              Delete Profile
            </button>
            <button className="button" onClick={logOut}>
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
