import { useState, useEffect } from "react";
import pigShadow from "./img/pigShadow.png";
import { db, auth } from "./Firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword
} from "firebase/auth";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";

export default function SignIn({
  startupSend,
  email,
  setEmail,
  password,
  setPassword,
  setAuthenticated,
  setActiveUser,
  setUserPayCycles,
  setFamilyDocId,
  setUsername,
  setSelectedAvatar
}) {
  console.log("Current User Is " + auth?.currentUser?.email);

  const [errorText, setErrorText] = useState(null);
  const familiesCollectionRef = collection(db, "families");
  const [isIntroStatus, setIntroStatus] = useState(1);
  const [cohortCode, setCohortCode] = useState(null);
  const VALID_CODES = {
    learning: "learning",
    evaluate: "evaluate"
  }; // replace this with your valid codes

  const validateCohortCode = () => {
    // Check if the code entered by the user exists in the VALID_CODES
    if (VALID_CODES[cohortCode]) {
      // Do something based on the cohortCode, e.g.,
      // console.log(`Joined the ${VALID_CODES[cohortCode]}`);
      setEmail(null);
      setPassword(null);
      setIntroStatus(4); // If valid, call incrementStatus
    } else {
      // If invalid, set an error message
      setErrorText({
        message: "Sorry, this code does not exist. Please try again."
      });
    }
  };
  //
  //
  //
  ///Incrementer for setup states
  const incrementStatus = () => {
    if (isIntroStatus < 10) {
      setIntroStatus((prevStatus) => prevStatus + 1);
    } else {
      //do nothing
    }
  };
  const decrementStatus = () => {
    if (isIntroStatus > 0) {
      setIntroStatus((prevStatus) => prevStatus - 1);
    } else {
      //do nothing
    }
  };
  //
  //
  // Create New User
  const createUser = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log("user credential is", user);
      // Create the family document
      const familyDocRef = await addDoc(familiesCollectionRef, {
        parentUID: user.uid,
        parentEmail: email,
        cohort: cohortCode
      });
      // Create a document inside the family's 'users' sub-collection
      const usersSubCollectionRef = collection(familyDocRef, "users");
      console.log("user subcollectionRef is ", usersSubCollectionRef);
      const userDocRef = await addDoc(usersSubCollectionRef, {
        name: null,
        payCycles: 0,
        selectedAvatar: null
      });
      const userSnapshot = await getDocs(usersSubCollectionRef);
      console.log("Number of user docs:", userSnapshot.size);
      setAuthenticated(true);
      setActiveUser(userDocRef.id);
      setUserPayCycles(0);
      startupSend("NEWUSER");
      console.log("User doc ref ID is: ", userDocRef.id);
    } catch (err) {
      console.error(err);
      setErrorText(err.message); // Using err.message to get a more user-friendly error message
    }
  };
  //
  //
  //
  useEffect(() => {
    let timer;
    if (errorText) {
      timer = setTimeout(() => {
        setErrorText(null);
      }, 2000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [errorText]);
  //
  //
  //
  // Sign in for existing users
  const signIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("I Just Signed In " + auth?.currentUser?.email);
      // THIS CLOSES AUTHENTICATION setAuthenticated(true);

      // Check the number of users in the family
      const familiesRef = collection(db, "families");
      const familySnapshot = await getDocs(
        query(familiesRef, where("parentUID", "==", auth.currentUser.uid))
      );
      //Parse data
      if (!familySnapshot.empty) {
        const familyDoc = familySnapshot.docs[0];
        const familyDocId = familyDoc.id;
        setFamilyDocId(familyDoc.id);

        // Get a reference to the 'users' sub-collection of the family document
        const usersSubCollectionRef = collection(
          db,
          "families",
          familyDocId,
          "users"
        );
        const userSnapshot = await getDocs(usersSubCollectionRef);

        // Check the number of users in this family
        if (userSnapshot.size > 1) {
          // Open the user picker modal if there are multiple users
          setAuthenticated(true);
          startupSend("NUMOFUSERS2");
        } else if (userSnapshot.size === 1) {
          // Automatically set the active user if there is only one
          setAuthenticated(true);
          setActiveUser(userSnapshot.docs[0].id);
          setSelectedAvatar(userSnapshot.docs[0].data().selectedAvatar);
          setUsername(userSnapshot.docs[0].data().name);
          startupSend("NUMOFUSERS1");
        }
      }
    } catch (err) {
      console.error(err);
      setErrorText(err);
    }
  };
  //
  //
  //
  //
  //
  // PAGE UI CONTROL HERE
  let pageContent;
  switch (isIntroStatus) {
    case 1:
      pageContent = (
        <div className="centeredContainer vertColumn">
          <div className="introImage">
            <img src={pigShadow} alt="Pig" />
          </div>
          <div className="introTitleWSubText">
            <h3>
              Welcome to the <br /> minimogul prototype!
            </h3>
            <p>Parents, please sign in or create an account to get started.</p>
            <input
              placeholder="Email..."
              className="inputCell"
              onChange={(e) => setEmail(e.target.value)}
              autoCapitalize="none"
            />
            <input
              placeholder="Password..."
              className="inputCell"
              onChange={(e) => setPassword(e.target.value)}
              autoCapitalize="none"
            />
            {/* Display error text if it exists */}
            {errorText && <p style={{ color: "red" }}>{errorText.message}</p>}
            <div className="introTitleFooter">
              <button className="button" onClick={signIn}>
                Sign In
              </button>
              <button className="framelessButton" onClick={incrementStatus}>
                Create an account
              </button>
            </div>
          </div>
        </div>
      );
      break;
    case 2:
      pageContent = (
        <div className="centeredContainer vertColumn">
          <div className="introImage">
            <img src={pigShadow} alt="Pig" />
          </div>
          <div className="introTitleWSubText">
            <p>Please enter the signup code you were given to continue:</p>
            <input
              key="signupCodeInput"
              placeholder="Sign up code..."
              className="inputCell"
              onChange={(e) => setCohortCode(e.target.value)}
            />
            {/* Display error text if it exists */}
            {errorText && <p style={{ color: "red" }}>{errorText.message}</p>}
            <div className="introTitleFooter">
              <button className="button" onClick={validateCohortCode}>
                Next
              </button>
              <button className="framelessButton" onClick={incrementStatus}>
                I don't have one
              </button>
            </div>
          </div>
        </div>
      );
      break;
    case 3:
      pageContent = (
        <div className="centeredContainer vertColumn">
          <div className="introImage">
            <img src={pigShadow} alt="Pig" />
          </div>
          <div className="introTitleWSubText">
            <h3>
              Missing your code? <br /> That's Ok.
            </h3>
            <p>
              Text or call us at: <br /> ###.###.#### <br /> to get a new one.
            </p>
            <div className="introTitleFooter">
              <button className="button" onClick={() => setIntroStatus(1)}>
                Done
              </button>
            </div>
          </div>
        </div>
      );
      break;
    case 4:
      pageContent = (
        <div className="centeredContainer vertColumn">
          <div className="introImage">
            <img src={pigShadow} alt="Pig" />
          </div>
          <div className="introTitleWSubText">
            <p>Parents, enter the email and password you’ll use to sign in.</p>
            <input
              key="emailInput"
              placeholder="Email..."
              className="inputCell"
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="off"
              autoCapitalize="none"
            />
            <input
              key="passwordInput"
              placeholder="Password..."
              className="inputCell"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="off"
              autoCapitalize="none"
            />
            {/* Display error text if it exists */}
            {errorText && <p style={{ color: "red" }}>{errorText.message}</p>}
            <div className="introTitleFooter">
              <button className="button" onClick={createUser}>
                Create account
              </button>
              <button
                className="framelessButton"
                onClick={() => setIntroStatus(1)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      );
      break;
    default:
  }

  return <div>{pageContent}</div>;
}
