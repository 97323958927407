import repeat from "../img/repeat.png";

export default function ExpenseCell({
  title,
  amount,
  onClick,
  paidStatusText
}) {
  let dollars = Math.floor(amount / 100);
  let cents = amount % 100;

  return (
    <div className="jobCard ">
      <div className="expenseCellLeft">
        <img className="expenseCycleIcon" src={repeat} alt="repeating Icon" />
        <div className="accountTitle" onClick={onClick}>
          <h5>{title}</h5>
          <p>
            {" "}
            <strong>
              ${dollars}.{cents.toString().padStart(2, "0")}
            </strong>{" "}
            a week
          </p>
        </div>
      </div>
      <div className="expenseSubTitle">
        <p>{paidStatusText}</p>
      </div>
    </div>
  );
}
