import React, { useState, useEffect } from "react";
import { undoAction } from "./JobsFunctions.js";
import { frequencies } from "../Frequencies.js";
import { updateJob, removeJob } from "./JobsFunctions.js";

export default function EditCellModal({
  onClose,
  title = "",
  amount = 0,
  frequency,
  timeSpanHours,
  cellId,
  actionTimeStamps = [],
  refreshJobs,
  selectedCell,
  setSelectedCell,
  setIsJobEditModalOpen,
  activeUser
}) {
  const [newTitle, setNewTitle] = useState(title);
  const [newAmount, setNewAmount] = useState(amount);
  const [newFrequency, setNewFrequency] = useState(frequency);
  const [newTimeSpanHours, setNewTimeSpanHours] = useState(timeSpanHours);
  const [localActionTimeStamps, setLocalActionTimeStamps] = useState(
    actionTimeStamps
  );
  const [rawAmount, setRawAmount] = useState(newAmount / 100);
  const [formattedAmount, setFormattedAmount] = useState(
    formatAsCurrency(newAmount / 100)
  );
  const [isFocused, setIsFocused] = useState(false);
  const handleModalClose = React.useCallback(
    (event) => {
      if (
        event.target.classList.contains("actModalOverlay") ||
        event.target.classList.contains("modal-content")
      ) {
        onClose();
      }
    },
    [onClose]
  );

  //
  // Looking for changes based on user activity
  useEffect(() => {
    setNewTitle(title);
    setNewAmount(amount);
  }, [title, amount]);

  useEffect(() => {
    window.addEventListener("click", handleModalClose);
    return () => {
      window.removeEventListener("click", handleModalClose);
    };
  }, [handleModalClose]);

  async function handleUndoAction(id, index) {
    const wasSuccessful = await undoAction(id, index);
    if (wasSuccessful) {
      console.log("Action Timestamp was logged.");
      const updatedTimeStamps = [...localActionTimeStamps];
      updatedTimeStamps.splice(index, 1);
      setLocalActionTimeStamps(updatedTimeStamps);
      refreshJobs();
    } else {
      console.log("Action timestamp failed to write");
    }
  }

  function formatAsCurrency(value) {
    return parseFloat(value).toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
  }
  const handleAmountBlur = () => {
    const updatedAmount = Math.round(parseFloat(rawAmount) * 100);
    setNewAmount(updatedAmount);
    setFormattedAmount(formatAsCurrency(updatedAmount / 100));
  };
  //
  //

  function formatDate(date) {
    const today = new Date();
    const daysAgo = (days) => {
      const newDate = new Date();
      newDate.setDate(today.getDate() - days);
      return newDate;
    };
    const isSameDay = (d1, d2) =>
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
    if (isSameDay(date, today)) return "Today";
    if (isSameDay(date, daysAgo(1))) return "Yesterday";
    for (let i = 2; i <= 6; i++) {
      if (isSameDay(date, daysAgo(i))) return `${i} days ago`;
    }
    if (isSameDay(date, daysAgo(7))) return "1 week ago";
    return date.toLocaleDateString();
  }
  //
  //
  //
  const handleTitleChange = (event) => {
    setNewTitle(event.target.value);
  };
  //
  //
  //

  const handleAmountChange = (event) => {
    let value = event.target.value;
    // Remove any non-numeric characters except for a decimal point
    value = value.replace(/[^0-9.]/g, "");
    if (value === "" || /^\d*\.?\d{0,2}$/.test(value)) {
      // Allow empty string or decimal number with up to 2 decimal places
      setRawAmount(value);
    }
  };
  //
  //
  //
  //
  //
  //

  async function handleEditSubmit() {
    const wasSuccessful = await updateJob(
      selectedCell.id,
      newTitle,
      newAmount,
      newFrequency,
      newTimeSpanHours,
      activeUser
    );
    if (wasSuccessful) {
      console.log("Job Was Edited.");
      // Optionally, you can update the jobList state here to remove the job from the UI
      refreshJobs();
      setSelectedCell(null);
      setIsJobEditModalOpen(false);
    } else {
      console.log("Job Edit failed.");
      console.log("Job Cell ID is: ", selectedCell.id);
    }
  }
  //
  //
  async function handleRemoveJob(id) {
    const wasSuccessful = await removeJob(selectedCell.id, activeUser);
    if (wasSuccessful) {
      console.log("Action Timestamp was logged.");
      refreshJobs();
      setSelectedCell(null);
      setIsJobEditModalOpen(false);
    } else {
      console.log("Action timestamp failed to write");
    }
  }

  console.log("exiting F: " + newFrequency + "Week: " + newTimeSpanHours);

  return (
    <div className="actModalOverlay">
      <div className="actModalContent">
        <div className="modalTitleControl">
          <h1>Edit Job</h1>
          <button className="buttonStyle3" onClick={onClose}>
            X
          </button>
        </div>
        <div className="actModalColumns">
          <div className="jobColumn-1">
            <label>Job Title:</label>
            <input
              type="text"
              className="inputFull"
              value={newTitle}
              onChange={handleTitleChange}
            />
            <label>Amount to pay when completed:</label>
            <input
              type="text"
              className="inputFull"
              value={isFocused ? rawAmount : formattedAmount}
              onFocus={() => {
                setIsFocused(true);
                setRawAmount(newAmount / 100);
              }}
              onChange={handleAmountChange}
              onBlur={() => {
                handleAmountBlur();
                setIsFocused(false);
              }}
            />
            <label>How often this job can be completed:</label>
            <select
              value={`${newFrequency}-${newTimeSpanHours}`}
              className="inputFull"
              // Construct a unique value for each frequency-timeSpan combination
              onChange={(e) => {
                const [freq, timeSpan] = e.target.value.split("-");
                setNewFrequency(Number(freq));
                setNewTimeSpanHours(Number(timeSpan));
                //console.log("New F: " + newFrequency + "new time: " + newTimeSpanHours)
              }}
            >
              {frequencies.map((freq) => (
                <option
                  key={freq.id}
                  value={`${freq.frequency}-${freq.timeSpanHours}`}
                >
                  {freq.title}
                </option>
              ))}
            </select>
          </div>
          <div className="jobColumn-2">
            <label>Completed on:</label>
            <div>
              <div>
                {localActionTimeStamps
                  .filter((actionObj) => actionObj.paid === false)
                  .map((actionObj, index) => (
                    <div key={index}>
                      <span
                        title={new Date(
                          actionObj.doneTimestamp
                        ).toLocaleDateString()}
                      >
                        {formatDate(new Date(actionObj.doneTimestamp))} at{" "}
                        {new Date(actionObj.doneTimestamp).toLocaleTimeString()}
                      </span>
                      <button onClick={() => handleUndoAction(cellId, index)}>
                        X
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="modalFooterControls2">
          <button className="button" onClick={handleRemoveJob}>
            Remove
          </button>
          <button className="button" onClick={handleEditSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
