//PayDayFTUE.JS
import { useState } from "react";
import pigMoneyToss from "../img/pigMoneyToss.png";

export const PayDayFTUE = ({ guideSend }) => {
  const [flowPosition, setFlowPosition] = useState(1);
  //
  // Increment position
  const incrementStatus = () => {
    if (flowPosition < 7) {
      setFlowPosition((prevStatus) => prevStatus + 1);
    } else {
      //do nothing
    }
  };
  const decrementStatus = () => {
    if (flowPosition > 0) {
      setFlowPosition((prevStatus) => prevStatus - 1);
    } else {
      //do nothing
    }
  };

  const handleExit = () => {
    guideSend("CLOSE");
  };

  let pageContent;
  switch (flowPosition) {
    case 1:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1>Get Ready For Payday!</h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="ftueColumn-1">
              <img
                className="jobAvatar"
                src={pigMoneyToss}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <h1>How It Works:</h1>
                <p>
                  Every Friday, you can activate pay day. On pay day, you'll
                  revew everything you earned in the last week.
                </p>
              </div>
              <div className="modalFooterControlSingle">
                <button className="button" onClick={incrementStatus}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case 2:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1>Get Ready For Payday!</h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="ftueColumn-1">
              <img
                className="jobAvatar"
                src={pigMoneyToss}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <h1>How It Works:</h1>
                <p>
                  You’ll also review any expenses that are owed and pay those
                  using the money you've earned.
                </p>
              </div>
              <div className="modalFooterControls2">
                <button className="button" onClick={decrementStatus}>
                  Back
                </button>
                <button className="button" onClick={incrementStatus}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case 3:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1>Get Ready For Payday!</h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="ftueColumn-1">
              <img
                className="jobAvatar"
                src={pigMoneyToss}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <h1>Tracking What's Been Spent</h1>
                <p>
                  The app will keep track of how much your child has 'spent' on
                  expenese over time.
                </p>
                <p>
                  Parents, we encourage you to take this money and put it in a
                  savings account that can be given to them at a later date of
                  your choice.
                </p>
              </div>
              <div className="modalFooterControls2">
                <button className="button" onClick={decrementStatus}>
                  Back
                </button>
                <button className="button" onClick={() => guideSend("DONE")}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case 4:
      break;
    default:
      pageContent = <div></div>;
  }

  return (
    <div className="actModalOverlay">
      <div className="actModalContent">{pageContent}</div>
    </div>
  );
};
