// Create first job flag
import pigRun from "../img/PigRun.png";

export default function CreateDailyJobFlag({ guideSend }) {
  return (
    <div className=" earningsContainer">
      <div className="levelFlagParent">
        <img
          className="levelFlagAvatar"
          src={pigRun}
          alt="Pig running on a treadmill"
        />
        <div className="flagCell">
          <div className="levelSummary">
            <h5>Getting Started:</h5>
            <h1>Create A Daily Job</h1>
          </div>
          <div className="earningSummaryControl">
            <button className="buttonStyle2" onClick={() => guideSend("GO")}>
              Go
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
