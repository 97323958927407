import loanIcon from "../img/loan.png";

// Loan Cell.js
export default function LoanCell({
  type,
  title,
  lender,
  amount,
  interest,
  dueDate,
  dueDays,
  onClick
}) {
  console.log("I have a loan called ", title);
  let dollars = Math.floor(amount / 100);
  let cents = amount % 100;

  const renderLoanByType = () => {
    switch (type) {
      case "carry":
        return (
          <>
            <p>
              ${dollars}.{cents.toString().padStart(2, "0")} for delaying
              payment
            </p>
          </>
        );
      case "balanceBorrow":
        return (
          <>
            <p>
              ${dollars}.{cents.toString().padStart(2, "0")} to {lender}. Due in{" "}
              {dueDays} days.
            </p>
          </>
        );
      default:
        return <p>Your Loan</p>;
    }
  };

  return (
    <div className="loanCard">
      <div className="primaryLoanContents">
        <div className="loanIconContainer">
          <img
            className="loanIcon"
            src={loanIcon}
            alt={"Person carrying a bag"}
          />
        </div>
        <div className="loanDetails" onClick={onClick}>
          <h5>You Owe</h5>
          {renderLoanByType()}
        </div>
      </div>
      <div className="loanAction">
        <button className="payButton">Pay</button>
      </div>
    </div>
  );
}
