// NewJobCard.js

export function NewJobCard({ job, isSelected, onToggle }) {
  // <h1>{job.title}</h1>
  // <p>{job.amount}</p>
  //<p>{job.timeSpanHours}</p>
  let dollars = Math.floor(job.amount / 100);
  let cents = job.amount % 100;

  const getFrequencyLabel = (frequency) => {
    switch (frequency) {
      case 24:
        return "daily job";
      case 168:
        return "weekly job";
      case 336:
        return "bi-weekly job";
      case 720:
        return "monthly job";
      default:
        return "";
    }
  };

  return (
    <div
      className={`newJobCardCell ${isSelected ? "selected" : ""}`}
      onClick={onToggle}
    >
      <div className="jobCardHeaderBar">
        <div className="jobFrequency">
          <h6>{getFrequencyLabel(job.timeSpanHours)}</h6>
        </div>
        <div className="starburstContainer">
          <div className="starburstValue">
            <span>
              ${dollars}.{cents.toString().padStart(2, "0")}
            </span>
            <p>earns</p>
          </div>
          <div className="starburst"></div>
        </div>
      </div>
      <div className="jobCardBody">
        <div className="jobSelectCheck ">
          {isSelected && (
            <div className="checkmarkCircle pink">
              &#x2713; {/* Unicode for checkmark */}
            </div>
          )}
        </div>
        <div className="jobTitle">
          <h2>{job.title}</h2>
        </div>
      </div>
    </div>
  );
}
