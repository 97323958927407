export const jobs = [
  {
    id: 0,
    title: "Go to school",
    amount: 100,
    frequency: 1,
    timeSpanHours: 24
  },
  {
    id: 1,
    title: "Pick up without being asked",
    amount: 100,
    frequency: 1,
    timeSpanHours: 24
  },
  {
    id: 2,
    title: "Get ready on time",
    amount: 100,
    frequency: 1,
    timeSpanHours: 24
  },
  {
    id: 3,
    title: "Make bed",
    amount: 100,
    frequency: 1,
    timeSpanHours: 24
  },
  {
    id: 4,
    title: "Help pack lunch / snack",
    amount: 100,
    frequency: 1,
    timeSpanHours: 24
  },
  {
    id: 5,
    title: "Clear table",
    amount: 100,
    frequency: 1,
    timeSpanHours: 24
  },
  {
    id: 6,
    title: "Put away my things",
    amount: 100,
    frequency: 1,
    timeSpanHours: 24
  },
  {
    id: 7,
    title: "Empty / load dishwasher",
    amount: 100,
    frequency: 1,
    timeSpanHours: 24
  },
  {
    id: 8,
    title: "Clean bedroom",
    amount: 100,
    frequency: 1,
    timeSpanHours: 24
  },
  // insert any other dayily jobs here - keep ID number under 20
  {
    id: 20,
    title: "Clean bathroom",
    amount: 100,
    frequency: 1,
    timeSpanHours: 168
  },
  {
    id: 21,
    title: "Take out trash / recycling",
    amount: 100,
    frequency: 1,
    timeSpanHours: 168
  },
  {
    id: 22,
    title: "Do the dishes",
    amount: 100,
    frequency: 1,
    timeSpanHours: 24
  },
  {
    id: 23,
    title: "Cut grass",
    amount: 300,
    frequency: 1,
    timeSpanHours: 168
  },
  {
    id: 24,
    title: "Do Laundry",
    amount: 100,
    frequency: 1,
    timeSpanHours: 168
  },
  {
    id: 99,
    title: "Create my own job",
    amount: 0,
    frequency: null,
    timeSpanHours: null
  }
];
