//RemoveUserModal.js

export default function RemoveUserModal({ setRemoveUserModal, removeUser }) {
  return (
    <div className="actModalOverlay">
      <div className="doubleConfirmationModal">
        <h1>Are you sure you want to remove this user?</h1>
        <button className="buttonStyle5" onClick={removeUser}>
          Yes, Remove User
        </button>
        <button
          className="buttonStyle4"
          onClick={() => setRemoveUserModal(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
