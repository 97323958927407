// Identity Functions
import { db, auth } from "../Firebase";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  query,
  where,
  getDoc,
  addDoc,
  deleteDoc
} from "firebase/firestore";

export default function ({}) {
  return;
}
//
// Update Name
export const updateName = async (newUserName) => {
  console.log("Updating User Name: ", newUserName);
  const familiesRef = collection(db, "families");
  const querySnapshot = await getDocs(
    query(familiesRef, where("parentUID", "==", auth.currentUser.uid))
  );
  if (!querySnapshot.empty) {
    const familyDoc = querySnapshot.docs[0]; // Assuming one family doc per UID
    const familyDocId = familyDoc.id;
    const usersSubCollectionRef = collection(
      db,
      "families",
      familyDocId,
      "users"
    );
    // Fetch the user document from the sub-collection
    const userSnapshot = await getDocs(usersSubCollectionRef);
    if (!userSnapshot.empty) {
      const userDoc = userSnapshot.docs[0]; // Assuming only one user doc for now
      await setDoc(
        doc(usersSubCollectionRef, userDoc.id),
        {
          name: newUserName
        },
        { merge: true }
      );
      console.log("user name update complated");
      return true;
    } else {
      console.error("No user document found to update");
    }
  } else {
    console.error("No family document found for the user");
  }
};
//
// Create New User
export const createNewUser = async (newUserName, newSelectedAvatar) => {
  console.log("attempting to create new user");
  const familiesRef = collection(db, "families");
  const querySnapshot = await getDocs(
    query(familiesRef, where("parentUID", "==", auth.currentUser.uid))
  );

  if (!querySnapshot.empty) {
    const familyDoc = querySnapshot.docs[0]; // Assuming one family doc per UID
    const familyDocId = familyDoc.id;
    const usersSubCollectionRef = collection(
      db,
      "families",
      familyDocId,
      "users"
    );
    const newUserID = await addDoc(usersSubCollectionRef, {
      name: newUserName,
      selectedAvatar: newSelectedAvatar,
      payCycles: 0,
      seenExpFTUE: false,
      seenJobFTUE: false
    });
    return newUserID.id;
  } else {
    console.error("No family document found for the user");
    return false;
  }
};
//
//

// Update Avatar
export const updateAvatar = async (selectedAvatar) => {
  console.log("Updating User avatar to: ", selectedAvatar);
  const familiesRef = collection(db, "families");
  const querySnapshot = await getDocs(
    query(familiesRef, where("parentUID", "==", auth.currentUser.uid))
  );
  if (!querySnapshot.empty) {
    const familyDoc = querySnapshot.docs[0]; // Assuming one family doc per UID
    const familyDocId = familyDoc.id;
    const usersSubCollectionRef = collection(
      db,
      "families",
      familyDocId,
      "users"
    );
    // Fetch the user document from the sub-collection
    const userSnapshot = await getDocs(usersSubCollectionRef);
    if (!userSnapshot.empty) {
      const userDoc = userSnapshot.docs[0]; // Assuming only one user doc for now
      await setDoc(
        doc(usersSubCollectionRef, userDoc.id),
        {
          selectedAvatar: selectedAvatar
        },
        { merge: true }
      );
      console.log("user avatar update complated");
      return true;
    } else {
      console.error("No user document found to update");
    }
  } else {
    console.error("No family document found for the user");
  }
};
//
//
//
export const importAllIdentities = async () => {
  // Check authenticated
  if (!auth.currentUser) {
    console.error("No user logged in");
    return;
  }
  // Get Family Documents
  const familiesRef = collection(db, "families");
  const querySnapshot = await getDocs(
    query(familiesRef, where("parentUID", "==", auth.currentUser.uid))
  );
  // Parse Documents
  if (!querySnapshot.empty) {
    const familyDoc = querySnapshot.docs[0];
    const familyDocId = familyDoc.id;
    const usersSubCollectionRef = collection(
      db,
      "families",
      familyDocId,
      "users"
    );
    const userSnapshot = await getDocs(usersSubCollectionRef);

    // Convert snapshot into an array of user objects
    const users = userSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));
    // Return array of user objects
    return users;
  }
};
//
//
//
export const updateUserActivity = async (
  activeUser,
  userFieldToUpdate,
  userDataToUpdate
) => {
  console.log("Updating:", activeUser, userFieldToUpdate, userDataToUpdate);

  try {
    // Obtain the family document related to the current user
    const familiesRef = collection(db, "families");
    const familySnapshot = await getDocs(
      query(familiesRef, where("parentUID", "==", auth.currentUser.uid))
    );

    // Verify family exists
    if (!familySnapshot.empty) {
      const familyDoc = familySnapshot.docs[0];
      const familyDocId = familyDoc.id;
      console.log("Found family:", familyDocId, familyDoc.data());

      // Directly reference the user document and attempt to update it
      const userDocRef = doc(db, "families", familyDocId, "users", activeUser);
      await setDoc(
        userDocRef,
        { [userFieldToUpdate]: userDataToUpdate },
        { merge: true }
      );
      console.log("User profile update completed");
      return true;
    } else {
      console.error("No family document found for the user");
      return false;
    }
  } catch (error) {
    console.error("Error updating user activity:", error);
    return false;
  }
};
//
//
// Download user identity
export const getUserIdentityDoc = async (activeUser, familyDocId) => {
  try {
    // Check if familyDocId and activeUser are provided
    if (!familyDocId || !activeUser) {
      console.error("Required parameters not provided");
      return { success: false, error: "Required parameters not provided" };
    }

    // Directly reference the user document
    const userDocRef = doc(db, "families", familyDocId, "users", activeUser);

    // Fetch the data from userDocRef and return it
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      console.log("User data fetched successfully");
      return { success: true, data: userDocSnapshot.data() };
    } else {
      console.error("No user data found");
      return { success: false, error: "No user data found" };
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return { success: false, error: error.message };
  }
};
//
//
// Delete user
export const deleteUser = async (activeUser) => {
  console.log("Attempting to delete user");
  const familiesRef = collection(db, "families");
  const querySnapshot = await getDocs(
    query(familiesRef, where("parentUID", "==", auth.currentUser.uid))
  );

  if (!querySnapshot.empty) {
    const familyDoc = querySnapshot.docs[0]; // Assuming one family doc per UID
    const familyDocId = familyDoc.id;

    // Confirm the user document exists
    const userDocRef = doc(db, "families", familyDocId, "users", activeUser);
    const userSnapshot = await getDoc(userDocRef);
    if (!userSnapshot.exists) {
      console.error("User document does not exist.");
      return false;
    }

    // Delete the user document
    await deleteDoc(userDocRef);
    console.log("User document deleted successfully.");
    return true;
  } else {
    console.error("No family document found for the user");
    return false;
  }
};
