import { Machine, assign } from "xstate";
import {
  createCarryLoan,
  createBorrowLoan
} from "../loanComponents/LoanFunctions.js";

export const reconciliationMachine = Machine(
  {
    id: "getPaid",
    initial: "getAParent",
    on: {
      RESET_RECONCILIATION_MACHINE: "getAParent"
    },
    states: {
      //Weekly Review States. All users see this
      getAParent: {
        on: { NEXT: "youEarned" }
      },
      youEarned: {
        on: { NEXT: "youOwe", BACK: "getAParent" }
      },
      youOwe: {
        on: { NEXT: "balanceReview", BACK: "youEarned" }
      },
      //
      //
      //
      // Determine what the balance is and what to do after identifying that
      balanceReview: {
        initial: "determineBalance",
        states: {
          determineBalance: {
            always: [
              {
                target: "positive",
                cond: (context, event) => context.balance === "positive"
              },
              {
                target: "equal",
                cond: (context, event) => context.balance === "equal"
              },
              {
                target: "negative",
                cond: (context, event) => context.balance === "negative"
              }
            ]
          },
          positive: {
            on: {
              BACK: "#getPaid.youOwe",
              WHY: "positiveWhyMatters",
              NEXT: "#getPaid.payChild"
            }
          },
          equal: {
            on: {
              BACK: "#getPaid.youOwe",
              NEXT: "equalWhyMatters"
            }
          },
          negative: {
            on: {
              BACK: "#getPaid.youOwe",
              NEXT: "negativeWhyMatters"
            }
          },
          positiveWhyMatters: {
            on: {
              BACK: "positive",
              NEXT: "#getPaid.payChild"
            }
          },
          equalWhyMatters: {
            on: {
              BACK: "equal",
              NEXT: "#getPaid.streakReview"
            }
          },
          negativeWhyMatters: {
            on: {
              BACK: "negative",
              NEXT: "#getPaid.payParent"
            }
          }
        }
      },
      //
      //
      //
      // Pay child
      payChild: {
        on: { BACK: "balanceReview", NEXT: "streakReview" }
      },
      //
      //
      //
      // If the user has had positive balance streak - this calcuates if they
      // have completed a streak and then subsequently delivers the congratulations
      // and gift card selection flow.
      streakReview: {
        initial: "determineStreakLevel",
        states: {
          determineStreakLevel: {
            always: [
              {
                target: "streakLevel1",
                cond: (context, event) => context.streakLvl === 1
              }
            ]
          },
          streakLevel1: {
            always: [
              {
                target: "streakLvl1Available",
                cond: (context, event) =>
                  context.streakLvl1Status === "available"
              },
              {
                target: "streakLvl1InProg",
                cond: (context, event) =>
                  context.streakLvl1Status === "inProgress"
              },
              {
                target: "streakLvl1Complete",
                cond: (context, event) =>
                  context.streakLvl1Status === "complete"
              }
            ]
          },
          streakLvl1Available: {
            on: {
              BACK: "#getPaid.balanceReview",
              NEXT: "#getPaid.completePayDayMarkComplete"
            }
          },
          streakLvl1InProg: {
            on: { BACK: "#getPaid.payChild", NEXT: "#getPaid.completePayDay" }
          },
          streakLvl1Complete: {
            on: { BACK: "#getPaid.payChild" }
          }
        }
      },
      //
      //
      completePayDayMarkComplete: {
        invoke: {
          src: (context, event) =>
            Promise.all([
              context.markExpensesAsPaid(context.activeUser),
              context.markJobsPaid(context.activeUser)
            ]),
          onDone: { target: "completePayDay" },
          onError: { target: "" }
        }
      },
      //
      //
      completePayDay: {
        entry: "invokeMarkCompleteAndExit"
      },
      //
      //
      // Pay parent in instances where there is a negative balance
      payParent: {
        initial: "determinePayBalanceLevel",
        states: {
          determinePayBalanceLevel: {
            always: [
              {
                target: "noPayBal",
                cond: (context, event) => context.payBalanceLvl === 1
              },
              {
                target: "payBal2",
                cond: (context, event) => context.payBalanceLvl === 2
              }
            ]
          },
          noPayBal: {
            on: {
              BACK: "#getPaid.balanceReview",
              NEXT: "#getPaid.streakReview"
            }
          },
          payBal2: {
            on: {
              BACK: "#getPaid.balanceReview.negativeWhyMatters",
              PAY: "#getPaid.balancePaid",
              CANTPAY: "#getPaid.cantPay"
            }
          }
        }
      },
      //
      //
      // User was able to pay their balance
      balancePaid: {
        on: { BACK: "payParent", DONE: "completePayDay" }
      },
      //
      //
      // Can't Pay. Find a way to reconcile
      cantPay: {
        initial: "determineIfBreakNeeded",
        states: {
          determineIfBreakNeeded: {
            always: [
              {
                target: "takeBreak",
                cond: (context, event) =>
                  !context.canBorrow && !context.canCarry
              },
              {
                target: "cantPayOptions2",
                cond: (context, event) => context.canBorrow || context.canCarry
              }
            ]
          },
          cantPayOptions2: {
            on: {
              BACK: "#getPaid.payParent",
              CARRY: "#getPaid.carryFlow1",
              BORROW: "#getPaid.borrowFlow1"
            }
          },
          takeBreak: {
            on: { BACK: "#getPaid.payParent", NEXT: "#getPaid.completePayDay" }
          }
        }
      },
      //
      //
      //Debt Mitigation tools
      //Carry Balance
      carryFlow1: {
        initial: "determineCarryStatus",
        states: {
          determineCarryStatus: {
            always: [
              {
                target: "noCarry",
                cond: (context, event) => context.canCarry === false
              },
              {
                target: "carry1Start",
                cond: (context, event) => context.canCarry === true
              }
            ]
          },
          noCarry: {
            on: { BACK: "#getPaid.cantPay" }
          },
          carry1Start: {
            on: {
              BACK: "#getPaid.cantPay",
              NEXT: {
                target: "createLoan",
                actions: assign({
                  newInterest: (context, event) => event.newInterest,
                  newAmount: (context, event) => event.newAmount,
                  activeUser: (context, event) => event.activeUser
                })
              }
            }
          },
          createLoan: {
            invoke: {
              id: "createCarryLoan",
              src: (context, event) =>
                createCarryLoan(
                  context.newAmount,
                  context.newInterest,
                  context.activeUser
                ),
              onDone: {
                target: "carry1Confirm", // On successful creation of the loan, go to `carry1Confirm`
                actions: (context, event) => {
                  // You can handle any successful actions here if needed
                }
              },
              onError: {
                target: "", // On error, transition to an error state (or whatever you'd like)
                actions: (context, event) => {
                  console.error(event.data); // This will log the error
                }
              }
            }
          },
          carry1Confirm: {
            entry: "setCarryAbilityToFalse",
            on: { BACK: "carry1Start", NEXT: "#getPaid.completePayDay" }
          }
        }
      },
      //
      //
      // Borrow Workflow
      borrowFlow1: {
        initial: "determineBorrowStatus",
        states: {
          determineBorrowStatus: {
            always: [
              {
                target: "noBorrow",
                cond: (context, event) => context.canBorrow === false
              },
              {
                target: "borrow1Start",
                cond: (context, event) => context.canBorrow === true
              }
            ]
          },
          noBorrow: {
            on: { BACK: "#getPaid.cantPay" }
          },
          borrow1Start: {
            on: { BACK: "#getPaid.cantPay", NEXT: "borrow1FromWho" }
          },
          borrow1FromWho: {
            on: { BACK: "borrow1Start", NEXT: "borrow1PayBack" }
          },
          borrow1PayBack: {
            on: {
              BACK: "borrow1FromWho",
              NEXT: "borrow1Confirm"
            }
          },
          borrow1Confirm: {
            entry: "setBorrowAbilityToFalse",
            on: {
              BACK: "borrow1PayBack",
              NEXT: {
                target: "createBorrowLoan",
                actions: assign({
                  newLoanLender: (context, event) => event.newLoanLender,
                  newLoanAmount: (context, event) => event.newLoanAmount,
                  newLoanInterest: (context, event) => event.newLoanInterest,
                  newLoanDueDate: (context, event) => event.newLoanDueDate,
                  newLoanDueDays: (context, event) => event.newLoanDueDays,
                  activeUser: (context, event) => event.activeUser
                })
              }
            }
          },
          createBorrowLoan: {
            invoke: {
              id: "createBorrowLoan",
              src: (context, event) =>
                createBorrowLoan(
                  context.newLoanLender,
                  context.newLoanAmount,
                  context.newLoanInterest,
                  context.newLoanDueDate,
                  context.newLoanDueDays,
                  context.activeUser
                ),
              onDone: {
                target: "borrow1Completed",
                actions: (context, event) => {
                  // additional actions can go here if needed
                }
              },
              onError: {
                target: "",
                actions: (context, event) => {
                  console.error(event.data);
                }
              }
            }
          },
          borrow1Completed: {
            entry: "setBorrowAbilityToFalse",
            on: { DONE: "#getPaid.completePayDay" }
          }
        }
      }
      //
      // Add More States Here If Needed
    }
  },
  {
    actions: {
      invokeMarkCompleteAndExit: (context, event) => {
        context.handleGetPaidExit();
      },
      setCarryAbilityToFalse: (context, event) => {
        context.setCanCarry(false);
      },
      setBorrowAbilityToFalse: (context, event) => {
        context.setCanBorrow(false);
      },
      invokeExpensesPaid: (context, event) => {
        context.markExpensesAsPaid();
      },
      invokeJobsPaid: (context, event) => {
        context.markJobsPaid();
      }
    }
  }
);
