import pigToss from "../img/PigTossNoShadow.png";
import moment from "moment";

export default function TotalEarningsFlag({
  totalEarnings,
  endOfWeek,
  currentDate,
  guideSend
}) {
  const today = moment(currentDate);
  const nextEndOfWeek = moment().day(endOfWeek); // Calculates the next endOfWeek

  let dueInDays = nextEndOfWeek.diff(today, "days");
  let buttonText = "Get Paid";

  if (dueInDays > 0) {
    buttonText = `Get paid in ${dueInDays} days`;
  } else if (dueInDays < -1) {
    // To account for +1 day after endOfWeek
    buttonText = "Get Paid";
  }

  return (
    <div className="earningsContainer">
      <div className="earningsParent">
        <div className="paydayCell">
          <div className="payDayAvatar">
            <img className="payDayIcon" src={pigToss} alt={"bagOfMoney"} />
          </div>
          <div className="payDayLaunchButton">
            <h5>It's Payday!</h5>
            <button className="buttonStyle2" onClick={() => guideSend("GO")}>
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
