//Reconcilation Modal Contents
import stopSign from "../img/stopSign.png";
import GetPaidListItem from "./GetPaidListItem.js";
import pigShadow from "../img/pigShadow.png";
import pigFly from "../img/PigFly.png";
import pigAtm from "../img/PigAtm.png";
import giftCardBanner from "../img/giftCardBanner.png";
import confirmJumpHurdle from "../img/ConfirmJumpHurdle.png";
import confirmTakeBreak from "../img/ConfirmTakeBreak.png";
import confirmSlip from "../img/ConfirmSlip.png";
import confirmPayNow from "../img/ConfirmPayNow.png";
import { useState } from "react";
//
//
//
// GET A PARENT------------------------------------------
export const GetAParent = ({ send }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="stopGetParentModal">
        <img
          className="stopGetParentAvatar"
          src={stopSign}
          alt="Robot with a stop sign"
        />
        <h2>
          Get your piggy bank and <br />a parent to start payday.
        </h2>
      </div>
      <div className="earningModalFooter">
        <button className={"button-disabled"} disabled={true}>
          Back
        </button>
        <button className="button" onClick={() => send("NEXT")}>
          Next
        </button>
      </div>
    </div>
  );
};
//
//
//
//
// REVIEW WHAT WAS EARNED------------------------------------------
export const YouEarned = ({ send, earnDollars, earnCents, filteredJobs }) => {
  return (
    <div className="earningModalBodyDefault">
      <img className="earnOwePigAvatar" src={pigShadow} alt="Piggy bank" />
      <div className="youOweEarnHeader">
        <div className="earningModalTitleText">
          <h1>This Week</h1>
          <h2>You Earned...</h2>
        </div>
        <div className="earningModalTotal ">
          <p className="youHave ">
            <span className="dollarSign2">$</span>
            <span className="dollarAmount">{earnDollars}.</span>
            <span className="centAmount">
              {earnCents.toString().padStart(2, "0")}
            </span>
          </p>
        </div>
      </div>
      <div className="div-line"></div>
      <div className="youOweEarnListItems">
        {filteredJobs.map((job) => {
          const unpaidCount = job.actionTimeStamps.filter(
            (ts) => ts.paid === false
          ).length;

          return (
            <GetPaidListItem
              key={job.createdOn}
              title={job.title}
              count={unpaidCount}
              amount={job.amount}
            />
          );
        })}
      </div>
      <div className="earningModalFooter">
        <button className={"button"} onClick={() => send("BACK")}>
          Back
        </button>
        <button className="button" onClick={() => send("NEXT")}>
          Next
        </button>
      </div>
    </div>
  );
};
//
//
//
//
// REVIEW WHAT IS OWED------------------------------------------
export const YouOwe = ({
  send,
  expenseDollars,
  expenseCents,
  filteredExpenses
}) => {
  return (
    <div className="earningModalBodyDefault">
      <img className="earnOwePigAvatar" src={pigShadow} alt="Piggy bank" />
      <div className="youOweEarnHeader">
        <div className="earningModalTitleText">
          <h1>This Week</h1>
          <h2>You Owe...</h2>
        </div>
        <div className="earningModalTotal">
          <p className="youHave">
            <span className="dollarSign">$</span>
            <span className="dollarAmount">{expenseDollars}.</span>
            <span className="centAmount">
              {expenseCents.toString().padStart(2, "0")}
            </span>
          </p>
        </div>
      </div>
      <div className="div-line"></div>
      <div className="youOweEarnListItems">
        {filteredExpenses.map((expense) => {
          return (
            <GetPaidListItem
              key={expense.createdOn}
              title={expense.title}
              amount={expense.amount}
              count={null}
            />
          );
        })}
      </div>
      <div className="earningModalFooter">
        <button className={"button"} onClick={() => send("BACK")}>
          Back
        </button>
        <button className="button" onClick={() => send("NEXT")}>
          Next
        </button>
      </div>
    </div>
  );
};
//
//
//
// BALANCE REVIEW -------------------------------------------------
export const PositiveBal = ({ send, summaryDollars, summaryCents }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalSpeechBubbleTwoColumn">
        <h2>
          This week, you earned{" "}
          <strong>
            ${summaryDollars}.{summaryCents}
          </strong>{" "}
          more <br /> than you spent. <strong>Great Job!</strong>
        </h2>
      </div>
      <div className="earningModalWhyMattersCont">
        <button className={"borderless"} onClick={() => send("WHY")}>
          Why does this matter?
        </button>
      </div>
      <img className="summaryPigAvatar" src={pigShadow} alt="Piggy bank" />
      <div className="earningModalFooter">
        <button className={"button"} onClick={() => send("BACK")}>
          Back
        </button>
        <button className="button" onClick={() => send("NEXT")}>
          Next
        </button>
      </div>
    </div>
  );
};
//

export const EqualBal = ({ send }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalSpeechBubble">
        <h2>
          <strong>This week you broke even.</strong>
        </h2>
        <p>
          Although you don’t owe money, <br />
          you didn’t earn any either.
        </p>
      </div>
      <div className="earningModalWhyMattersCont"></div>
      <img className="summaryPigAvatar" src={pigShadow} alt="Piggy bank" />

      <div className="earningModalFooter">
        <button className={"button"} onClick={() => send("BACK")}>
          Back
        </button>
        <button className="button" onClick={() => send("NEXT")}>
          Next
        </button>
      </div>
    </div>
  );
};
//
export const NegativeBal = ({ send, summaryDollars, summaryCents }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalSpeechBubbleTwoColumn">
        <h2>
          This week, you spent{" "}
          <strong>
            ${summaryDollars}.{summaryCents.toString().padStart(2, "0")}
          </strong>{" "}
          more <br /> than you earned.
        </h2>
      </div>
      <div className="earningModalWhyMattersCont"></div>
      <img className="summaryPigAvatar" src={pigShadow} alt="Piggy bank" />

      <div className="earningModalFooter">
        <button className={"button"} onClick={() => send("BACK")}>
          Back
        </button>
        <button className="button" onClick={() => send("NEXT")}>
          Next
        </button>
      </div>
    </div>
  );
};
//
export const PositiveWhyMatters = ({ send }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalTitleText">
        <h1>Test</h1>
        <h2>Positive Balance. Why This Matters</h2>
      </div>
      <div className="earningModalTotal">
        <p>some shit here</p>
      </div>
      <div className="earningModalFooter">
        <button className={"button"} onClick={() => send("BACK")}>
          Back
        </button>
        <button className="button" onClick={() => send("NEXT")}>
          Next
        </button>
      </div>
    </div>
  );
};
//
export const EqualWhyMatters = ({ send }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalSpeechBubble">
        <h2>
          <strong>Let's get you earning...</strong>
        </h2>
        <p>
          With extra cash, you'll be able to tackle new
          <br /> problems and put your money to work for you.
        </p>
      </div>
      <div className="earningModalWhyMattersCont"></div>
      <img className="summaryPigAvatar" src={pigShadow} alt="Piggy bank" />

      <div className="earningModalFooter">
        <button className={"button"} onClick={() => send("BACK")}>
          Back
        </button>
        <button className="button" onClick={() => send("NEXT")}>
          Next
        </button>
      </div>
    </div>
  );
};
//
export const NegativeWhyMatters = ({ send }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalSpeechBubble">
        <h2>
          <strong>Sometimes it's okay...</strong>
        </h2>
        <p>
          to spend a bit more than you earn. But doing it a lot <br />
          means you might use all of the money you’ve saved.
        </p>
      </div>
      <div className="earningModalWhyMattersCont"></div>
      <img className="summaryPigAvatar" src={pigShadow} alt="Piggy bank" />

      <div className="earningModalFooter">
        <button className={"button"} onClick={() => send("BACK")}>
          Back
        </button>
        <button className="button" onClick={() => send("NEXT")}>
          Next
        </button>
      </div>
    </div>
  );
};
//
//
//
// PAYMENT
export const PayChild = ({ send, summaryDollars, summaryCents }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalSpeechBubble">
        <h2>
          Parents, please <br />
          pay your child:
        </h2>
        <h3>
          <strong>
            ${summaryDollars}.{summaryCents}
          </strong>
        </h3>
      </div>
      <div className="earningModalWhyMattersCont">
        <button className={"borderless"} onClick={() => send("WHY")}></button>
      </div>
      <img className="summaryPigAvatar" src={pigShadow} alt="Piggy bank" />
      <div className="earningModalFooter">
        <button className={"button"} onClick={() => send("BACK")}>
          Back
        </button>
        <button className="button" onClick={() => send("NEXT")}>
          Ok
        </button>
      </div>
    </div>
  );
};
//
//
// STREAK MATCHING -------------------------------------------------
export const StreakLvl1Available = ({ send }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalTitleText">
        <h4>Earn more than you spend and...</h4>
        <h2>Get a $5 Gift Card</h2>
      </div>
      <div className="earningModalContents">
        <img className="" src={giftCardBanner} alt="A row of gift cards" />
        <p>
          If you earn more than you spend two weeks in a row, you’ll earn a $5
          gift card of your choice.
        </p>
      </div>
      <div className="earningModalFooter">
        <button className={"button"} onClick={() => send("BACK")}>
          Back
        </button>
        <button className="button" onClick={() => send("NEXT")}>
          Ok
        </button>
      </div>
    </div>
  );
};
//
export const StreakLvl1InProgress = ({ send }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalTitleText">
        <h1>Streak level 1: InProg</h1>
        <h2>You Are On A Streak!! One More Week</h2>
      </div>
      <div className="earningModalTotal">
        <p></p>
      </div>
      <div className="earningModalFooter">
        <button className={"button"} onClick={() => send("BACK")}>
          Back
        </button>
        <button className="button" onClick={() => send("NEXT")}>
          Next
        </button>
      </div>
    </div>
  );
};
//
export const StreakLvl1Complete = ({ send }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalTitleText">
        <h1>Streak level 1: complete</h1>
        <h2>You did it! You Earned a gift card</h2>
      </div>
      <div className="earningModalTotal">
        <p></p>
      </div>
      <div className="earningModalFooter">
        <button className={"button"} onClick={() => send("BACK")}>
          Back
        </button>
        <button className="button" onClick={() => send("NEXT")}>
          Next
        </button>
      </div>
    </div>
  );
};
//
//
// Pay Parent --------------------------------------------------------
export const NoPayBalance = ({ send }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalSpeechBubble">
        <h2>
          <strong>This week, your debt is forgiven</strong>
        </h2>
        <p>
          In the future, you might have to pay your <br /> parents if you spend
          more than you earn.
        </p>
      </div>
      <div className="earningModalWhyMattersCont"></div>
      <img className="summaryPigAvatar" src={pigShadow} alt="Piggy bank" />

      <div className="earningModalFooter">
        <button className={"button"} onClick={() => send("BACK")}>
          Back
        </button>
        <button className="button" onClick={() => send("NEXT")}>
          Next
        </button>
      </div>
    </div>
  );
};
//
export const PayBalance = ({
  send,
  summaryDollars,
  summaryCents,
  earnDollars,
  earnCents,
  expenseDollars,
  expenseCents
}) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalConfirmationContent">
        <img
          className="earningModalCinformationAvatar"
          src={confirmPayNow}
          alt="Piggy bank Jumping a hurdle"
        />
        <div className="earningModalPayNowHeader">
          <div className="earningModalTitleText">
            <h2>Please Pay:</h2>
          </div>
          <div className="">
            <p className="youHave ">
              <span className="dollarSign2">$</span>
              <span className="dollarAmount">{summaryDollars}.</span>
              <span className="centAmount">
                {summaryCents.toString().padStart(2, "0")}
              </span>
            </p>
          </div>
        </div>
        <div className="earningModalCantPayContents">
          <p>
            You earned{" "}
            <strong>
              ${earnDollars}.{earnCents.toString().padStart(2, "0")}
            </strong>{" "}
            but owe{" "}
            <strong>
              ${expenseDollars}.{expenseCents.toString().padStart(2, "0")}
            </strong>
            , which means <br />
            you must pay your parents the difference. <br /> Select an option
            below to move forward.
          </p>
        </div>
      </div>
      <div className="earningModalcenterFooter">
        <button className={"buttonStyle4"} onClick={() => send("PAY")}>
          Pay Parents
        </button>
        <button className={"buttonStyle4"} onClick={() => send("CANTPAY")}>
          I don't have the money
        </button>
        <button className={"buttonStyle5"} onClick={() => send("BACK")}>
          Back
        </button>
      </div>
    </div>
  );
};
//
//
export const BalancePaid = ({ send }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalConfirmationContent">
        <img
          className="earningModalCinformationAvatar"
          src={confirmJumpHurdle}
          alt="Piggy bank Jumping a hurdle"
        />
        <h2>You Paid Your Debt!</h2>
        <p>
          By using money you had set aside, you've demonstrated that you are
          prepared for unexpected expenses.
        </p>
      </div>
      <div className="earningModalcenterFooter">
        <button className={"buttonStyle4"} onClick={() => send("DONE")}>
          Done
        </button>
        <button className={"buttonStyle5"} onClick={() => send("BACK")}>
          Back
        </button>
      </div>
    </div>
  );
};
//
//
//
// CANT PAY -------------------------------------------------
export const CantPayOptions2 = ({ send }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalConfirmationContent">
        <img
          className="earningModalCinformationAvatar"
          src={confirmSlip}
          alt="Piggy bank Jumping a hurdle"
        />
        <h2>Dont Have The Money?</h2>
        <p>
          Here are tools to help bridge the gap.
          <br />
          Tap one to see if it is right for you.
        </p>
      </div>
      <div className="earningModalcenterFooter">
        <button className={"buttonStyle4"} onClick={() => send("CARRY")}>
          Pay It Next Week
        </button>
        <button className={"buttonStyle4"} onClick={() => send("BORROW")}>
          Borrow From Someone
        </button>
        <button className={"buttonStyle5"} onClick={() => send("BACK")}>
          Back
        </button>
      </div>
    </div>
  );
};
//
export const TakeBreak = ({ send }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalConfirmationContent">
        <img
          className="earningModalCinformationAvatar"
          src={confirmTakeBreak}
          alt="Life Raft"
        />
        <h2>Time For A Break</h2>
        <p>
          Parents, your child has had a negative balance two weeks in a row.
          This might be a sign that their jobs pay too little or they have too
          many expenses. This week we will skip collecting any money owed to
          give your child a chance to build up funds.
        </p>
      </div>
      <div className="earningModalcenterFooter">
        <button className={"buttonStyle4"} onClick={() => send("NEXT")}>
          Done
        </button>
        <button className={"buttonStyle5"} onClick={() => send("BACK")}>
          Back
        </button>
      </div>
    </div>
  );
};
//
//
//
// CARRY FLOW - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const NoCarry = ({ send }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalTitleText">
        <h1>Sorry...</h1>
        <h2>You're already carrying a blance.</h2>
        <p>You can't carry more until your current debt is paid off.</p>
      </div>
      <div className="earningModalTotal"></div>
      <div className="earningModalFooter">
        <button className={"button"} onClick={() => send("BACK")}>
          Back
        </button>
      </div>
    </div>
  );
};
//
export const Carry1Start = ({
  send,
  summaryBalance,
  summaryDollars,
  summaryCents,
  activeUser
}) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="borrowWorkflowTitle">
        <h2>Pay It Next Week?</h2>
      </div>
      <div className="borrowColumnContainer">
        <div className="borrowColumn-1">
          <img className="borrowImages" src={pigFly} alt="Pig Flying" />
        </div>
        <div className="borrowColumn-2">
          <h4>How it works:</h4>
          <p>
            Occasionally, the people or companies will let you pay money you owe
            at a later date with no penatly. This is called ‘carrying a
            balance.’ You just have to ask them if it is OK.
          </p>
          <p>
            Ask your parent if it is OK for you to pay <br />
            the{" "}
            <strong>
              ${summaryDollars}.{summaryCents.toString().padStart(2, "0")}{" "}
            </strong>{" "}
            you owe next week.{" "}
          </p>
          <div className="borrowControlsStack">
            <button
              className={"buttonStyle4"}
              onClick={() =>
                send("NEXT", {
                  newInterest: 0,
                  newAmount: summaryBalance,
                  activeUser: activeUser
                })
              }
            >
              Pay It Next Week
            </button>
            <button className={"buttonStyle5"} onClick={() => send("BACK")}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
//
export const Carry1Confirm = ({ send, summaryDollars, summaryCents }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalConfirmationContent">
        <img
          className="earningModalCinformationAvatar"
          src={confirmJumpHurdle}
          alt="Piggy bank Jumping a hurdle"
        />
        <h2>You Delayed Payment!</h2>
        <p>
          By carrying the your balance of ${summaryDollars}.
          {summaryCents.toString().padStart(2, "0")} to next week, you've
          demonstrated that you are a good negotiator. Your carried balance will
          now appear on your dashboard until you pay it off.
        </p>
      </div>
      <div className="earningModalcenterFooter">
        <button className={"buttonStyle4"} onClick={() => send("NEXT")}>
          Done
        </button>
      </div>
    </div>
  );
};
//
//
//
// Borrow Workflow ------ - - - - - - - - - - - - - - - - - -
export const NoBorrow = ({ send }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalTitleText">
        <h1>Sorry...</h1>
        <h2>You've already borrowed.</h2>
        <p>You can't borrow again until your current debt is paid off.</p>
      </div>
      <div className="earningModalTotal"></div>
      <div className="earningModalFooter">
        <button className={"button"} onClick={() => send("BACK")}>
          Back
        </button>
      </div>
    </div>
  );
};
//
export const Borrow1Start = ({ send, summaryDollars, summaryCents }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="borrowWorkflowTitle">
        <h2>Borrow The Money?</h2>
      </div>
      <div className="borrowColumnContainer">
        <div className="borrowColumn-1">
          <img className="borrowImages" src={pigAtm} alt="Pig Flying" />
        </div>
        <div className="borrowColumn-2">
          <h4>How it works:</h4>
          <p>
            Ask a friend or family member to borrow the{" "}
            <strong>
              ${summaryDollars}.{summaryCents.toString().padStart(2, "0")}{" "}
            </strong>{" "}
            needed to pay off what you owe. <br />
            <br />
            Together, agree on:
            <ul>
              <li>how much you can borrow</li>
              <li>when you have to pay it back</li>
            </ul>
          </p>
          <div className="borrowControlsStack">
            <button className={"buttonStyle4"} onClick={() => send("NEXT")}>
              Borrow The Money
            </button>
            <button className={"buttonStyle5"} onClick={() => send("BACK")}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
//
export const Borrow1FromWho = ({
  send,
  summaryDollars,
  summaryCents,
  setNewLoanLender
}) => {
  const handleInputChange = (e) => {
    setNewLoanLender(e.target.value.replace(/[^a-zA-Z]/g, ""));
  };
  return (
    <div className="earningModalBodyDefault">
      <div className="borrowWorkflowTitle">
        <h2>
          Borrow ${summaryDollars}.{summaryCents.toString().padStart(2, "0")}
        </h2>
      </div>
      <div className="borrowColumnContainer">
        <div className="borrowColumn-1">
          <img className="borrowImages" src={pigAtm} alt="Pig Flying" />
        </div>
        <div className="borrowColumn-2">
          <h4>Who will you borrow from?</h4>
          <input
            placeholder="Enter A Name" // Bind the inputValue state to the input's value
            onChange={handleInputChange} // Handle input change to restrict characters
            className="loanInputField"
          />
          <div className="borrowControlsStack">
            <button className={"buttonStyle4"} onClick={() => send("NEXT")}>
              Next
            </button>
            <button className={"buttonStyle5"} onClick={() => send("BACK")}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
//
//
//
export const Borrow1PayBack = ({
  send,
  summaryDollars,
  summaryCents,
  newLoanLender,
  newLoanDueDays,
  setNewLoanDueDays
}) => {
  const increment = () => {
    setNewLoanDueDays(newLoanDueDays + 1);
  };
  const decrement = () => {
    if (newLoanDueDays > 2) {
      // optional check to prevent negative values
      setNewLoanDueDays(newLoanDueDays - 1);
    }
  };

  return (
    <div className="earningModalBodyDefault">
      <div className="borrowWorkflowTitle">
        <h2>
          Borrow ${summaryDollars}.{summaryCents.toString().padStart(2, "0")}{" "}
          From {newLoanLender}
        </h2>
      </div>
      <div className="borrowColumnContainer">
        <div className="borrowColumn-1">
          <img className="borrowImages" src={pigAtm} alt="Pig Flying" />
        </div>
        <div className="borrowColumn-2">
          <h4>When can you pay them back?</h4>
          <div className="loanDaySelectorContainer">
            <button className="button" onClick={decrement}>
              -
            </button>
            <h2>{newLoanDueDays} Days</h2>
            <button className="button" onClick={increment}>
              +
            </button>
          </div>
          <div className="borrowControlsStack">
            <button className={"buttonStyle4"} onClick={() => send("NEXT")}>
              Next
            </button>
            <button className={"buttonStyle5"} onClick={() => send("BACK")}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
//
export const Borrow1Confirm = ({
  send,
  summaryBalance,
  summaryDollars,
  summaryCents,
  newLoanLender,
  newLoanDueDate,
  newLoanDueDays,
  newLoanInterest,
  activeUser
}) => {
  console.log(
    "Lender is: ",
    newLoanLender,
    " ",
    "Due days: ",
    newLoanDueDays,
    " ",
    "loan amount is: ",
    summaryBalance
  );
  return (
    <div className="earningModalBodyDefault">
      <div className="borrowWorkflowTitle">
        <h2>Borrow Confirmation</h2>
      </div>
      <div className="borrowColumnContainer">
        <div className="borrowColumn-1">
          <img className="borrowImages" src={pigAtm} alt="Pig Flying" />
        </div>
        <div className="borrowColumn-2">
          <h4>Does this look right?</h4>
          <div className="loanReceipt">
            <p>
              {" "}
              I will borrow{" "}
              <span>
                ${summaryDollars}.{summaryCents.toString().padStart(2, "0")}
              </span>{" "}
              from <span>{newLoanLender}.</span> I agree to pay it back in{" "}
              <span>{newLoanDueDays}</span> days.
            </p>
          </div>
          <div className="borrowControlsStack">
            <button
              className={"buttonStyle4"}
              onClick={() =>
                send("NEXT", {
                  newLoanLender: newLoanLender,
                  newLoanAmount: summaryBalance,
                  newLoanInterest: newLoanInterest,
                  newLoanDueDate: newLoanDueDate,
                  newLoanDueDays: newLoanDueDays,
                  activeUser: activeUser
                })
              }
            >
              Borrow Money
            </button>
            <button className={"buttonStyle5"} onClick={() => send("BACK")}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
//
//
//
export const Borrow1Completed = ({ send, summaryDollars, summaryCents }) => {
  return (
    <div className="earningModalBodyDefault">
      <div className="earningModalConfirmationContent">
        <img
          className="earningModalCinformationAvatar"
          src={confirmJumpHurdle}
          alt="Piggy bank Jumping a hurdle"
        />
        <h2>
          You Borrowed ${summaryDollars}.
          {summaryCents.toString().padStart(2, "0")}
        </h2>
        <p>
          By borrowing money to pay what you owe you’ve demonstrated that you
          have friends and family that trust you with money. Just make sure you
          pay them back on time.
        </p>
      </div>
      <div className="earningModalcenterFooter">
        <button className={"buttonStyle4"} onClick={() => send("DONE")}>
          Done
        </button>
      </div>
    </div>
  );
};
//
//
