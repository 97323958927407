//JobsAndExpenseLevel2.js

import { useState } from "react";
import levelUp from "../img/LevelUnlock.png";
import workIt from "../img/JobsAndExpenseLevelUp.png";
import { NewJobCard } from "./NewJobCard.js";
import { jobs } from "../jobComponents/Jobs.js";
import { createJob } from "../jobComponents/JobsFunctions.js";
import { expenses } from "../expenseComponents/Expenses.js";
import { createExpense } from "../expenseComponents/ExpenseFunctions.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRandom } from "@fortawesome/free-solid-svg-icons";

export function JobsAndExpenseLevel2Flag({ guideSend }) {
  return (
    <div className=" earningsContainer">
      <div className="levelFlagParent">
        <img
          className="levelFlagAvatar2"
          src={levelUp}
          alt="Rocket Ship Taking Off"
        />
        <div className="darkCellBG">
          <div className="levelSummary">
            <h1>
              You've unlocked <br />a new level!
            </h1>
          </div>
          <div className="earningSummaryControl">
            <button className="buttonStyle2" onClick={() => guideSend("GO")}>
              Show Me
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function JobsAndExpenseLevel2Guide({
  guideSend,
  activeUser,
  refreshJobs,
  getExpenseList
}) {
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectedExpenses, setSelectedExpenses] = useState([]);
  const [flowPosition, setFlowPosition] = useState(1);
  const [displayedJobs, setDisplayedJobs] = useState(
    jobs.filter((job) => job.id !== 11).slice(0, 3)
  );
  const [displayedExpenses, setDisplayedExpenses] = useState(
    expenses.filter((expense) => expense.id !== 11).slice(0, 3)
  );
  //
  // Cycle Job view controsl here
  const shuffleJobs = () => {
    let remainingJobs = jobs.filter(
      (job) =>
        !displayedJobs.includes(job) &&
        !selectedJobs.includes(job.id) &&
        job.id !== 11
    );
    let newJobs = [
      ...displayedJobs.filter((job) => selectedJobs.includes(job.id))
    ];
    while (newJobs.length < 3 && remainingJobs.length > 0) {
      const randomIndex = Math.floor(Math.random() * remainingJobs.length);
      newJobs.push(remainingJobs[randomIndex]);
      remainingJobs = remainingJobs.filter((_, index) => index !== randomIndex);
    }
    setDisplayedJobs(newJobs);
  };
  //
  //
  const toggleJobSelection = (index) => {
    if (selectedJobs.includes(index)) {
      // If already selected, remove from the array
      setSelectedJobs((prev) => prev.filter((jobIndex) => jobIndex !== index));
    } else {
      // If not selected, add to array if less than 2 jobs are selected
      if (selectedJobs.length < 2) {
        setSelectedJobs((prev) => [...prev, index]);
      }
    }
  };
  //
  // Cycle Expense view controsl here
  const shuffleExpenses = () => {
    let remainingExpenses = expenses.filter(
      (expense) =>
        !displayedExpenses.includes(expense) &&
        !selectedExpenses.includes(expense.id) &&
        expense.id !== 11
    );
    let newExpenses = [
      ...displayedExpenses.filter((expense) =>
        selectedExpenses.includes(expense.id)
      )
    ];
    while (newExpenses.length < 3 && remainingExpenses.length > 0) {
      const randomIndex = Math.floor(Math.random() * remainingExpenses.length);
      newExpenses.push(remainingExpenses[randomIndex]);
      remainingExpenses = remainingExpenses.filter(
        (_, index) => index !== randomIndex
      );
    }
    setDisplayedExpenses(newExpenses);
  };
  //
  //
  const toggleExpenseSelection = (index) => {
    if (selectedExpenses.includes(index)) {
      // If already selected, remove from the array
      setSelectedExpenses((prev) =>
        prev.filter((expenseIndex) => expenseIndex !== index)
      );
    } else {
      // If not selected, add to array if less than 2 jobs are selected
      if (selectedExpenses.length < 2) {
        setSelectedExpenses((prev) => [...prev, index]);
      }
    }
  };
  //
  //
  // Commit selected Jobs to the server
  const handleClose = async () => {
    // Commit Jobs to the server
    for (let jobId of selectedJobs) {
      const job = jobs.find((j) => j.id === jobId);
      if (job) {
        const success = await createJob(
          job.title,
          job.frequency,
          job.timeSpanHours,
          job.amount,
          activeUser
        );
        if (!success) {
          console.error("Failed to save job:", job.title);
        }
      }
    }

    // Commit Expenses to the server (assuming you have a similar function called createExpense)
    for (let expenseId of selectedExpenses) {
      const expense = expenses.find((e) => e.id === expenseId);
      if (expense) {
        const success = await createExpense(
          expense.title,
          expense.frequency,
          expense.timeSpanHours,
          expense.amount,
          activeUser
        );
        if (!success) {
          console.error("Failed to save expense:", expense.title);
        }
      }
    }

    refreshJobs();
    getExpenseList();
    guideSend("DONE");
  };

  //
  // Increment position
  const incrementStatus = () => {
    if (flowPosition < 7) {
      setFlowPosition((prevStatus) => prevStatus + 1);
    } else {
      //do nothing
    }
  };
  const decrementStatus = () => {
    if (flowPosition > 0) {
      setFlowPosition((prevStatus) => prevStatus - 1);
    } else {
      //do nothing
    }
  };

  const handleExit = () => {
    guideSend("CLOSE");
  };
  //
  // Cycle Workflow Contents
  let pageContent;
  switch (flowPosition) {
    case 1:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <h1></h1>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="modalActionContents2">
            <div className="ftueColumn-1">
              <img
                className="FTUEimages"
                src={workIt}
                alt="Pig running on a treadmill"
              />
            </div>
            <div className="ftueColumn-2">
              <div className="ftueContentsCent">
                <h1>You've Unlocked</h1>
                <h2>Jobs & Expenses</h2>
                <h4>Level 2</h4>
                <p>
                  This new level will require your child to work a little harder
                  to achieve a positive weekly balance. It includes 2 new jobs
                  and 2 new expenses.
                </p>
              </div>
              <div className="modalFooterControlSingle">
                <button className="button" onClick={incrementStatus}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case 2:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <div>
              <h1>Select Two Additional Ways To Earn</h1>
              <h6>You can customize these later</h6>
            </div>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="cardShuffleBody">
            <div className="cardShuffleContainer">
              {displayedJobs.map((job) => (
                <NewJobCard
                  key={job.id}
                  job={job}
                  isSelected={selectedJobs.includes(job.id)}
                  onToggle={() => toggleJobSelection(job.id)}
                />
              ))}
              <div className="centerShuffleButton ">
                <button className="shuffleButton" onClick={shuffleJobs}>
                  <FontAwesomeIcon className="fa-2x" icon={faRandom} />
                  <span>shuffle</span>
                </button>
              </div>
            </div>
            <div className="modalFooterControls2">
              <button className="button" onClick={decrementStatus}>
                Back
              </button>
              <button className="button" onClick={incrementStatus}>
                Next
              </button>
            </div>
          </div>
        </>
      );
      break;
    case 3:
      pageContent = (
        <>
          <div className="modalTitleControl">
            <div>
              <h1>Select Two Additional Expenses</h1>
              <h6>You can customize these later</h6>
            </div>
            <button className="buttonStyle3" onClick={handleExit}>
              X
            </button>
          </div>
          <div className="cardShuffleBody">
            <div className="cardShuffleContainer">
              {displayedExpenses.map((expense) => (
                <NewJobCard
                  key={expense.id}
                  job={expense}
                  isSelected={selectedExpenses.includes(expense.id)}
                  onToggle={() => toggleExpenseSelection(expense.id)}
                />
              ))}
              <div className="centerShuffleButton ">
                <button className="shuffleButton" onClick={shuffleExpenses}>
                  <FontAwesomeIcon className="fa-2x" icon={faRandom} />
                  <span>shuffle</span>
                </button>
              </div>
            </div>
            <div className="modalFooterControls2">
              <button className="button" onClick={decrementStatus}>
                Back
              </button>
              <button className="button" onClick={handleClose}>
                Next
              </button>
            </div>
          </div>
        </>
      );
      break;
    default:
  }
  return (
    <div className="actModalOverlay">
      <div className="jobPickerSlotmachine">{pageContent}</div>
    </div>
  );
}
