// Avatars
import avatar1 from "./avatar/50 Monsters Avatar Icons_1.png";
import avatar2 from "./avatar/50 Monsters Avatar Icons_2.png";
import avatar3 from "./avatar/50 Monsters Avatar Icons_3.png";
import avatar4 from "./avatar/50 Monsters Avatar Icons_4.png";
import avatar5 from "./avatar/50 Monsters Avatar Icons_5.png";
import avatar6 from "./avatar/50 Monsters Avatar Icons_6.png";
import avatar7 from "./avatar/50 Monsters Avatar Icons_7.png";
import avatar8 from "./avatar/50 Monsters Avatar Icons_8.png";
import avatar9 from "./avatar/50 Monsters Avatar Icons_9.png";
import avatar10 from "./avatar/50 Monsters Avatar Icons_10.png";
import avatar11 from "./avatar/50 Monsters Avatar Icons_11.png";
import avatar12 from "./avatar/50 Monsters Avatar Icons_12.png";
import avatar13 from "./avatar/50 Monsters Avatar Icons_13.png";
import avatar14 from "./avatar/50 Monsters Avatar Icons_14.png";
import avatar15 from "./avatar/50 Monsters Avatar Icons_15.png";
import avatar16 from "./avatar/50 Monsters Avatar Icons_16.png";
import avatar17 from "./avatar/50 Monsters Avatar Icons_17.png";
import avatar18 from "./avatar/50 Monsters Avatar Icons_18.png";
import avatar19 from "./avatar/50 Monsters Avatar Icons_19.png";
import avatar20 from "./avatar/50 Monsters Avatar Icons_20.png";
import avatar21 from "./avatar/50 Monsters Avatar Icons_21.png";
import avatar22 from "./avatar/50 Monsters Avatar Icons_22.png";
import avatar23 from "./avatar/50 Monsters Avatar Icons_23.png";
import avatar24 from "./avatar/50 Monsters Avatar Icons_24.png";

export const avatars = [
  { id: 1, src: avatar1, alt: "Character Description" },
  { id: 2, src: avatar2, alt: "Character Description" },
  { id: 3, src: avatar3, alt: "Character Description" },
  { id: 4, src: avatar4, alt: "Character Description" },
  { id: 5, src: avatar5, alt: "Character Description" },
  { id: 6, src: avatar6, alt: "Character Description" },
  { id: 7, src: avatar7, alt: "Character Description" },
  { id: 8, src: avatar8, alt: "Character Description" },
  { id: 9, src: avatar9, alt: "Character Description" },
  { id: 10, src: avatar10, alt: "Character Description" },
  { id: 11, src: avatar11, alt: "Character Description" },
  { id: 12, src: avatar12, alt: "Character Description" },
  { id: 13, src: avatar13, alt: "Character Description" },
  { id: 14, src: avatar14, alt: "Character Description" },
  { id: 15, src: avatar15, alt: "Character Description" },
  { id: 16, src: avatar16, alt: "Character Description" },
  { id: 17, src: avatar17, alt: "Character Description" },
  { id: 18, src: avatar18, alt: "Character Description" },
  { id: 19, src: avatar19, alt: "Character Description" },
  { id: 20, src: avatar20, alt: "Character Description" },
  { id: 21, src: avatar21, alt: "Character Description" },
  { id: 22, src: avatar22, alt: "Character Description" },
  { id: 23, src: avatar23, alt: "Character Description" },
  { id: 24, src: avatar24, alt: "Character Description" }
];
