//IntroFTUE
import StickyFooter from "./StickyFooter.js";
import calculator2 from "./img/calculator2.png";
import CountMoney from "./CountMoney.js";
import MoneyInPiggyBank from "./MoneyInPiggyBank.js";
import UserInfo from "./UserInfo.js";
import React, { useState } from "react";
import ChooseAvatar from "./ChooseAvatar.js";
import { avatars } from "./Avatars.js";
import { addAccount } from "./bankAccounts/AccountFunctions.js";
import { updateAvatar, updateName } from "./identity/IdentityFunctions.js";

export default function IntroFTUE({
  selectedAvatar,
  setSelectedAvatar,
  curBalance,
  activeUser,
  startupSend,
  setUsername
}) {
  const [isIntroStatus, setIntroStatus] = useState(1);
  const [showFullList, setShowFullList] = useState(false);
  const [newUserName, setNewUsername] = useState(null);
  const [enteredAmount, setEnteredAmount] = useState("0");
  //
  //
  //
  ///Incrementer for setup states
  const incrementStatus = () => {
    if (isIntroStatus < 10) {
      setIntroStatus((prevStatus) => prevStatus + 1);
    } else {
      //do nothing
    }
  };
  const decrementStatus = () => {
    if (isIntroStatus > 0) {
      setIntroStatus((prevStatus) => prevStatus - 1);
    } else {
      //do nothing
    }
  };
  //
  //
  // Other Page Controls
  const handleToggleList = () => {
    setShowFullList(!showFullList);
  };
  //
  //
  const handleInitialAccount = async () => {
    const [dollars, cents = "00"] = enteredAmount.split(".");
    const totalCents = parseInt(dollars, 10) * 100 + parseInt(cents, 10);
    const success = await addAccount(
      "Piggy Bank",
      totalCents,
      selectedAvatar,
      activeUser
    );
    if (success) {
      startupSend("COMPLETE");
    } else {
      // Handle the case when addAccount returns false, e.g., show an error message
    }
  };
  //
  //
  const handleUpdateName = async () => {
    const success = await updateName(newUserName);
    if (success) {
      incrementStatus();
      setUsername(newUserName);
    } else {
      //Handle error case here
    }
  };
  //
  //
  const handleUpdateAvatar = async () => {
    const success = await updateAvatar(selectedAvatar);
    if (success) {
      incrementStatus();
    } else {
      // Handle error case here
    }
  };

  let pageContent;
  switch (isIntroStatus) {
    case 1:
      //
      //Introduction to parents
      pageContent = (
        <div className="horizontalContainer">
          <div className="ftueOverview">
            <div className="ftueColumn-1">
              <img className="ftueImage" src={calculator2} alt="calculator" />
            </div>
            <div className="ftueColumn-2">
              <p>
                <h1>Welcome to the Mini-mogul prototype!</h1>
              </p>
              <h2>
                Money never sits still. We are always balancing what we earn
                with what we spend.
              </h2>
            </div>
          </div>
          <StickyFooter
            onClickIncrement={incrementStatus}
            incrementText="next"
            decrementText="back"
          />
        </div>
      );
      break;
    case 2:
      //
      //Introduction to parents
      pageContent = (
        <div className="horizontalContainer">
          <div className="ftueOverview">
            <div className="ftueColumn-1">
              <img className="ftueImage" src={calculator2} alt="calculator" />
            </div>
            <div className="ftueColumn-2">
              <p>
                <h1>Overview</h1>
              </p>
              <h2>
                Unfortunately, most financial education focuses on the
                vocabulary of finance, rather than letting children experiment
                with real money over time.
              </h2>
            </div>
          </div>
          <StickyFooter
            onClickIncrement={incrementStatus}
            incrementText="next"
            onClickDecrement={decrementStatus}
            decrementText="back"
          />
        </div>
      );
      break;
    case 3:
      //
      //Introduction to parents
      pageContent = (
        <div className="horizontalContainer">
          <div className="ftueOverview">
            <div className="ftueColumn-1">
              <img className="ftueImage" src={calculator2} alt="calculator" />
            </div>
            <div className="ftueColumn-2">
              <p>
                <h1>Overview</h1>
              </p>
              <h2>
                This app uses a hands-on approach to help children learn the
                basics of finance. Your child will learn things like:
              </h2>
              <ul>
                <li>Balancing what they earn and spend.</li>
                <li>Discovering how to ‘put money to work’.</li>
                {showFullList && (
                  <>
                    <li>Forecasting expected & unexpected expenses.</li>
                    <li>Identifying different ways to earn money.</li>
                    <li>
                      Recognizing and participating in the costs of every day
                      life (e.g. utilities, entertainment in the home, etc..).
                    </li>
                    <li>Personal accountability.</li>
                  </>
                )}
              </ul>
              <button className="framelessButton" onClick={handleToggleList}>
                {showFullList ? "Show Less" : "Show More"}
              </button>
            </div>
          </div>
          <StickyFooter
            onClickIncrement={incrementStatus}
            incrementText="next"
            onClickDecrement={decrementStatus}
            decrementText="back"
          />
        </div>
      );
      break;
    case 4:
      //
      //Introduction to parents
      pageContent = (
        <div className="horizontalContainer">
          <div className="ftueOverview">
            <div className="ftueColumn-1">
              <img className="ftueImage" src={calculator2} alt="calculator" />
            </div>
            <div className="ftueColumn-2">
              <p>
                <h1>Overview</h1>
              </p>
              <h2>
                The app is designed to be used by parents and children together,
                providing prompts for engagement, storytelling, and reflection
                on what you want your children to know from your own experience.
              </h2>
            </div>
          </div>
          <StickyFooter
            onClickIncrement={incrementStatus}
            incrementText="next"
            onClickDecrement={decrementStatus}
            decrementText="back"
          />
        </div>
      );
      break;
    case 5:
      //
      // Enter First Name
      pageContent = (
        <div className="horizontalContainer">
          <UserInfo setNewUsername={setNewUsername} />
          <StickyFooter
            onClickIncrement={handleUpdateName}
            incrementText="next"
            onClickDecrement={decrementStatus}
            decrementText="back"
            newUserName={newUserName}
          />
        </div>
      );
      break;
    case 6:
      //
      // Select Avatar Page Here
      pageContent = (
        <div className="horizontalContainer">
          <ChooseAvatar
            incrementStatus={handleUpdateAvatar}
            decrementStatus={decrementStatus}
            setSelectedAvatar={setSelectedAvatar}
            avatars={avatars}
          />
          <StickyFooter
            onClickIncrement={handleUpdateAvatar}
            incrementText="next"
            onClickDecrement={decrementStatus}
            decrementText="back"
            selectedAvatar={selectedAvatar}
          />
        </div>
      );
      break;
    case 7:
      //
      // count the money in your bank page here
      pageContent = (
        <div className="horizontalContainer">
          <CountMoney />
          <StickyFooter
            onClickIncrement={incrementStatus}
            incrementText="next"
            onClickDecrement={decrementStatus}
            decrementText="back"
            selectedAvatar={selectedAvatar}
          />
        </div>
      );
      break;
    case 8:
      //Enter the amount in your piggy bank
      pageContent = (
        <div className="horizontalContainer">
          <MoneyInPiggyBank
            curBalance={curBalance}
            enteredAmount={enteredAmount}
            setEnteredAmount={setEnteredAmount}
          />
          <StickyFooter
            onClickIncrement={handleInitialAccount}
            incrementText="next"
            onClickDecrement={decrementStatus}
            decrementText="back"
            selectedAvatar={selectedAvatar}
          />
        </div>
      );
      break;
    default:
  }
  return <div>{pageContent}</div>;
}
